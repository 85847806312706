import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from '../components/Layout';
import AdminLayout from '../components/AdminLayout';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Dashboard from '../pages/admin/Dashboard';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import GetQuotes from '../pages/GetQuotes';
import ResetPassword from '../pages/ResetPassword';
import CreateShipment from '../pages/CreateShipment';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Dpa from '../pages/Dpa';
import YourAccount from '../pages/YourAccount';

const Router = ({ isAuthenticated }) => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='signin' element={<SignIn />} />
					<Route path='signup' element={<SignUp />} />
					<Route path='reset-password' element={<ResetPassword />} />
					<Route path='get-quotes' element={<GetQuotes />} />
					<Route
						path='create-shipment'
						element={
							isAuthenticated ? <CreateShipment /> : <Navigate to='/signin' />
						}
					/>
					<Route
						path='your-account'
						element={
							isAuthenticated ? <YourAccount /> : <Navigate to='/signin' />
						}
					/>
					<Route path='terms-and-conditions' element={<TermsAndConditions />} />
					<Route path='privacy-policy' element={<PrivacyPolicy />} />
					<Route path='dpa' element={<Dpa />} />
				</Route>
				<Route
					path='admin'
					element={
						isAuthenticated ? <AdminLayout /> : <Navigate to='/signin' />
					}
				>
					<Route index element={<Dashboard />} />
				</Route>

				<Route path='*' element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.auth.currentUser,
});

export default connect(mapStateToProps)(Router);
