import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import SignInForm from '../lib/form/sign-in-form';

const initialState = '';

const SignIn = () => {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<>
			{redirectPath ? (
				<Navigate to={redirectPath} />
			) : (
				<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
					<PageTitle title='Sign in Your Account' />
					<SignInForm setRedirectPath={setRedirectPath} />
				</main>
			)}
		</>
	);
};

export default SignIn;
