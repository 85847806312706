import React, { useState } from 'react';
import { connect } from 'react-redux';
import { usePlacesWidget } from 'react-google-autocomplete';
import { getRecipient } from '../../../store/actions';
import { getAddressObj } from './getAddressObj';

const RecipientAutoComplete = (props) => {
	const [formattedAddress, setFormattedAddress] = useState('');
	const { getRecipient } = props;

	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		onPlaceSelected: (place) => {
			console.log(place);
			if (place.formatted_address) {
				setFormattedAddress(place.formatted_address);
			}
			getRecipient(getAddressObj(place));
		},
		options: {
			types: ['geocode'],
			fields: ['ALL'],
		},
	});

	return (
		<div className='col-span-6'>
			<label
				htmlFor='recipientAddress'
				className='block text-sm font-medium text-gray-700'
			>
				Search for recipient's address
			</label>
			<input
				ref={ref}
				name='recipientAddress'
				type='text'
				className='mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
			/>
			{formattedAddress && (
				<div className='mt-5 text-sm font-medium text-gray-600'>
					Formatted address: {formattedAddress}
				</div>
			)}
		</div>
	);
};

export default connect(null, { getRecipient })(RecipientAutoComplete);
