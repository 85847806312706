import React from 'react';
import { signUp } from '../../../store/actions';
import FormSubmit from '../FormSubmit';
import Fields from './Fields';

const getReqBody = (values) => {
	return {
		displayName: values.displayName,
		email: values.email,
		password: values.password,
	};
};
const buttonLabel = 'Sign up';
const redirectPath = '';

const SignUpForm = ({ setRedirectPath }) => {
	return (
		<FormSubmit
			component={Fields}
			getReqBody={getReqBody}
			submitAction={signUp}
			buttonLabel={buttonLabel}
			redirectPath={redirectPath}
			setRedirectPath={setRedirectPath}
		/>
	);
};

export default SignUpForm;
