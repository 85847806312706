const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const paymentApiUrl = () => {
	let url = process.env.REACT_APP_LIVE_API_URL;

	// if (process.env.NODE_ENV === 'development') {
	// 	url = process.env.REACT_APP_LOCAL_API_URL;
	// }

	return url;
};

const PAYMENT_API_URL = paymentApiUrl();

export const API_ENDPOINTS = {
	wceGetQuote: BASE_API_URL + '/Wce/get-quote',
	fedexCreateShipment: BASE_API_URL + '/Fedex/create-shipment',
	uspsCreateShipment: BASE_API_URL + '/Essential/create-shipment',
	createPaymentIntent: PAYMENT_API_URL + '/payments/create-payment-intent',
	getTransactionByClientSecret:
		PAYMENT_API_URL + '/transactions/client-secret/',
	getTransactions: PAYMENT_API_URL + '/transactions',
};
