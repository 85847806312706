import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import {
	initiateShipmentDetailsValues,
	cleanUpServiceRates,
} from '../../../store/actions';

import ServiceRates from '../../../components/ServiceRates';
import { getInitialValues } from './getInitialValues';

const ShipNow = ({
	price,
	initiateShipmentDetailsValues,
	cleanUpServiceRates,
}) => {
	const navigate = useNavigate();

	const onServiceSelect = (service) => {
		initiateShipmentDetailsValues(getInitialValues(service, price), service);
		navigate('/create-shipment');
	};

	useEffect(() => {
		return () => {
			cleanUpServiceRates();
		};
	});

	return (
		<ServiceRates
			linkName={'Ship now'}
			onServiceSelect={onServiceSelect}
			serviceRates={price.serviceRates}
		/>
	);
};

const mapStateToProps = (state) => ({
	price: state.price,
});

export default connect(mapStateToProps, {
	initiateShipmentDetailsValues,
	cleanUpServiceRates,
})(ShipNow);
