export const getReqBody = (values) => {
	const {
		shipperCountryCode,
		shipperCity,
		shipperStateOrProvinceCode,
		shipperPostalCode,
		recipientCountryCode,
		recipientCity,
		recipientStateOrProvinceCode,
		recipientPostalCode,
		length,
		width,
		height,
		weight,
		ozUnit,
		shipDate,
		packagingType,
		serviceType,
		residential,
	} = values;

	const from_location = {
		state: shipperStateOrProvinceCode,
		country: shipperCountryCode,
		postal_code: shipperPostalCode,
		city: shipperCity,
		residential: true,
	};

	const to_location = {
		state: '',
		country: recipientCountryCode,
		postal_code: recipientPostalCode,
		city: recipientCity || recipientStateOrProvinceCode,
		residential: residential || false,
	};

	let packages = [];
	let finalWeight = 0;

	finalWeight = parseInt(weight) + parseInt(ozUnit) / 16;

	packages.push({
		length: packagingType === 'FEDEX_ENVELOPE' ? 15 : length,
		width: packagingType === 'FEDEX_ENVELOPE' ? 10 : width,
		height: packagingType === 'FEDEX_ENVELOPE' ? 1 : height,
		weight: finalWeight || 0.21875,
		groupPackageCount: 1,
	});

	let commodities = [];
	commodities.push({
		description: 'documentation',
		weight: {
			units: 'LB',
			value: weight || undefined,
		},
		quantity: 1,
		customsValue: {
			amount: '10',
			currency: 'USD',
		},
	});

	let carrier = 0;

	const reqBody = {
		carrier: carrier,
		shipDate: shipDate,
		from_location,
		to_location,
		packages,
		documentShipment: true,
		pickupType: 'DROPOFF_AT_FEDEX_LOCATION',
		packagingType,
		commodities,
		service_Type: serviceType,
	};

	console.log(reqBody);

	return reqBody;
};
