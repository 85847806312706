import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { auth } from '../../../firebase/config';

import { connect } from 'react-redux';
import {
	fedexCreateShipment,
	uspsCreateShipment,
} from '../../../store/actions';

import { API_ENDPOINTS } from '../../../store/api';

import FormSubmit from './FormSubmit';
import Fields from './Fields';
import { getReqBody } from './getReqBody';

const buttonLabel = 'Pay now';

const stripePromise = loadStripe(
	'pk_test_51KzmbaEPnKUnFHZvZJBcfyqDiigWfHxeCwmfgxRVXkpOHnXbYIYSCTvZgby2qqgjNlphV9VnLprmZogAIsHGpBuk00VwkqFpDm'
);

const PaymentForm = ({ service, submittedForm, onStepChange }) => {
	const [clientSecret, setClientSecret] = useState('');
	const metadata = { ...service, ...submittedForm };
	metadata.uid = auth.currentUser.uid;

	let submitAction = null;

	if (service.carrier_code === 'Fedex') submitAction = fedexCreateShipment;
	if (service.carrier_code === 'usps') submitAction = uspsCreateShipment;

	useEffect(() => {
		// Create PaymentIntent as soon as the page loads
		fetch(API_ENDPOINTS.createPaymentIntent, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				items: [{ id: 'xl-tshirt' }],
				metadata,
				receipt_email: auth.currentUser.email,
			}),
		})
			.then((res) => res.json())
			.then((data) => setClientSecret(data.clientSecret));
	}, []);

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	};

	return (
		<>
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<FormSubmit
						component={Fields}
						submitAction={submitAction}
						getReqBody={getReqBody}
						buttonLabel={buttonLabel}
						setRedirectPath={onStepChange}
					/>
				</Elements>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	service: state.shipment.selectedService,
	submittedForm: state.shipment.initialValues,
});

export default connect(mapStateToProps)(PaymentForm);
