export const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce(
			(error, validator) => error || validator(value),
			undefined
		);

export const requiredField = (value) => (value ? undefined : 'Required');
export const mustBeNumber = (value) =>
	isNaN(value) ? 'Must be a number' : undefined;
export const minValue = (min) => (value) =>
	isNaN(value) || value >= min
		? undefined
		: `Should be greater than or equal ${min}`;
export const greaterThan = (min) => (value) =>
	isNaN(value) || value > min ? undefined : `Should be greater than ${min}`;
export const maxValue = (max) => (value) =>
	isNaN(value) || value <= max
		? undefined
		: `Should be smaller or equal ${max}`;
export const confirmedPasswordMatched = (password) => (value) =>
	password && value && value === password
		? undefined
		: 'Confirmed password does not match';
