import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

import { connect } from 'react-redux';

import { FieldSet, Subtitle, TitleSet, Title, Card } from '../components';

const Fields = ({ initialValues, selectedService }) => {
	return (
		<>
			<FieldSet>
				<TitleSet>
					<Title>Shipment summary</Title>
					<Subtitle>
						Check your shipment's information before processing the payment
					</Subtitle>
				</TitleSet>
				<div className='mt-5 md:mt-0 md:col-span-2'>
					<div className='border border-gray-200 overflow-hidden sm:rounded-md'>
						<div className='px-4 py-5 bg-white sm:p-6'>
							<div className='space-y-2 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-6 md:gap-y-2'>
								<div className='col-span-2'>
									<span className='text-sm font-medium'>Shipper:</span>{' '}
									<span className='text-sm text-gray-900'>
										{`${initialValues.shipperStreetLine1}, ${initialValues.shipperCity}, ${initialValues.shipperStateOrProvinceCode} ${initialValues.shipperPostalCode}, ${initialValues.shipperCountryCode}`}
									</span>
								</div>
								<div className='col-span-2'>
									<span className='text-sm font-medium'>Recipient:</span>{' '}
									<span className='text-sm text-gray-900'>
										{`${initialValues.recipientStreetLine1}, ${initialValues.recipientCity}, ${initialValues.recipientStateOrProvinceCode} ${initialValues.recipientPostalCode}, ${initialValues.recipientCountryCode}`}
									</span>
								</div>
							</div>
						</div>

						<div className='border-t border-gray-200 px-4 py-5 bg-white sm:p-6'>
							<div className='space-y-2 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-6 md:gap-y-2'>
								<div className='col-span-2'>
									<span className='text-sm font-medium'>Package:</span>{' '}
									<span className='text-sm text-gray-900'>
										{`${initialValues.weight} lbs, ${initialValues.length} in x ${initialValues.width} in x ${initialValues.height} in`}
									</span>
								</div>
								<div className='col-span-2'>
									<span className='text-sm font-medium'>Service Type:</span>{' '}
									<span className='text-sm text-gray-900'>
										{selectedService.service}
									</span>
								</div>
								<div className='col-span-2'>
									<span className='text-sm font-medium'>Packaging:</span>{' '}
									<span className='text-sm text-gray-900'>
										{initialValues.packagingType}
									</span>
								</div>
								<div className='col-span-2'>
									<span className='text-sm font-medium'>Delivery Date:</span>{' '}
									<span className='text-sm text-gray-900'>
										{selectedService.commit_date}
									</span>
								</div>
							</div>
						</div>

						<dl className='border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6'>
							<div className='flex items-center justify-between'>
								<dt className='text-sm'>Shipping cost</dt>
								<dd className='text-sm font-medium text-gray-900'>
									{`$${selectedService.price}`}
								</dd>
							</div>
							<div className='flex items-center justify-between'>
								<dt className='text-sm'>Surcharge</dt>
								<dd className='text-sm font-medium text-gray-900'>Included</dd>
							</div>
							<div className='flex items-center justify-between border-t border-gray-200 pt-6'>
								<dt className='text-base font-medium'>Total</dt>
								<dd className='text-base font-medium text-gray-900'>
									{`$${selectedService.price}`}
								</dd>
							</div>
						</dl>
					</div>
				</div>
			</FieldSet>

			<FieldSet>
				<TitleSet>
					<Title>Payment details</Title>
					<Subtitle>Our payment will be process by Stripe</Subtitle>
				</TitleSet>
				<Card>
					<div className='col-span-6 lg:col-span-6'>
						<PaymentElement id='payment-element' />
					</div>
				</Card>
			</FieldSet>
		</>
	);
};

const mapStateToProps = (state) => ({
	initialValues: state.shipment.initialValues,
	selectedService: state.shipment.selectedService,
});

export default connect(mapStateToProps)(Fields);
