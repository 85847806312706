export const getInitialValues = (service, price) => {
	const formValues = {};

	formValues.shipperCountryCode = price.shipper.countryCode;
	formValues.shipperStreetLine1 = price.shipper.streetLine1;
	formValues.shipperStreetLine2 = price.shipper.streetLine2;
	formValues.shipperCity = price.shipper.city;
	formValues.shipperStateOrProvinceCode = price.shipper.stateOrProvinceCode;
	formValues.shipperPostalCode = price.shipper.postalCode;
	formValues.recipientCountryCode = price.recipient.countryCode;
	formValues.recipientStreetLine1 = price.recipient.streetLine1;
	formValues.recipientStreetLine2 = price.recipient.streetLine2;
	formValues.recipientCity = price.recipient.city;
	formValues.recipientStateOrProvinceCode = price.recipient.stateOrProvinceCode;
	formValues.recipientPostalCode = price.recipient.postalCode;
	formValues.serviceType = service.service_code;
	formValues.length = price.formValues.length;
	formValues.width = price.formValues.width;
	formValues.height = price.formValues.height;
	formValues.weight = price.formValues.weight;
	formValues.ozUnit = price.formValues.ozUnit;
	formValues.packagingType = price.formValues.packagingType;
	formValues.shipDate = price.formValues.shipDate;

	return formValues;
};
