import React from 'react';
import { P, H2, Strong, A } from '../components/common';
import PageTitle from '../components/PageTitle';

export default function PrivacyPolicy() {
	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			<PageTitle title={'Privacy Policy'} />
			<br />
			<P>Last Modified: May 03rd, 2022</P>
			<P>
				This is the privacy policy of G7 Logistics Inc ("G7 Express"). This
				privacy policy ("Privacy Policy") describes how we protect and handle
				information we collect about you. It is also intended to comply with all
				the directives of the European Union’s General Data Protection
				Regulation, the California Consumer Privacy Protection Act of 2018, and
				the Children's Online Privacy Protection Act as each of those laws are
				updated and added to over time. This Privacy Policy describes the
				privacy practices of G7 Express and its corporate affiliates, meaning
				companies related by common ownership or control to G7 Express ("us,"
				"we," "our") who may be involved and responsible for collecting and
				maintaining certain information collected about you.
			</P>
			<P>
				<Strong>This Privacy Policy includes, by reference, the </Strong>
				<A href='/dpa'>
					<Strong>Data Processing Addendum</Strong>
				</A>
				<Strong>.</Strong>
				<Strong>
					{' '}
					The Data Processing Addendum defines our specific legal obligations
					under the data protection laws of the European Union and your
					agreement with respect to the terms governing the processing of your
					personal data.
				</Strong>
			</P>
			<P>
				If you have questions or complaints regarding our Privacy Policy or
				practices, please contact us as detailed under the "How to Contact Us"
				heading below.
			</P>
			<H2>1. WHEN THIS PRIVACY POLICY APPLIES.</H2>
			<P>
				This Privacy Policy applies to you if you fall into one of the following
				categories: (i) a G7 Express customer; (ii) an end recipient of services
				provided by G7 Express; (iii) a G7 Express supplier; (iv) a customer of
				a third party service that links to our website or this Privacy Policy;
				or (v) if you contact us via our website, social media, or e-mail.
				Generally, this Privacy Policy applies to our sites, products, and
				services (collectively, "Services") that link to this Privacy Policy.
				For legal purposes, including the E.U.’s General Data Protection
				Regulation, G7 Express is the controller of all Personal Information
				subject to this Privacy Policy for our Services.
			</P>
			<H2>2. WHAT WE DO WITH YOUR INFORMATION.</H2>
			<P>
				<Strong>
					Here we discuss what information we collect and how we use it to
					deliver our Services to you, operate our business, and help make our
					Services useful.
				</Strong>
			</P>
			<P>
				This Privacy Policy describes how G7 Express collects, uses, shares and
				secures the Personal Information you provide. It also describes your
				choices regarding use, access and correction of your Personal
				Information. We do not sell or share your Personal Information with
				third parties for their own commercial uses. We only transfer such
				information necessary to the United State Postal Service, selected
				third-party couriers and service providers to G7 Express as necessary to
				accomplish the purchase of postage and creation of shipping labels.
			</P>
			<ol>
				<li>
					<Strong>Types of Information We Collect.</Strong> In connection with
					accessing our Services, we may collect information from you and your
					household which can be used to identify you (all such personal and
					household information, "Personal Information"), such as your name,
					shipping/billing address, email address, phone number, credit
					card/bank account number, username and password.
					<br />
					<br />
					We collect information when you register or open an account, sign in,
					request price quotes, pay fees, purchase or otherwise use a Service,
					call us for support, or give us feedback. We may also get information
					from other companies or third parties, such as when you sync a
					third-party account or service with your G7 Express Account, or when
					we may use service providers to supplement the Personal Information
					you give us (e.g., validate your mailing address) to help us maintain
					the accuracy of your data and provide you with better service. We will
					only use that information for legally acceptable purposes. Finally, we
					may collect content or other information that you may provide or
					create when you interact with our Services.
					<br />
					<br />
					We may also automatically collect certain usage information when you
					access our Services ("Usage Data"), such as Internet Protocol ("IP")
					addresses, log files, unique device identifiers, pages viewed, browser
					type, any links you click on to leave or interact with our Services,
					and other usage information collected from cookies and other tracking
					technologies. For example, we may collect IP addresses to track and
					aggregate to monitor the regions from which users navigate to our
					Services. If you visit our website, in order to optimize your user
					experience and the delivery of our service, we will track your usage
					of the site. For instance, we may track the pages you visited, the
					time spent on each page, the time spent engaged in particular tasks on
					each page, the key strokes necessary to perform a function on the
					site, etc. We may also collect IP addresses from users when they log
					into the Services as part of our log-in and security features. We may
					also, when you enable location-based Services, collect Global
					Positioning System (GPS) location data and/or motion data.
					<br />
					<br />
					Our Services may change over time and we may introduce new features
					that may collect new or different types of information.
					<br />
					<br />
				</li>
				<li>
					<Strong>How We Use Your Information.</Strong> We may use your
					information, including your Personal Information, for the following
					purposes: <br />
					<br />
					<span className='underline'>
						<i>Account Registration.</i>
					</span>{' '}
					We will use your name, address, phone number, billing information, and
					email address to register your account for certain Services we provide
					and to communicate important information to you. We may obtain
					additional Personal Information about you, such as address change
					information, from commercially available sources to keep our records
					current. If you set up an administrator account that may be accessed
					by people other than you, please note that they may see and have the
					ability to change or delete your Personal Information. Please be
					careful who you give administrator access to, as you are responsible
					for their actions.
					<br />
					<br />
					<span className='underline'>
						<i>To Provide Our Services and Operate Our Business.</i>
					</span>{' '}
					We may use your information to operate our business, including
					providing Services you requested, provide you with support related to
					our Services, and to help us protect our Services, including to combat
					fraud and protect your information.
					<br />
					<br />
					<span className='underline'>
						<i>Customer Service and Technical Support.</i>
					</span>{' '}
					We may use your name, address, phone number, email address, how you
					interact with our Services, and information about your computer
					configuration to resolve questions you may have about our Services and
					to follow up with you about your experience. We also offer various
					Internet chat services, for example, to speak with a G7 Express
					customer support representative. Internet Chat transmissions are
					encrypted but you should not supply more Personal Information than is
					required to address your specific issue. A transcript of the session
					is retained to resolve questions or issues related to our Services.
					<br />
					<br />
					<span className='underline'>
						<i>Communicate with You.</i>
					</span>{' '}
					We may use your information to communicate with you about our
					Services. Please see below under "What You Can Do to Manage Your
					Privacy" for the choices you have regarding these communications.
					<br />
					<br />
					<span className='underline'>
						<i>To Improve Services and Develop New Services.</i>
					</span>{' '}
					We will use your information to personalize or customize your
					experience and the Service, develop new features or services, and to
					improve the overall quality of G7 Express's Services.
					<br />
					<br />
					<span className='underline'>
						<i>Feedback.</i>
					</span>{' '}
					We may use any information you volunteer and combine it with
					information from other customers in order to better understand our
					Services and how we may improve them. Providing any feedback is
					optional.
					<br />
					<br />
				</li>
				<li>
					<Strong>When and How We Share Your Personal Information.</Strong> From
					time to time, we may need to share your Personal Information with
					others. <br />
					<br />
					<span className='underline bold'>
						Third Party Service Providers.
					</span>{' '}
					We may share your information, including Personal Information and
					Usage Data, with third party service providers who perform various
					functions to enable us to provide our Services and help us operate our
					business, such as website design, sending email communications,
					telephone communication, SMS (text messaging), advertising, fraud
					detection and prevention, customer care, third party surveys or
					performing analytics. These companies are authorized to use your
					Personal Information only as necessary to provide these services to
					us. Our contracts with these third parties require them to maintain
					the confidentiality of the Personal Information we provide to them,
					only act on our behalf and under our instructions, and not use
					Personal Information for purposes other than the product or service
					they're providing to us or on our behalf.
					<br />
					<br />
					<span className='underline bold'>
						United States Postal Service (USPS).
					</span>{' '}
					G7 Express and its partners are regulated by the USPS. As part of the
					regulatory relationship, G7 Express collects certain information about
					you on behalf of the USPS. The USPS also may send us tracking data
					from their scans of your packages in the mailstream. Our uses for the
					data include informing you about the status of your packages through
					options like USPS Tracking, fraud detection and aggregating the data
					to help the USPS and us provide you with better service. Information
					collected on behalf of USPS is used for their regulatory oversight
					function and is not used for other purposes.
					<br />
					<br />
					As part of G7 Express providing services of the USPS, the USPS
					requires that you agree to the USPS Privacy Act Statement and to
					provide information about yourself that will be maintained in a
					Privacy Act System of Records by the Postal Service. Privacy Act
					Statement: Your information will be used to facilitate the purchase of
					USPS postage and fulfill transactional reporting requirements for USPS
					postage systems. Collection is authorized by 39 U.S.C. 401, 403, and
					404. Providing the information is voluntary, but if not provided, your
					transaction may not be processed. The Postal Service does not disclose
					your information to third parties without your consent, except to
					facilitate the transaction, to act on your behalf or request, or as
					legally required. This includes the following limited circumstances:
					to a congressional office on your behalf; to financial entities
					regarding financial transaction issues; to a US Postal Service (USPS)
					auditor; to entities, including law enforcement, as required by law or
					in legal proceedings; and to contractors and other entities aiding us
					to fulfill the Services (service providers). For more information
					regarding our privacy policies, visit{' '}
					<A href='https://www.usps.com/privacypolicy' target='_blank'>
						www.usps.com/privacypolicy
					</A>
					. <br />
					<br />
					For more information regarding other specific carrier you may use via
					the G7 Express platform, please see the privacy policies provided by
					each specific carrier you select.
					<br />
					<br />
					<span className='underline bold'>United Parcel Service (UPS).</span>
					As a part of its partnership with UPS, G7 Express collects Personal
					Information about you that may be shared with UPS in order to provide
					you with Services, including, but not limited to, your consideration,
					purchase, and use of UPS shipping and related services through G7
					Express. UPS processes such Personal Information for the purposes set
					forth in the UPS Privacy Notice published at{' '}
					<A href='https://www.ups.com/content/us/en/resources/ship/terms/privacy.html'>
						https://www.ups.com/content/us/en/resources/ship/terms/privacy.html
					</A>
					, which may be updated by UPS from time to time.
					<br />
					<br />
					<span className='underline bold'>Third Party Carriers.</span> Use of
					carrier services via the G7 Express platform is at your own risk. G7
					Express is not responsible for your use of carrier services. Your use
					of carrier services is as a direct customer of the specific carrier of
					your choosing and you agree to be bound by the terms and conditions of
					that carrier for use of services. By providing information to certain
					carrier services either directly or through G7 Express, you understand
					and consent to the collection, use, processing disclosure and transfer
					of such information and acknowledge that the carrier services may have
					different data protection practices than G7 Express. Your interactions
					with these service carriers are governed by each carrier’s privacy
					policy. <br />
					<br />
					<span className='underline bold'>
						Response to Subpoenas and Other Legal Requests.
					</span>{' '}
					We may share your information with courts, law enforcement agencies,
					or other government bodies when we have a good faith belief we're
					required or permitted to do so by law, including to meet national
					security or law enforcement requirements, to protect our company, or
					to respond to a court order, subpoena, search warrant, or other law
					enforcement request. <br />
					<br />
					<span className='underline bold'>
						Protection of G7 Express and Others.
					</span>{' '}
					We may share account information, Personal Information and Usage Data
					when we believe it is appropriate to enforce or apply our products'
					Terms of Use and other agreements; or protect the rights, property, or
					safety of G7 Express, our Services, our users, or others. This
					includes exchanging information with other companies and organizations
					for fraud protection and credit risk reduction.{' '}
					<em>
						This does not include selling, renting, sharing, or otherwise
						disclosing Personal Information of our customers for commercial
						purposes in violation of the commitments set forth in this Privacy
						Policy.
					</em>{' '}
					<br />
					<br />
					<span className='underline bold'>
						Information Sharing Between G7 Express Entities.
					</span>{' '}
					We share your information, including your Personal Information, with
					and among our subsidiaries, except where prohibited by law.
					"Subsidiaries" means companies related by common ownership or control.
					The reasons why we share your information include for our everyday
					business purposes, such as to: process your transactions, maintain
					your accounts, operate our business, etc. We may also share your
					information in order for us to be able to offer our products and
					services to you. We may also share information about your transactions
					and experience so that we can operate our business effectively, detect
					and prevent fraud, and improve our Services.
					<br />
					<br />
					<span className='underline bold'>
						Sharing with Third Parties Generally.
					</span>{' '}
					If we sell, merge, or transfer any part of our business, we may be
					required to share your information. <br />
					<br />
					<span className='underline bold'>With your Consent.</span> Other than
					as set out above, we will provide you with notice and the opportunity
					to choose when your Personal Information may be shared with other
					third parties. <br />
					<br />
					<span className='underline bold'>Aggregate Date.</span> G7 Express may
					collect, use, and distribute statistical information related to the
					usage of the website, Services, or traffic patterns only in aggregate
					and de-identifiable form ("Aggregated Data"). Aggregated Data does not
					contain any Personal Information about you. Aggregated Data may be
					shared with third parties, but is stripped of all Personal Information
					and contains information like: the busiest shipping date of the year
					is December 18th and the top shipping destination in the U.S. is the
					metro NYC area. G7 Express will own all Aggregated Data.
					<br />
					<br />
				</li>
				<li>
					<Strong>
						Linking Other Third Party Services with Your G7 Express Service.
					</Strong>{' '}
					<br />
					<br />
					We work with other companies to enhance our services, such as
					integrations to ecommerce platforms, and you may choose to sync, link
					or connect other third party services with your G7 Express Service.
					Sometimes G7 Express may let you know about the service or product, or
					another company may let you know about a G7 Express service or
					product. If you choose to accept these services, providing your
					consent to either the third party or to us, we may exchange your
					information, including your Personal Information, as well as
					information about how you interact with each company's service or
					product. This exchange of information is necessary to maintain
					business operations and to provide the ongoing service you've
					requested. By requesting or accepting these products or services, you
					are permitting us to provide your information, including your Personal
					Information, to the other party.
					<br />
					<br />
				</li>
			</ol>
			<H2>3. WHAT YOU CAN DO TO MANAGE YOUR PRIVACY. </H2>
			<P>
				<Strong>
					You can view and edit information that identifies you online through
					your G7 Express Service. How you can access and control information
					that identifies you will depend on which Services you use. You have a
					choice about the use of information that identifies you, marketing
					communications you receive from us, and our use of cookies and other
					tracking technologies.
				</Strong>
			</P>
			<ol>
				<li>
					<Strong>Updating Your Personal Information.</Strong> In connection
					with your right to manage your Personal Information you provide to us,
					you may access, update, change, correct or request delivery or
					deletion of your information either through the Service or through our
					customer support. You can reach our customer support by using the
					contact information provided in the "How to Contact Us" section of
					this Privacy Policy. We will respond to your request within a
					reasonable timeframe.
				</li>
				<li>
					<Strong>Managing Marketing Communications From Us.</Strong> We will
					honor your choices when it comes to receiving marketing communications
					from us.
					<br />
					<br />
					You have the following choices if you have been receiving marketing
					communications from us that you no longer wish to receive:
					<ul>
						<li>
							Access the opt-out link in any marketing email communication from
							G7 Express.
						</li>
						<li>
							Remember that even if you choose not to receive marketing
							communications from us, we will continue to send you mandatory
							service or transactional communications.
						</li>
					</ul>
				</li>
				<li>
					<Strong>Cookies and Other Tracking Technologies.</Strong> Generally we
					use these tracking technologies to:
					<ul>
						<li>
							Fulfill your requests for products and services, and improve our
							services.
						</li>
						<li>
							Remind us of who you are on return visits in order to deliver to
							you a better and more personalized service. This cookie is set
							when you first visit the G7 Express Site.
						</li>
						<li>
							Estimate our audience size and measure traffic patterns regarding
							usage of G7 Express’s network of Sites. Each browser accessing G7
							Express is given a unique cookie which is then used to determine
							the extent of repeat usage and usage by a registered user versus
							by an unregistered user.
						</li>
					</ul>
					<br />
					If you are uncomfortable having a cookie placed on your system, you
					may elect to turn off the cookie or delete existing cookies in your
					browser. If you turn off or delete the G7 Express cookie, you may not
					be able to access all G7 Express Services, may have only partial
					functionality, or the service may not work at all.
					<br />
					<br />
					Technologies such as cookies, or similar technologies, are used by G7
					Express and our partners, affiliates, or analytics or service
					providers (e.g. online customer support technology providers). These
					technologies are used in analyzing trends, administering the site,
					tracking user movements around the site and to gather demographic
					information about our user base as a whole. We may receive reports
					based on the use of these technologies by these companies on an
					individual as well as aggregated basis.
					<br />
					<br />
					We and third parties with whom we partner to provide certain features
					on our site may use Local Storage (LSs) such as HTML 5 to collect and
					store information. Various browsers may offer their own management
					tools for removing HTML5.
					<br />
					<br />
					We may partner with third parties to manage our advertising on other
					sites. Our third party partners may use technologies such as cookies
					to gather information about your activities on this site and other
					sites in order to provide you advertising based upon your browsing
					activities and interests. For example, this allows us to display ads
					to you on other websites, which we believe will be relevant because
					you’ve visited our website before. If you do not wish to have this
					information used for the purpose of serving you interest-based ads,
					you may 1) clear your web browser’s cache or 2) opt-out through a
					third-party service by clicking the links below:
					<br />
					<br />
					<ul>
						<li>
							<A
								href='https://www.facebook.com/settings/?tab=ads'
								target='_blank'
							>
								Click here
							</A>{' '}
							to opt out of targeted ads on Facebook.
						</li>
						<li>
							<A
								href='https://tools.google.com/dlpage/gaoptout'
								target='_blank'
							>
								Click here
							</A>{' '}
							to opt out of Google Analytics for display advertising or to{' '}
							<A
								href='https://support.google.com/ads/answer/2662922?hl=en'
								target='_blank'
							>
								customize Google Display Network ads
							</A>
							.
						</li>
						<li>
							To opt-out of third-party services in the US, visit the{' '}
							<A
								href='https://www.networkadvertising.org/choices/'
								target='_blank'
							>
								Network Advertising Initiative
							</A>{' '}
							and{' '}
							<A href='https://optout.aboutads.info/' target='_blank'>
								Digital Advertising Alliance
							</A>
							, which offer opt-out tools for such third parties participating
							in those programs. Participating network advertisers will be
							listed at those sites. Some third parties may offer opt out tools
							on their own sites. Consult their privacy statements for more
							details. Please contact us if you have any questions.
						</li>
					</ul>
					<br />
					Please note that even if you opt-out of such Cookies or otherwise
					opt-out of interest-based advertising, you may still receive
					advertisements, they just won't be tailored to your interests. Also,
					if you opt-out and later delete your Cookies, use a different browser,
					or buy a new computer, you may need to renew your opt-out choices.
				</li>
				<li>
					<Strong>Do Not Track.</Strong> Like most other companies, our Services
					are not currently configured to respond to browsers' "Do Not Track"
					signals because at this time no formal "Do Not Track" standard has
					been adopted. However, the practices of our partners and other
					third-party providers may change as a result of this feature being
					utilized.
					<A href='https://allaboutdnt.com/' target='_blank'>
						Click here
					</A>{' '}
					for more information on "Do Not Track."
				</li>
				<li>
					<Strong>Social Media Features.</Strong> Our Services may use social
					media features, such as Facebook sharing ("Social Media Features").
					These features may collect your IP address and which page you are
					visiting within our Service, and may set a cookie to enable the
					feature to function properly. Social Media Features are either hosted
					by a third party or hosted directly on our Services. Your interactions
					with these features are governed by the privacy policy of the company
					providing the relevant Social Media Features.
				</li>
			</ol>
			<br />
			<H2>4. DATA RETENTION AND YOUR ACCESS RIGHTS</H2>
			<ol>
				<li>
					<Strong>Data Retention.</Strong> In accordance with and as permitted
					by applicable law and regulations, we will retain your information as
					long as necessary to serve you, to maintain your account for as long
					as your account is active, or as otherwise needed to operate our
					business. When you close your account, we may continue to communicate
					with you about our Services, give you important business updates that
					may affect you, and let you know about products and services that may
					interest you, unless you have opted out of receiving marketing
					communications. We may also continue to use some of your information
					for business purposes and to improve our offerings or in some cases to
					develop new ones. We will retain and use your information as required
					by applicable regulations and G7 Express's records and information
					management policies to comply with our legal and reporting
					obligations, resolve disputes, enforce our agreements, complete any
					outstanding transactions, and for the detection and prevention of
					fraud.
				</li>
				<li>
					<Strong>Your Rights to Personal Information.</Strong> You have the
					following rights to access, update, change, correct or request
					delivery or deletion of Personal Information:
					<ol>
						<li>
							{' '}
							Upon request you may contact G7 Express to confirm whether we
							maintain, or process on behalf of a third party, any of your
							Personal Information and to review it in order to verify its
							accuracy and the lawfulness of our processing of such Personal
							Information.{' '}
						</li>
						<li>
							Upon request, you may also request that your Personal Information
							be corrected, amended, delivered to you or deleted.
						</li>
						<li>
							In the event you request any correction, amendment, delivery or
							deletion of any of your Personal Information, G7 Express will
							comply with such request within forty-five (45) days. If the
							circumstances of the request require additional processing time,
							G7 Express will notify you of a one-time extension of up to
							forty-five (45) additional days to comply with such request.
							Please be advised that in certain circumstances, G7 Express may
							not comply with a request to delete Personal Information if G7
							Express is required to retain such information (i) under
							applicable law or the order of a court or agency of applicable
							jurisdiction, (ii) to prevent or detect security incidents,
							protect against malicious, deceptive, fraudulent, or illegal
							activity; or prosecute those responsible for that activity, or
							(iii) to complete the Services.
						</li>
						<li>
							In the event you request delivery of your Personal Information, G7
							Express will deliver your Personal Information by mail or
							electronically up to two (2) times in any twelve (12) month period
							free of charge. If provided electronically, the information will
							be in a portable and, to the extent technically feasible, readily
							useable format that allows you to transmit this information to
							another service provider without hindrance.
						</li>
						<li>
							Requests for access to your Personal Information and to have it
							corrected, amended, delivered or deleted should be sent to us via
							the chat on our website or email as listed on{' '}
							<A href='https://g7express.com/contact'>
								https://www.g7express.com/contact
							</A>{' '}
							or to the mailing address provided under "How to Contact Us."
						</li>{' '}
					</ol>
				</li>
			</ol>
			<br />
			<H2>5. SECURITY OF YOUR INFORMATION.</H2>
			<P>
				<Strong>Keeping your Information safe is important to us.</Strong> We
				provide reasonable and appropriate security measures in connection with
				securing Personal Information we collect, though no method of
				transmission over the Internet is 100% secure.
			</P>
			<P>For example, we:</P>
			<ul>
				<li>
					Consistently work to update our security practices to implement
					accepted best methods to protect your Personal Information, and review
					our security procedures carefully.
				</li>
				<li>Comply with applicable laws and security standards.</li>
				<li>Securely transmit your sensitive Personal Information.</li>
				<li>
					Train our staff and require them to safeguard your data. Limit the
					staff with access to your Personal Information.
				</li>
				<li>
					Securely transmit, protect, and access all payment information. <br />
					<br />
					If you have any questions about the security of your personal
					information, you can contact our{' '}
					<A href='https://g7express.com/contact'>customer support</A> via chat
					or email.
				</li>
			</ul>
			<br />
			<H2>6. INTERNATIONAL DATA TRANSFERS</H2>
			<P>
				In accordance with and as permitted by applicable law and regulations,
				we reserve the right to transfer your information, process and store it
				outside your country of residence to wherever we or our third party
				service providers operate.
			</P>
			<H2>7. HOW TO CONTACT US.</H2>
			<P>
				<Strong>
					If you have questions or comments about this Privacy Policy, please
					contact us. We want your feedback and comments.
				</Strong>
			</P>
			<P>
				<Strong>Via the internet:</Strong> Contact our{' '}
				<A href='https://g7express.com/contact'>customer support</A> via chat or
				email.
			</P>
			<P>
				<Strong>Via phone:</Strong> Toll-free:{' '}
				<A href='tel:+19494913617'>1-949-491-3617</A>
			</P>
			<P>
				<Strong>Via mail:</Strong> <br />
				G7 Express LLC <br />
				Attn: Privacy Team <br />
				PO Box 28383 <br />
				Newport Beach, CA 92603
			</P>
			<H2>8. CHANGES TO OUR PRIVACY POLICY.</H2>
			<P>
				<Strong>
					From time to time we may change or update our Privacy Policy. We
					reserve the right to make changes or updates at any time. More
					information about how we will notify you is below.
				</Strong>
			</P>
			<P>
				If we make material changes to the way we process your Personal
				Information, we will provide you notice via our Service or by other
				communication channels, such as by email or posting on our Site when the
				change becomes effective. We encourage you to periodically review this
				page for the latest information on our privacy practices. Please review
				any changes carefully. If you object to any of the changes and no longer
				wish to use our Services, you may close your account(s). All changes are
				effective immediately upon posting and your use of our Service after a
				notice of material change or posting of an updated Privacy Policy shall
				constitute your consent to all changes.
			</P>
			<H2>9. COLLECTION AND USE OF CHILDREN'S PERSONAL INFORMATION.</H2>
			<P>
				<Strong>We do not knowingly collect information from minors.</Strong>
			</P>
			<P>
				G7 Express Services are intended for and directed to adults. Our
				Services are not directed to minors and we do not knowingly collect
				Personal Information from minors.{' '}
				<Strong>
					IF YOU ARE UNDER 16 YEARS OLD, YOU ARE A MINOR IN MANY JURISDICTIONS.
				</Strong>
			</P>
			<P>
				g7express.com is a general audience web site that complies with the
				Children's Online Privacy Protection Act (COPPA). If we receive valid
				information that a registered member is under the age of 13, the account
				will be closed and Personal Information removed from the site.
			</P>
			<P>
				For more information about COPPA, please visit the Federal Trade
				Commission's web site at{' '}
				<A href='http://www.ftc.gov/'>http://www.ftc.gov</A>.
			</P>
			<P>
				If you are the parent of a child under the age of 16 who has created an
				account using a false age, please contact our{' '}
				<A href='https://g7express.com/contact'>customer support</A> via chat or
				email and we will immediately cancel your child's account and nullify
				Personal Information collected from them.
			</P>
			<H2>10. YOUR CALIFORNIA AND STATE PRIVACY RIGHTS.</H2>
			<P>
				This Privacy Policy, the Terms of Use, and the delivery of the Services
				by G7 Express are intended to comply with the your rights and G7express’
				duties under the California Consumer Privacy Act of 2018 ("CCPA") and
				the similar consumer protection and data privacy laws of various
				jurisdictions within the United States. Under the CCPA, if you are a
				resident of California, you have certain privacy rights as described in
				this section.
			</P>
			<P>
				<Strong>California Privacy Rights</Strong>. If you reside in California,
				we are required to provide additional information to you about how we
				use and disclose your Personal Information. You may also have additional
				rights with regard to how we use your Personal Information. We have
				included this California-specific information below.
			</P>
			<P>
				<em>Uses and Disclosure of your information</em>. Consistent with the
				"Types of Information We Collect" section above, we collect certain
				categories and specific pieces of information about individuals that are
				considered "Personal Information" in California. As detailed above, we
				may collect this Personal Information from you and other third parties.
				We collect, share and disclose Personal Information for the business and
				commercial purposes described in the "How We Use Your Information" and
				"When and How We Share Your Personal Information" sections above.
			</P>
			<P>
				We also share Personal Information collected from cookies and similar
				tracking technologies, such as how you interact with our websites and
				mobile apps, with advertising technology partners in order to present
				advertising messages based on your preferences. Given that the
				definition of "sale" under the CCPA is broad and covers situations
				beyond traditional monetary transactions, this type of activity is
				considered a "sale" of Personal Information under the CCPA.
			</P>
			<P>
				<em>Deletion, Access, and Information</em>. Subject to certain
				exceptions, as a California resident, you have the right to: (i) obtain
				deletion of your Personal Information; (ii) obtain access to your
				Personal Information; and (iii) receive information about the categories
				of Personal Information that we collect via third-party advertising
				technologies, which may be considered a "sale" under California law, to
				"third parties" (as that term is defined under California law) and also
				that we have disclosed for a "business purpose" (as that term is defined
				under California law).
			</P>
			<ul>
				<li>
					Deletion: If you would like us to delete your Personal Information,
					please log into your account and submit a deletion request via the
					Support chat. If you do not have an account with us, then please
					contact us via the chat on our website or email as listed on{' '}
					<A href='https://g7express.com/contact'>
						https://g7express.com/contact
					</A>
					. You will need to provide us with your email address as well as any
					additional information requested by our team so that we may verify
					your request and communicate with you regarding your request.{' '}
				</li>
				<li>
					Access and Information: If you would like to access your personal
					information or receive information about the categories of personal
					information about you that we have shared as described above, please
					contact us via the chat on our website or email as listed on{' '}
					<A href='https://g7express.com/contact'>
						https://g7express.com/contact
					</A>
					, with your request. We will use your email address to verify your
					request and communicate with you regarding your request.{' '}
				</li>
				<li>
					You may also contact us by chatting with a representative via our
					website for assistance in exercising any of the California rights
					described above.
				</li>
				<li>
					Should you wish to request the exercise of these rights as detailed
					above, we will not discriminate against you by offering you different
					pricing for products or services, or by providing you with a different
					level or quality of products or service, based solely upon this
					request and subject to certain applicable exceptions. Please note that
					we need to retain certain types of personal information to continue
					providing our services (e.g., name, address). Without your personal
					information, we will not be able to provide you with the requested
					services.
				</li>
			</ul>
			<P>
				<em>Do Not Sell My Personal Information</em>. Subject to certain
				exceptions, as a California resident, you have the right to opt out of
				the "sale" of your personal information collected from cookies and
				similar tracking technologies. To opt out of the "sale" of your personal
				information as described in this section, please follow the steps
				outlined above in the "Cookies and Other Tracking Technologies" section.
				You may also opt out of certain "sales" of advertising by visiting{' '}
				<A href='https://optout.networkadvertising.org/?c=1'>
					NAI Consumer Opt Out
				</A>
				.
			</P>
			<ul>
				<li>
					Keep in mind that, if you opt out of interest-based advertising, you
					may still see customized advertising displayed by advertising networks
					from which you have not opted out. In addition, your opt out will not
					prevent any advertising network from displaying advertising that is
					not customized to you and your interests and, consequently, may be
					less relevant to you.{' '}
				</li>
			</ul>
			<P>
				You may also contact us by via the chat on our website or email as
				listed on{' '}
				<A href='https://g7express.com/contact'>
					https://g7express.com/contact
				</A>{' '}
				for assistance in exercising your right to opt out of the "sale" of
				personal information.
			</P>
			<H2>11. YOUR EUROPEAN UNION PRIVACY RIGHTS.</H2>
			<P>
				Your rights with respect to your data and Personal Information under the
				applicable laws of the European Union and its constituent countries are
				governed by the <A href='/dpa'>Data Processing Addendum</A>.
			</P>
		</main>
	);
}
