import { connect } from 'react-redux';

const General = ({ user }) => {
	return (
		<>
			{/* Description list with inline editing */}
			<div className='mt-10 divide-y divide-gray-200'>
				<div className='space-y-1'>
					<h3 className='text-lg leading-6 font-medium text-gray-900'>
						Profile
					</h3>
					<p className='max-w-2xl text-sm text-gray-500'>
						This information will be displayed publicly so be careful what you
						share.
					</p>
				</div>
				<div className='mt-6'>
					<dl className='divide-y divide-gray-200'>
						<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
							<dt className='text-sm font-medium text-gray-500'>
								Display Name
							</dt>
							<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
								<span className='flex-grow'>{user.displayName}</span>
								<span className='ml-4 flex-shrink-0'>
									<button
										type='button'
										className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
									>
										Update
									</button>
								</span>
							</dd>
						</div>
						<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
							<dt className='text-sm font-medium text-gray-500'>Photo</dt>
							<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
								<span className='flex-grow'>
									<img
										className='h-8 w-8 rounded-full'
										src='https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
										alt=''
									/>
								</span>
								<span className='ml-4 flex-shrink-0 flex items-start space-x-4'>
									<button
										type='button'
										className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
									>
										Update
									</button>
									<span className='text-gray-300' aria-hidden='true'>
										|
									</span>
									<button
										type='button'
										className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
									>
										Remove
									</button>
								</span>
							</dd>
						</div>
						<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
							<dt className='text-sm font-medium text-gray-500'>Password</dt>
							<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
								<span className='flex-grow'>*********</span>
								<span className='ml-4 flex-shrink-0'>
									<button
										type='button'
										className='bg-white rounded-md font-medium text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
									>
										Update
									</button>
								</span>
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.currentUser,
});

export default connect(mapStateToProps)(General);
