import React from 'react';
import { Field } from 'react-final-form';
import {
	FieldSet,
	Subtitle,
	TitleSet,
	Card,
	Input,
	Checkbox,
	FORM_GRID,
} from '../components';
import RouterLink from '../RouterLink';
import { confirmedPasswordMatched } from '../validators';
import { A } from '../../../components/common';

export default function Fields({ values }) {
	return (
		<FieldSet>
			<TitleSet>
				<Subtitle>
					Already a member? <RouterLink path='/signin'>Sign in</RouterLink>
				</Subtitle>
			</TitleSet>
			<Card>
				<Field name='displayName'>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Display name'
							type='text'
							size={FORM_GRID[3]}
							placeholder=''
							autoComplete='off'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
				<Field name='email'>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Email'
							type='email'
							size={FORM_GRID[3]}
							placeholder=''
							autoComplete='off'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
				<Field name='password'>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Password'
							type='password'
							size={FORM_GRID[3]}
							placeholder=''
							autoComplete='off'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
				<Field
					name='confirmedPassword'
					validate={confirmedPasswordMatched(values.password)}
				>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Confirmed password'
							type='password'
							size={FORM_GRID[3]}
							placeholder=''
							autoComplete='off'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
				<Field name='acceptance' type='checkbox'>
					{({ input, meta }) => (
						<Checkbox
							input={input}
							meta={meta}
							size={FORM_GRID[3]}
							required={true}
							disabled={false}
						>
							I accept the{' '}
							<A href='/terms-and-conditions' target='_blank'>
								Terms and Conditions
							</A>
							{' & '}
							<A href='/privacy-policy' target='_blank'>
								Privacy Policy
							</A>
						</Checkbox>
					)}
				</Field>
			</Card>
		</FieldSet>
	);
}
