import React, { useState } from 'react';
import { Field } from 'react-final-form';
import {
	FieldSet,
	Subtitle,
	TitleSet,
	Title,
	Card,
	Input,
	Select,
	Checkbox,
	FORM_GRID,
} from '../components';
import {
	composeValidators,
	mustBeNumber,
	minValue,
	maxValue,
} from '../validators';
import { parseFigures } from '../formats';
import RouterLink from '../RouterLink';
import ShipperAutoComplete from './ShipperAutoComplete';
import RecipientAutoComplete from './RecipientAutoComplete';

import { connect } from 'react-redux';
import { TYPES } from '../../../store/actions/types';

const Fields = ({ values, dispatch, recipient, shipper }) => {
	const [isSearchFrom, setIsSearchFrom] = useState(true);
	const [isSearchTo, setIsSearchTo] = useState(true);

	const onChangeShipperHandler = (e) => {
		dispatch({
			type: TYPES.UPDATE_SHIPPER,
			payload: { name: e.target.name, value: e.target.value },
		});
	};

	const onChangeRecipientHandler = (e) => {
		dispatch({
			type: TYPES.UPDATE_RECIPIENT,
			payload: { name: e.target.name, value: e.target.value },
		});
	};

	const onSearchFromClick = () => {
		setIsSearchFrom(!isSearchFrom);
	};

	const onSearchToClick = () => {
		setIsSearchTo(!isSearchTo);
	};

	return (
		<>
			<FieldSet>
				<TitleSet>
					<Title>Where from</Title>
					<Subtitle>
						Can't find the location?{' '}
						<RouterLink
							path={'/'}
							onClick={(e) => {
								e.preventDefault();
								onSearchFromClick();
							}}
						>
							{isSearchFrom ? 'Enter the address yourself.' : 'Back to search.'}
						</RouterLink>
					</Subtitle>
				</TitleSet>
				<Card>
					{isSearchFrom ? (
						<ShipperAutoComplete />
					) : (
						<>
							<Select
								input={{
									name: 'countryCode',
									value: shipper.countryCode,
									onChange: onChangeShipperHandler,
								}}
								meta={{ error: null, touched: null }}
								label='Country *'
								size={FORM_GRID[0]}
								required={true}
							>
								<option value=''>Select a country</option>
								<option value='US'>United States</option>
								<option value='CA'>Canada</option>
								<option value='MX'>Mexico</option>
							</Select>

							<Input
								input={{
									name: 'postalCode',
									value: shipper.postalCode,
									onChange: onChangeShipperHandler,
								}}
								meta={{ error: null, touched: null }}
								label='ZIP / Postal code *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							/>
						</>
					)}
				</Card>
			</FieldSet>
			<FieldSet>
				<TitleSet>
					<Title>Where to</Title>
					<Subtitle>
						Can't find the location?{' '}
						<RouterLink
							path={'/'}
							onClick={(e) => {
								e.preventDefault();
								onSearchToClick();
							}}
						>
							{isSearchTo ? 'Enter the address yourself.' : 'Back to search.'}
						</RouterLink>
					</Subtitle>
				</TitleSet>
				<Card>
					{isSearchTo ? (
						<RecipientAutoComplete />
					) : (
						<>
							<Select
								input={{
									name: 'countryCode',
									value: recipient.countryCode,
									onChange: onChangeRecipientHandler,
								}}
								meta={{ error: null, touched: null }}
								label='Country *'
								size={FORM_GRID[0]}
								required={true}
							>
								<option value=''>Select a country</option>
								<option value='US'>United States</option>
								<option value='CA'>Canada</option>
								<option value='MX'>Mexico</option>
								<option value='AU'>Australia</option>
								<option value='VN'>Vietnam</option>
							</Select>

							{recipient.countryCode === 'VN' ? (
								<Input
									input={{
										name: 'city',
										value: recipient.city,
										onChange: onChangeRecipientHandler,
									}}
									meta={{ error: null, touched: null }}
									label='Destination City/Province'
									type='text'
									size={FORM_GRID[2]}
									placeholder=''
									autoComplete='on'
									required={true}
									disabled={false}
								/>
							) : (
								<Input
									input={{
										name: 'postalCode',
										value: recipient.postalCode,
										onChange: onChangeRecipientHandler,
									}}
									meta={{ error: null, touched: null }}
									label='ZIP / Postal code *'
									type='text'
									size={FORM_GRID[2]}
									placeholder=''
									autoComplete='on'
									required={true}
									disabled={false}
								/>
							)}
						</>
					)}
					<Field name='residential' type='checkbox'>
						{({ input, meta }) => (
							<Checkbox
								input={input}
								meta={meta}
								size={FORM_GRID[3]}
								required={false}
								disabled={false}
							>
								I'm shipping to a resident
							</Checkbox>
						)}
					</Field>
				</Card>
			</FieldSet>

			<FieldSet>
				<TitleSet>
					<Title>{'Packaging type'}</Title>
					<Subtitle>
						Fedex Envelope dimensions: 9-1/2" x 12-1/2" or 9-1/2" x 15-1/2"
						(reusable). Maximum volume 250 cubic inches
					</Subtitle>
				</TitleSet>
				<Card>
					<Field name='packagingType'>
						{({ input, meta }) => (
							<Select
								input={input}
								meta={meta}
								label='Packaging type *'
								size={FORM_GRID[0]}
								required={true}
							>
								<option value=''>Select a packaging type</option>
								<option value='YOUR_PACKAGING'>Your Packaging</option>
								<option value='FEDEX_ENVELOPE'>Courier's Envelope</option>
							</Select>
						)}
					</Field>
				</Card>
			</FieldSet>

			{values.packagingType !== 'FEDEX_ENVELOPE' && (
				<FieldSet>
					<TitleSet>
						<Title>{'Package dimensions'}</Title>
						<Subtitle>Maximum weight is 150 lbs</Subtitle>
					</TitleSet>
					<Card>
						<Field
							name='length'
							validate={composeValidators(mustBeNumber, minValue(1))}
							parse={parseFigures}
						>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='Length *'
									type='text'
									size={FORM_GRID[2]}
									placeholder={'in'}
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
						<Field
							name='width'
							validate={composeValidators(mustBeNumber, minValue(1))}
							parse={parseFigures}
						>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='Width *'
									type='text'
									size={FORM_GRID[2]}
									placeholder='in'
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
						<Field
							name='height'
							validate={composeValidators(mustBeNumber, minValue(1))}
							parse={parseFigures}
						>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='Height *'
									type='text'
									size={FORM_GRID[2]}
									placeholder='in'
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
					</Card>
				</FieldSet>
			)}

			<FieldSet>
				<TitleSet>
					<Title>{'Package weight'}</Title>
					<Subtitle>Maximum weight is 150 lbs</Subtitle>
				</TitleSet>
				<Card>
					<Field
						name='weight'
						validate={composeValidators(mustBeNumber, maxValue(150))}
						parse={parseFigures}
					>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Lb(s) *'
								type='text'
								size={FORM_GRID[1]}
								placeholder='lb'
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field
						name='ozUnit'
						validate={composeValidators(mustBeNumber, maxValue(15))}
						parse={parseFigures}
					>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Oz(s) *'
								type='text'
								size={FORM_GRID[1]}
								placeholder='oz'
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
				</Card>
			</FieldSet>

			<FieldSet>
				<TitleSet>
					<Title>Ship date</Title>
					<Subtitle>
						The day that shipment's label is scanned and shipped
					</Subtitle>
				</TitleSet>
				<Card>
					<Field name='shipDate'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Ship date *'
								type='date'
								size={FORM_GRID[3]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
				</Card>
			</FieldSet>
		</>
	);
};

const mapStateToProps = (state) => ({
	shipper: state.price.shipper,
	recipient: state.price.recipient,
});

export default connect(mapStateToProps)(Fields);
