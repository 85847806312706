import React, { useState } from 'react';

import PageTitle from '../components/PageTitle';
import GetQuotesForm from '../lib/form/get-quotes-form';

const initialState = '';

export default function GetQuotes() {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			<PageTitle title='Get Quotes' />
			<GetQuotesForm
				setRedirectPath={setRedirectPath}
				redirectPath={redirectPath}
			/>
		</main>
	);
}
