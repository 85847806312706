import React from 'react';
import { P, H2, Strong, A } from '../components/common';
import PageTitle from '../components/PageTitle';

export default function Dpa() {
	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			<PageTitle title={'Data Processing Addendum'} />
			<br />
			<P>Last Modified: September 29th, 2021</P>

			<P>
				This is the G7 Express Data Processing Addendum (“
				<Strong>DPA</Strong>”). This DPA is intended to comply with the
				applicable Data Protection Law (defined below) of the European Union and
				its constituent countries. Because of that, it is a formally structured
				document, with the terms, definitions and language necessitated by the
				Data Protection Law and the regulators who help oversee compliance with
				that law.
			</P>
			<P>
				Included with this DPA are the{' '}
				<Strong>Standard Contractual Clauses</Strong> adopted by the European
				Commission as well as certain Appendices which provide additional
				disclosure about how G7 Express will use and protect your data. More
				information about G7 Express’s data processing and usage can be found in
				the <A href='/privacy-policy'>Privacy Policy</A> as that is updated from
				time to time. Overall, this DPA describes our specific legal obligations
				under the Data Protection Law and your agreement with respect to the
				terms governing the Processing of your Personal Data under the G7
				Express <A href='/terms-and-conditions'>Terms of Use</A> (the{' '}
				<Strong>“Terms of Use”</Strong>). This DPA is an amendment to the Terms
				of Use and is effective upon your acknowledgment of the Terms of Use via
				the acceptance procedure on the G7 Express site. This DPA will form a
				part of the Terms of Use.
			</P>
			<P>
				In most cases, you (our customer) will be the “Controller” of the data
				you submit to G7 Express, and G7 Express will be the “Processor.”
			</P>
			<P>
				The term of this DPA will follow the term of the Terms of Use. Terms not
				otherwise defined herein will have the meaning as set forth in the Terms
				of Use.
			</P>
			<H2>THIS DPA INCLUDES:</H2>
			<ul>
				<li>
					Standard Contractual Clauses, attached hereto as Exhibit 1 (toward the
					end) which describe our obligations with respect to Personal Data that
					is exported from the European Union.
				</li>
				<li>
					Annex I to the Standard Contractual Clauses, which includes specifics
					on the Personal Data transferred by the data exporter to the data
					importer.
				</li>
				<li>
					Annex II to the Standard Contractual Clauses, which includes a
					description of the technical and organizational security measures
					implemented by the data importer as referenced.
				</li>
				<li>
					List of our Sub-Processors, attached hereto as Exhibit 2, which will
					be updated from time to time and lists the entities we use to help us
					process data to provide our Services
				</li>
			</ul>
			<h3>1. Definitions</h3>
			<P>
				<Strong>“Controller”</Strong> means the natural or legal person, public
				authority, agency or other body which, alone or jointly with others,
				determines the purposes and means of the Processing of Personal Data.
			</P>
			<P>
				<Strong>“Data Protection Law”</Strong> means the EU Data Protection
				Directive 95/46/EC and all local laws and regulations which amend or
				replace any of them, including the GDPR, together with any national
				implementing laws in any Member State of the European Union, as amended,
				repealed, consolidated or replaced from time to time.
			</P>
			<P>
				<Strong>“Data Subject”</Strong> means the individual to whom Personal
				Data relates, solely to the extent such individual is a resident of the
				European Union.
			</P>
			<P>
				<Strong>“GDPR”</Strong> means the General Data Protection Regulation
				(EU) 2016/679 of the European Parliament and of the Council of 27 April
				2016 on the protection of natural persons with regard to the processing
				of personal data and on the free movement of such data.
			</P>
			<P>
				<Strong>“Instruction”</Strong> means the written, documented
				instruction, issued by Controller to Processor, and directing the same
				to perform a specific action with regard to Personal Data (including,
				but not limited to, depersonalizing, blocking, deletion, making
				available).{' '}
			</P>
			<P>
				<Strong>“Personal Data”</Strong> means any information relating to an
				identified or identifiable individual where such information is
				contained within data provided by data exporter to data importer under
				this DPA and is protected similarly as personal data or personally
				identifiable information under applicable Data Protection Law.
			</P>
			<P>
				<Strong>“Personal Data Breach”</Strong> means a breach of security
				leading to the accidental or unlawful destruction, loss, alteration,
				unauthorized disclosure of, or access to, Personal Data transmitted,
				stored or otherwise processed.
			</P>
			<P>
				<Strong>“Processing”</Strong> means any operation or set of operations
				which is performed on Personal Data, encompassing the collection,
				recording, organization, structuring, storage, adaptation or alteration,
				retrieval, consultation, use, disclosure by transmission, dissemination
				or otherwise making available, alignment or combination, restriction or
				erasure of Personal Data.
			</P>
			<P>
				<Strong>“Processor”</Strong> means a natural or legal person, public
				authority, agency or other body which processes Personal Data on behalf
				of the Controller.
			</P>
			<P>
				<Strong>“Standard Contractual Clauses”</Strong> means the clauses
				attached hereto as Exhibit 1 pursuant to the European Commission’s
				Implementing Decision (EU) 2021/914 of 4 June 2021 on standard
				contractual clauses for the transfer of personal data to third countries
				pursuant to Regulation (EU) 2016/679 of the European Parliament and of
				the Council.
			</P>
			<h3>2. Details of the Processing</h3>
			<ol type='a'>
				<li>
					<span className='underline'>
						Categories of Data Subjects Covered:
					</span>{' '}
					Controller’s contacts and other end users including Controller’s
					employees, contractors, collaborators, customers, prospects, suppliers
					and subcontractors. Data Subjects also include individuals attempting
					to communicate with or transfer Personal Data to the Controller’s end
					users.
				</li>
				<li>
					<span className='underline'>Types of Personal Data Covered.</span>{' '}
					Contact Information, the extent of which is determined and controlled
					by the Customer in its sole discretion, and other Personal Data such
					as navigational data (including website usage information), email
					data, system usage data, application integration data, and other
					electronic data submitted, stored, sent, or received by end users via
					the Services.
				</li>
				<li>
					<span className='underline'>
						Subject-Matter and Nature of the Processing.
					</span>{' '}
					The subject-matter of Processing of Personal Data by Processor is the
					provision of the Services to the Controller that involves the
					Processing of Personal Data. Personal Data will be subject to those
					Processing activities as may be specified in the Terms of Use.
				</li>
				<li>
					<span className='underline'>Purpose of the Processing.</span> Personal
					Data will be Processed for purposes of providing the Services set out
					and otherwise agreed to in the Terms of Use.
				</li>
				<li>
					<span className='underline'>Duration of the Processing.</span>{' '}
					Personal Data will be Processed for the duration of the Terms of Use,
					subject to Section 4 of this DPA.
				</li>
			</ol>
			<h3>3. Customer Responsibility</h3>
			<P>
				Within the scope of the Terms of Use and in its use of the Services,
				Controller will be solely responsible for complying with the statutory
				requirements relating to data protection and privacy, in particular
				regarding the disclosure and transfer of Personal Data to the Processor
				and the Processing of Personal Data. For the avoidance of doubt,
				Controller’s instructions for the Processing of Personal Data will
				comply with the Data Protection Law. This DPA is Customer’s complete and
				final instruction to G7 Express in relation to Personal Data; additional
				instructions outside the scope of DPA would require prior written
				agreement between the parties. Instructions will initially be specified
				in the Terms of Use and may, from time to time thereafter, be amended,
				amplified or replaced by Controller in separate written Instructions (as
				individual Instructions).
			</P>
			<P>
				Controller will inform Processor without undue delay and comprehensively
				about any errors or irregularities related to statutory provisions on
				the Processing of Personal Data.
			</P>
			<h3>4. Obligations of Processor</h3>
			<ol type='a'>
				<li>
					{' '}
					<span className='underline'>Compliance with Instructions.</span> The
					parties acknowledge and agree that Customer is the Controller of
					Personal Data and G7 Express is the Processor of that data. Processor
					will collect, process and use Personal Data only within the scope of
					Controller’s Instructions. If the Processor believes that an
					Instruction of the Controller infringes the Data Protection Law, it
					will immediately inform the Controller without delay. If Processor
					cannot process Personal Data in accordance with the Instructions due
					to a legal requirement under any applicable Data Protection Law,
					Processor will (i) promptly notify the Controller of that legal
					requirement before the relevant Processing to the extent permitted by
					the Data Protection Law; and (ii) cease all Processing (other than
					merely storing and maintaining the security of the affected Personal
					Data) until such time as the Controller issues new Instructions with
					which Processor is able to comply. If this provision is invoked,
					Processor will not be liable to the Controller under the Terms of Use
					or under any other theory of liability for any failure to perform the
					applicable Services until such time as the Controller issues new
					Instructions in regard to the Processing.{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>Security.</span> Processor will take the
					appropriate technical and organizational measures intended to
					adequately protect Personal Data against accidental or unlawful
					destruction, loss, alteration, unauthorized disclosure of, or access
					to Personal Data, described under Appendix 2 to the Standard
					Contractual Clauses. Such measures include, but are not be limited to:
					<ol type='i'>
						<li>
							the prevention of unauthorized persons from gaining access to
							Personal Data Processing systems (physical access control);
						</li>
						<li>
							the prevention of Personal Data Processing systems from being used
							without authorization (logical access control);
						</li>
						<li>
							ensuring that persons entitled to use a Personal Data Processing
							system gain access only to such Personal Data as they are entitled
							to accessing in accordance with their access rights, and that, in
							the course of Processing or use and after storage, Personal Data
							cannot be read, copied, modified or deleted without authorization
							(data access control);
						</li>
						<li>
							ensuring that Personal Data cannot be read, copied, modified or
							deleted without authorization during electronic transmission,
							transport or storage on storage media, and that the target
							entities for any transfer of Personal Data by means of data
							transmission facilities can be established and verified (data
							transfer control);
						</li>
						<li>
							ensuring the establishment of an audit trail to document whether
							and by whom Personal Data have been entered into, modified in, or
							removed from Personal Data Processing systems (entry control);
						</li>
						<li>
							ensuring that Personal Data is Processed solely in accordance with
							the Instructions (control of instructions); and
						</li>
						<li>
							ensuring that Personal Data is protected against accidental
							destruction or loss (availability control).
						</li>
					</ol>
					<br />
					Upon Controller’s request, Processor will provide a current Privacy
					Policy and any additional data protection plan adopted by Processor
					relating to the Processing hereunder. <br />
					<br />
					Processor will facilitate Controller’s compliance with Controller’s
					obligation to implement security measures with respect to Personal
					Data (including if applicable Controller’s obligations pursuant to
					Articles 32 to 34 (inclusive) of the GDPR), by (i) implementing and
					maintaining the security measures described in Annex II, (ii)
					complying with the terms of Section 4(d) (Personal Data Breaches); and
					(iii) providing Controller with information in relation to the
					Processing in accordance with Section 5 (Audits).{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>Confidentiality.</span> Processor will
					ensure that any personnel whom Processor authorizes to Process
					Personal Data on its behalf is subject to confidentiality obligations
					with respect to that Personal Data. The undertaking to confidentiality
					will continue after the termination of the above-entitled activities.{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>Personal Data Breaches.</span> Processor
					will notify the Controller as soon as practicable after it becomes
					aware of any of any Personal Data Breach affecting any Personal Data.
					At the Controller’s request, Processor will promptly provide
					Controller with all reasonable assistance necessary to enable
					Controller to report Personal Data Breaches to competent authorities
					and/or affected Data Subjects, if Controller is required to do so
					under the Data Protection Law.{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>Data Subject Requests.</span> Processor
					will provide reasonable assistance, including by appropriate technical
					and organizational measures and taking into account the nature of the
					Processing, to enable Controller to respond to any request from Data
					Subjects seeking to exercise their rights under the Data Protection
					Law with respect to Personal Data (including access, rectification,
					restriction, deletion or portability of Personal Data, as applicable),
					to the extent permitted by the Data Protection Law. If such request is
					made directly to Processor, Processor will promptly inform Controller
					and will advise Data Subjects to submit their request to the
					Controller. Controller will be solely responsible for responding to
					any Data Subjects’ requests. Controller will reimburse Processor for
					the costs arising from this assistance.{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>Sub-Processors.</span> Processor will be
					entitled to engage sub-Processors to fulfill Processor’s obligations
					defined in the Terms of Use. For these purposes, Controller consents
					to the engagement as sub-Processors of Processor’s affiliated
					companies and the third parties listed in Exhibit 2. For the avoidance
					of doubt, the above authorization constitutes Controller’s prior
					written consent to the sub-Processing by Processor for purposes of
					Clause 9 of the Standard Contractual Clauses. <br />
					<br />
					If the Processor intends to instruct sub-Processors other than the
					companies listed in Exhibit 2, the Processor will notify the
					Controller thereof in writing (email to the email address(es) on
					record in Processor’s account information for Controller is
					sufficient) and will give the Controller the opportunity to object to
					the engagement of the new sub-Processors within 30 days after being
					notified. The objection must be based on reasonable grounds (e.g. if
					the Controller proves that significant risks for the protection of its
					Personal Data exist at the sub-Processor). If the Processor and
					Controller are unable to resolve such objection, either party may
					terminate the Terms of Use by providing written notice to the other
					party. Controller will receive a refund of any prepaid but unused fees
					for the period following the effective date of termination. <br />
					<br />
					Where Processor engages sub-Processors, Processor will enter into a
					contract with the sub-Processor that imposes on the sub-Processor the
					same obligations that apply to Processor under this DPA. Where the
					sub-Processor fails to fulfill its data protection obligations,
					Processor will remain liable to the Controller for the performance of
					such sub-Processors obligations. <br />
					<br />
					Where a sub-Processor is engaged, the Controller must be granted the
					right to monitor and inspect the sub-Processor’s activities in
					accordance with this DPA and the Data Protection Law, including to
					obtain information from the Processor, upon written request, on the
					substance of the contract and the implementation of the data
					protection obligations under the sub-Processing contract, where
					necessary by inspecting the relevant contract documents. <br />
					<br />
					The provisions of this Section 4(f) will mutually apply if the
					Processor engages a sub-Processor in a country outside the European
					Economic Area (“EEA”) not recognized by the European Commission as
					providing an adequate level of protection for personal data. If, in
					the performance of this DPA, G7 Express transfers any Personal Data to
					a sub-processor located outside of the EEA, G7 Express will, in
					advance of any such transfer, ensure that a legal mechanism to achieve
					adequacy in respect of that processing is in place.{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>Data Transfers.</span> Controller
					acknowledges and agrees that, in connection with the performance of
					the Services, Personal Data will be transferred to G7 Express in the
					United States. The Standard Contractual Clauses at Exhibit 1 will
					apply with respect to Personal Data that is transferred outside the
					EEA, either directly or via onward transfer, to any country not
					recognized by the European Commission as providing an adequate level
					of protection for personal data (as described in the Data Protection
					Law).{' '}
				</li>
				<li>
					{' '}
					<span className='underline'>
						Deletion or Retrieval of Personal Data.
					</span>{' '}
					Other than to the extent Processor is permitted or required to retain
					copies of Personal Data to comply with Data Protection Law, following
					termination or expiry of the Terms of Use, Processor will delete all
					Personal Data (including copies thereof) processed pursuant to this
					DPA. If Processor is unable to delete Personal Data for technical or
					other reasons, Processor will apply measures to ensure that Personal
					Data is blocked from any further Processing. <br />
					<br />
					Controller will, upon termination or expiration of the Terms of Use
					and by way of issuing an Instruction, stipulate, within a period of
					time set by Processor, the reasonable measures to return data or to
					delete stored data. Any additional cost arising in connection with the
					return or deletion of Personal Data after the termination or
					expiration of the Terms of Use will be borne by Controller.{' '}
				</li>
			</ol>
			<h3>5. Audits</h3>
			<P>
				Controller may, prior to the commencement of Processing, and at regular
				intervals thereafter, audit the technical and organizational measures
				taken by Processor.
			</P>
			<P>For such purpose, Controller may, for example:</P>
			<ul>
				<li>obtain information from the Processor,</li>
				<li>
					request Processor to submit to Controller an existing attestation or
					certificate by an independent professional expert, or
				</li>
				<li>
					upon reasonable and timely advance agreement, during regular business
					hours and without interrupting Processor’s business operations,
					conduct an on-site inspection of Processor’s business operations or
					have the same conducted by a qualified third party which will not be a
					competitor of Processor.{' '}
				</li>
			</ul>
			<P>
				Processor will, upon Controller’s written request and within a
				reasonable period of time, provide Controller with all information
				necessary for such audit, to the extent that such information is within
				Processor’s control and Processor is not precluded from disclosing it by
				applicable law, a duty of confidentiality, or any other obligation owed
				to a third party.{' '}
			</P>
			<h3>6. General Provisions</h3>
			<P>
				With respect to updates and changes to this DPA, the terms that apply in
				the “Changes to our Terms and Privacy Policy” section in the Terms of
				Use will apply.{' '}
			</P>
			<P>
				In case of any conflict, this DPA will take precedence over the
				regulations of the Terms of Use. Where individual provisions of this DPA
				are invalid or unenforceable, the validity and enforceability of the
				other provisions of this DPA will not be affected.{' '}
			</P>
			<P>
				Upon the incorporation of this DPA into the Terms of Use, the parties
				indicated in Section 7 below (Parties to this DPA) are agreeing to the
				Standard Contractual Clauses (where and as applicable) and all annexes
				attached thereto. In the event of any conflict or inconsistency between
				this DPA and the Standard Contractual Clauses in Exhibit 1, the Standard
				Contractual Clauses will prevail.{' '}
			</P>
			<P>
				Effective 25 May 2018 G7 Express will process Personal Data in
				accordance with the GDPR requirements contained herein which are
				directly applicable to G7 Express's provision of the Services.
			</P>
			<h3>7. Parties to this DPA</h3>
			<P>
				This DPA is an amendment to and forms part of the Terms of Use. Upon the
				incorporation of this DPA into the Terms of Use (i) Controller and the
				G7 Express entity that are each a party to the Terms of Use are also
				each a party to this DPA, and (ii) to the extent that G7 Express is not
				the direct party to the Terms of Use, G7 Express is still a party to
				this DPA, but only with respect to agreement to the Standard Contractual
				Clauses of the DPA, this Section 7 of the DPA, and to the Standard
				Contractual Clauses themselves.{' '}
			</P>
			<P>
				<i>
					If G7 Express is not a party to the Terms of Use, the section of the
					Terms of Use entitled ‘Limitation of Liability’ will apply as between
					Controller and G7 Express, and in such respect any references to ‘G7
					Express’, ‘we’, ‘us’ or ‘our’ will include both G7 Express and the G7
					Express entity that is a party to the Terms of Use.{' '}
				</i>
			</P>
			<P>
				The legal entity agreeing to this DPA as Controller represents that it
				is authorized to agree to and enter into this DPA for, and is agreeing
				to this DPA solely on behalf of, the Controller.{' '}
			</P>
			<H2>EXHIBIT 1</H2>
			<P>
				<b>STANDARD CONTRACTUAL CLAUSES</b>
			</P>
			<P>
				<b>Controller to Processor</b>
			</P>
			<P>
				<b>SECTION I</b>
			</P>
			<P>
				<b>
					<i> Clause 1</i>
				</b>
			</P>
			<P>
				<b>Purpose and scope</b>
			</P>
			<P>
				(a) The purpose of these standard contractual clauses is to ensure
				compliance with the requirements of Regulation (EU) 2016/679 of the
				European Parliament and of the Council of 27 April 2016 on the
				protection of natural persons with regard to the processing of personal
				data and on the free movement of such data (General Data Protection
				Regulation) for the transfer of personal data to a third country.
			</P>
			<P>(b) The Parties:</P>
			<P>
				(i) the natural or legal person(s), public authority/ies, agency/ies or
				other body/ies (hereinafter ‘entity/ies’) transferring the personal
				data, as listed in Annex I.A (hereinafter each ‘data exporter’), and
			</P>
			<P>
				(ii) the entity/ies in a third country receiving the personal data from
				the data exporter, directly or indirectly via another entity also Party
				to these Clauses, as listed in Annex I.A (hereinafter each ‘data
				importer’)
			</P>
			<P>
				have agreed to these standard contractual clauses (hereinafter:
				‘Clauses’).
			</P>
			<P>
				(c) These Clauses apply with respect to the transfer of personal data as
				specified in Annex I.B.
			</P>
			<P>
				(d) The Appendix to these Clauses containing the Annexes referred to
				therein forms an integral part of these Clauses.
			</P>
			<P>
				<b>
					<i> Clause 2</i>
				</b>
			</P>
			<P>
				<b>Effect and invariability of the Clauses</b>
			</P>
			<P>
				(a) These Clauses set out appropriate safeguards, including enforceable
				data subject rights and effective legal remedies, pursuant to Article
				46(1) and Article 46(2)(c) of Regulation (EU) 2016/679 and, with respect
				to data transfers from controllers to processors and/or processors to
				processors, standard contractual clauses pursuant to Article 28(7) of
				Regulation (EU) 2016/679, provided they are not modified, except to
				select the appropriate Module(s) or to add or update information in the
				Appendix. This does not prevent the Parties from including the standard
				contractual clauses laid down in these Clauses in a wider contract
				and/or to add other clauses or additional safeguards, provided that they
				do not contradict, directly or indirectly, these Clauses or prejudice
				the fundamental rights or freedoms of data subjects.
			</P>
			<P>
				(b) These Clauses are without prejudice to obligations to which the data
				exporter is subject by virtue of Regulation (EU) 2016/679.
			</P>
			<P>
				<b>
					<i> Clause 3</i>
				</b>
			</P>
			<P>
				<b>Third-party beneficiaries</b>
			</P>
			<P>
				(a) Data subjects may invoke and enforce these Clauses, as third-party
				beneficiaries, against the data exporter and/or data importer, with the
				following exceptions:
			</P>
			<P>(i) Clause 1, Clause 2, Clause 3, Clause 6, Clause 7;</P>
			<P>(ii) Clause 8.1(b), 8.9(a), (c), (d) and (e); </P>
			<P>(iii) Clause 9(a), (c), (d) and (e); </P>
			<P>(iv) Clause 12(a), (d) and (f);</P>
			<P>(v) Clause 13;</P>
			<P>(vi) Clause 15.1(c), (d) and (e);</P>
			<P>(vii) Clause 16(e);</P>
			<P>(viii) Clause 18(a) and (b).</P>
			<P>
				(b) Paragraph (a) is without prejudice to rights of data subjects under
				Regulation (EU) 2016/679.
			</P>
			<P>
				<b>
					<i> Clause 4</i>
				</b>
			</P>
			<P>
				<b>Interpretation</b>
			</P>
			<P>
				(a) Where these Clauses use terms that are defined in Regulation (EU)
				2016/679, those terms shall have the same meaning as in that Regulation.
			</P>
			<P>
				(b) These Clauses shall be read and interpreted in the light of the
				provisions of Regulation (EU) 2016/679.
			</P>
			<P>
				(c) These Clauses shall not be interpreted in a way that conflicts with
				rights and obligations provided for in Regulation (EU) 2016/679.
			</P>
			<P>
				<b>
					<i> Clause 5</i>
				</b>
			</P>
			<P>
				<b>Hierarchy</b>
			</P>
			<P>
				In the event of a contradiction between these Clauses and the provisions
				of related agreements between the Parties, existing at the time these
				Clauses are agreed or entered into thereafter, these Clauses shall
				prevail.
			</P>
			<P>
				<b>
					<i> Clause 6</i>
				</b>
			</P>
			<P>
				<b>Description of the transfer(s)</b>
			</P>
			<P>
				The details of the transfer(s), and in particular the categories of
				personal data that are transferred and the purpose(s) for which they are
				transferred, are specified in Annex I.B.
			</P>
			<P>
				<b>
					<i> Clause 7 – Optional</i>
				</b>
			</P>
			<P>
				<b>Docking clause</b>
			</P>
			<P>
				(a) An entity that is not a Party to these Clauses may, with the
				agreement of the Parties, accede to these Clauses at any time, either as
				a data exporter or as a data importer, by completing the Appendix and
				signing Annex I.A.
			</P>
			<P>
				(b) Once it has completed the Appendix and signed Annex I.A, the
				acceding entity shall become a Party to these Clauses and have the
				rights and obligations of a data exporter or data importer in accordance
				with its designation in Annex I.A.
			</P>
			<P>
				(c) The acceding entity shall have no rights or obligations arising
				under these Clauses from the period prior to becoming a Party.
			</P>
			<P>
				<b>SECTION II – OBLIGATIONS OF THE PARTIES</b>
			</P>
			<P>
				<b>
					<i> Clause 8</i>
				</b>
			</P>
			<P>
				<b>Data protection safeguards</b>
			</P>
			<P>
				The data exporter warrants that it has used reasonable efforts to
				determine that the data importer is able, through the implementation of
				appropriate technical and organizational measures, to satisfy its
				obligations under these Clauses.
			</P>
			<P>
				<b>8.1 Instructions</b>
			</P>
			<P>
				(a)<b></b> The data importer shall process the personal data only on
				documented instructions from the data exporter. The data exporter may
				give such instructions throughout the duration of the contract.
			</P>
			<P>
				(b) The data importer shall immediately inform the data exporter if it
				is unable to follow those instructions.
			</P>
			<P>
				<b>8.2 Purpose limitation</b>
			</P>
			<P>
				The data importer shall process the personal data only for the specific
				purpose(s) of the transfer, as set out in Annex I.B, unless on further
				instructions from the data exporter.
			</P>
			<P>
				<b>8.3 Transparency</b>
			</P>
			<P>
				On request, the data exporter shall make a copy of these Clauses,
				including the Appendix as completed by the Parties, available to the
				data subject free of charge. To the extent necessary to protect business
				secrets or other confidential information, including the measures
				described in Annex II and personal data, the data exporter may redact
				part of the text of the Appendix to these Clauses prior to sharing a
				copy, but shall provide a meaningful summary where the data subject
				would otherwise not be able to understand the its content or exercise
				his/her rights. On request, the Parties shall provide the data subject
				with the reasons for the redactions, to the extent possible without
				revealing the redacted information. This Clause is without prejudice to
				the obligations of the data exporter under Articles 13 and 14 of
				Regulation (EU) 2016/679.
			</P>
			<P>
				<b>8.4 Accuracy</b>
			</P>
			<P>
				If the data importer becomes aware that the personal data it has
				received is inaccurate, or has become outdated, it shall inform the data
				exporter without undue delay. In this case, the data importer shall
				cooperate with the data exporter to erase or rectify the data.
			</P>
			<P>
				<b>8.5 Duration of processing and erasure or return of data</b>
			</P>
			<P>
				Processing by the data importer shall only take place for the duration
				specified in Annex I.B. After the end of the provision of the processing
				services, the data importer shall, at the choice of the data exporter,
				delete all personal data processed on behalf of the data exporter and
				certify to the data exporter that it has done so, or return to the data
				exporter all personal data processed on its behalf and delete existing
				copies. Until the data is deleted or returned, the data importer shall
				continue to ensure compliance with these Clauses. In case of local laws
				applicable to the data importer that prohibit return or deletion of the
				personal data, the data importer warrants that it will continue to
				ensure compliance with these Clauses and will only process it to the
				extent and for as long as required under that local law. This is without
				prejudice to Clause 14, in particular the requirement for the data
				importer under Clause 14(e) to notify the data exporter throughout the
				duration of the contract if it has reason to believe that it is or has
				become subject to laws or practices not in line with the requirements
				under Clause 14(a).
			</P>
			<P>
				<b>8.6 Security of processing</b>
			</P>
			<P>
				(a) The data importer and, during transmission, also the data exporter
				shall implement appropriate technical and organizational measures to
				ensure the security of the data, including protection against a breach
				of security leading to accidental or unlawful destruction, loss,
				alteration, unauthorised disclosure or access to that data (hereinafter
				‘personal data breach’). In assessing the appropriate level of security,
				the Parties shall take due account of the state of the art, the costs of
				implementation, the nature, scope, context and purpose(s) of processing
				and the risks involved in the processing for the data subjects. The
				Parties shall in particular consider having recourse to encryption or
				pseudonymisation, including during transmission, where the purpose of
				processing can be fulfilled in that manner. In case of pseudonymisation,
				the additional information for attributing the personal data to a
				specific data subject shall, where possible, remain under the exclusive
				control of the data exporter. In complying with its obligations under
				this paragraph, the data importer shall at least implement the technical
				and organizational measures specified in Annex II. The data importer
				shall carry out regular checks to ensure that these measures continue to
				provide an appropriate level of security.
			</P>
			<P>
				(b) The data importer shall grant access to the personal data to members
				of its personnel only to the extent strictly necessary for the
				implementation, management and monitoring of the contract. It shall
				ensure that persons authorised to process the personal data have
				committed themselves to confidentiality or are under an appropriate
				statutory obligation of confidentiality.
			</P>
			<P>
				(c) In the event of a personal data breach concerning personal data
				processed by the data importer under these Clauses, the data importer
				shall take appropriate measures to address the breach, including
				measures to mitigate its adverse effects. The data importer shall also
				notify the data exporter without undue delay after having become aware
				of the breach. Such notification shall contain the details of a contact
				point where more information can be obtained, a description of the
				nature of the breach (including, where possible, categories and
				approximate number of data subjects and personal data records
				concerned), its likely consequences and the measures taken or proposed
				to address the breach including, where appropriate, measures to mitigate
				its possible adverse effects. Where, and in so far as, it is not
				possible to provide all information at the same time, the initial
				notification shall contain the information then available and further
				information shall, as it becomes available, subsequently be provided
				without undue delay.
			</P>
			<P>
				(d) The data importer shall cooperate with and assist the data exporter
				to enable the data exporter to comply with its obligations under
				Regulation (EU) 2016/679, in particular to notify the competent
				supervisory authority and the affected data subjects, taking into
				account the nature of processing and the information available to the
				data importer.
			</P>
			<P>
				<b>8.7 Sensitive data</b>
			</P>
			<P>
				Where the transfer involves personal data revealing racial or ethnic
				origin, political opinions, religious or philosophical beliefs, or trade
				union membership, genetic data, or biometric data for the purpose of
				uniquely identifying a natural person, data concerning health or a
				person’s sex life or sexual orientation, or data relating to criminal
				convictions and offences (hereinafter ‘sensitive data’), the data
				importer shall apply the specific restrictions and/or additional
				safeguards described in Annex I.B.
			</P>
			<P>
				<b>8.8 Onward transfers</b>
			</P>
			<P>
				The data importer shall only disclose the personal data to a third party
				on documented instructions from the data exporter. In addition, the data
				may only be disclosed to a third party located outside the European
				Union (in the same country as the data importer or in another third
				country, hereinafter ‘onward transfer’) if the third party is or agrees
				to be bound by these Clauses, under the appropriate Module, or if:
			</P>
			<P>
				(i) the onward transfer is to a country benefitting from an adequacy
				decision pursuant to Article 45 of Regulation (EU) 2016/679 that covers
				the onward transfer;
			</P>
			<P>
				(ii) the third party otherwise ensures appropriate safeguards pursuant
				to Articles 46 or 47 Regulation of (EU) 2016/679 with respect to the
				processing in question;
			</P>
			<P>
				(iii) the onward transfer is necessary for the establishment, exercise
				or defence of legal claims in the context of specific administrative,
				regulatory or judicial proceedings; or
			</P>
			<P>
				(iv) the onward transfer is necessary in order to protect the vital
				interests of the data subject or of another natural person.
			</P>
			<P>
				Any onward transfer is subject to compliance by the data importer with
				all the other safeguards under these Clauses, in particular purpose
				limitation.
			</P>
			<P>
				<b>8.9 Documentation and compliance</b>
			</P>
			<P>
				(a) The data importer shall promptly and adequately deal with enquiries
				from the data exporter that relate to the processing under these
				Clauses.
			</P>
			<P>
				(b) The Parties shall be able to demonstrate compliance with these
				Clauses. In particular, the data importer shall keep appropriate
				documentation on the processing activities carried out on behalf of the
				data exporter.
			</P>
			<P>
				(c) The data importer shall make available to the data exporter all
				information necessary to demonstrate compliance with the obligations set
				out in these Clauses and at the data exporter’s request, allow for and
				contribute to audits of the processing activities covered by these
				Clauses, at reasonable intervals or if there are indications of
				non-compliance. In deciding on a review or audit, the data exporter may
				take into account relevant certifications held by the data importer.
			</P>
			<P>
				(d) The data exporter may choose to conduct the audit by itself or
				mandate an independent auditor. Audits may include inspections at the
				premises or physical facilities of the data importer and shall, where
				appropriate, be carried out with reasonable notice.
			</P>
			<P>
				(e) The Parties shall make the information referred to in paragraphs (b)
				and (c), including the results of any audits, available to the competent
				supervisory authority on request.
			</P>
			<P>
				<b>
					<i> Clause 9</i>
				</b>
			</P>
			<P>
				<b>Use of sub-processors</b>
			</P>
			<P>
				(a) The data importer has the data exporter’s general authorisation for
				the engagement of sub-processor(s) from an agreed list. The data
				importer shall specifically inform the data exporter in writing of any
				intended changes to that list through the addition or replacement of
				sub-processors at least 30 days in advance, thereby giving the data
				exporter sufficient time to be able to object to such changes prior to
				the engagement of the sub-processor(s). The data importer shall provide
				the data exporter with the information necessary to enable the data
				exporter to exercise its right to object.
			</P>
			<P>
				(b) Where the data importer engages a sub-processor to carry out
				specific processing activities (on behalf of the data exporter), it
				shall do so by way of a written contract that provides for, in
				substance, the same data protection obligations as those binding the
				data importer under these Clauses, including in terms of third-party
				beneficiary rights for data subjects. The Parties agree that, by
				complying with this Clause, the data importer fulfils its obligations
				under Clause 8.8. The data importer shall ensure that the sub-processor
				complies with the obligations to which the data importer is subject
				pursuant to these Clauses.
			</P>
			<P>
				(c) The data importer shall provide, at the data exporter’s request, a
				copy of such a sub-processor agreement and any subsequent amendments to
				the data exporter. To the extent necessary to protect business secrets
				or other confidential information, including personal data, the data
				importer may redact the text of the agreement prior to sharing a copy.
			</P>
			<P>
				(d) The data importer shall remain fully responsible to the data
				exporter for the performance of the sub-processor’s obligations under
				its contract with the data importer. The data importer shall notify the
				data exporter of any failure by the sub-processor to fulfil its
				obligations under that contract.
			</P>
			<P>
				(e) The data importer shall agree a third-party beneficiary clause with
				the sub-processor whereby – in the event the data importer has factually
				disappeared, ceased to exist in law or has become insolvent – the data
				exporter shall have the right to terminate the sub-processor contract
				and to instruct the sub-processor to erase or return the personal data.
			</P>
			<P>
				<b>
					<i> Clause 10</i>
				</b>
			</P>
			<P>
				<b>Data subject rights</b>
			</P>
			<P>
				(a) The data importer shall promptly notify the data exporter of any
				request it has received from a data subject. It shall not respond to
				that request itself unless it has been authorised to do so by the data
				exporter.
			</P>
			<P>
				(b) The data importer shall assist the data exporter in fulfilling its
				obligations to respond to data subjects’ requests for the exercise of
				their rights under Regulation (EU) 2016/679. In this regard, the Parties
				shall set out in Annex II the appropriate technical and organizational
				measures, taking into account the nature of the processing, by which the
				assistance shall be provided, as well as the scope and the extent of the
				assistance required.
			</P>
			<P>
				(c) In fulfilling its obligations under paragraphs (a) and (b), the data
				importer shall comply with the instructions from the data exporter.
			</P>
			<P>
				<b>
					<i> Clause 11</i>
				</b>
			</P>
			<P>
				<b>Redress</b>
			</P>
			<P>
				(a) The data importer shall inform data subjects in a transparent and
				easily accessible format, through individual notice or on its website,
				of a contact point authorised to handle complaints. It shall deal
				promptly with any complaints it receives from a data subject.
			</P>
			<P>
				(b) In case of a dispute between a data subject and one of the Parties
				as regards compliance with these Clauses, that Party shall use its best
				efforts to resolve the issue amicably in a timely fashion. The Parties
				shall keep each other informed about such disputes and, where
				appropriate, cooperate in resolving them.
			</P>
			<P>
				(c) Where the data subject invokes a third-party beneficiary right
				pursuant to Clause 3, the data importer shall accept the decision of the
				data subject to:
			</P>
			<P>
				(i) lodge a complaint with the supervisory authority in the Member State
				of his/her habitual residence or place of work, or the competent
				supervisory authority pursuant to Clause 13;
			</P>
			<P>
				(ii) refer the dispute to the competent courts within the meaning of
				Clause 18.
			</P>
			<P>
				(d) The Parties accept that the data subject may be represented by a
				not-for-profit body, organisation or association under the conditions
				set out in Article 80(1) of Regulation (EU) 2016/679.
			</P>
			<P>
				(e) The data importer shall abide by a decision that is binding under
				the applicable EU or Member State law.
			</P>
			<P>
				(f) The data importer agrees that the choice made by the data subject
				will not prejudice his/her substantive and procedural rights to seek
				remedies in accordance with applicable laws.
			</P>
			<P>
				<b>
					<i> Clause 12</i>
				</b>
			</P>
			<P>
				<b>Liability</b>
			</P>
			<P>
				(a) Each Party shall be liable to the other Party/ies for any damages it
				causes the other Party/ies by any breach of these Clauses.
			</P>
			<P>
				(b) The data importer shall be liable to the data subject, and the data
				subject shall be entitled to receive compensation, for any material or
				non-material damages the data importer or its sub-processor causes the
				data subject by breaching the third-party beneficiary rights under these
				Clauses.
			</P>
			<P>
				(c) Notwithstanding paragraph (b), the data exporter shall be liable to
				the data subject, and the data subject shall be entitled to receive
				compensation, for any material or non-material damages the data exporter
				or the data importer (or its sub-processor) causes the data subject by
				breaching the third-party beneficiary rights under these Clauses. This
				is without prejudice to the liability of the data exporter and, where
				the data exporter is a processor acting on behalf of a controller, to
				the liability of the controller under Regulation (EU) 2016/679 or
				Regulation (EU) 2018/1725, as applicable.
			</P>
			<P>
				(d) The Parties agree that if the data exporter is held liable under
				paragraph (c) for damages caused by the data importer (or its
				sub-processor), it shall be entitled to claim back from the data
				importer that part of the compensation corresponding to the data
				importer’s responsibility for the damage.
			</P>
			<P>
				(e) Where more than one Party is responsible for any damage caused to
				the data subject as a result of a breach of these Clauses, all
				responsible Parties shall be jointly and severally liable and the data
				subject is entitled to bring an action in court against any of these
				Parties.
			</P>
			<P>
				(f) The Parties agree that if one Party is held liable under paragraph
				(e), it shall be entitled to claim back from the other Party/ies that
				part of the compensation corresponding to its/their responsibility for
				the damage.
			</P>
			<P>
				(g) The data importer may not invoke the conduct of a sub-processor to
				avoid its own liability.
			</P>
			<P>
				<b>
					<i> Clause 13</i>
				</b>
			</P>
			<P>
				<b>Supervision</b>
			</P>
			<P>
				(a) [Where the data exporter is established in an EU Member State:] The
				supervisory authority with responsibility for ensuring compliance by the
				data exporter with Regulation (EU) 2016/679 as regards the data
				transfer, as indicated in Annex I.C, shall act as competent supervisory
				authority.
			</P>
			<P>
				[Where the data exporter is not established in an EU Member State, but
				falls within the territorial scope of application of Regulation (EU)
				2016/679 in accordance with its Article 3(2) and has appointed a
				representative pursuant to Article 27(1) of Regulation (EU) 2016/679:]
				The supervisory authority of the Member State in which the
				representative within the meaning of Article 27(1) of Regulation (EU)
				2016/679 is established, as indicated in Annex I.C, shall act as
				competent supervisory authority.
			</P>
			<P>
				[Where the data exporter is not established in an EU Member State, but
				falls within the territorial scope of application of Regulation (EU)
				2016/679 in accordance with its Article 3(2) without however having to
				appoint a representative pursuant to Article 27(2) of Regulation (EU)
				2016/679:] The supervisory authority of one of the Member States in
				which the data subjects whose personal data is transferred under these
				Clauses in relation to the offering of goods or services to them, or
				whose behaviour is monitored, are located, as indicated in Annex I.C,
				shall act as competent supervisory authority.{' '}
			</P>
			<P>
				(b) The data importer agrees to submit itself to the jurisdiction of and
				cooperate with the competent supervisory authority in any procedures
				aimed at ensuring compliance with these Clauses. In particular, the data
				importer agrees to respond to enquiries, submit to audits and comply
				with the measures adopted by the supervisory authority, including
				remedial and compensatory measures. It shall provide the supervisory
				authority with written confirmation that the necessary actions have been
				taken.
			</P>
			<P>
				<b>
					SECTION III – LOCAL LAWS AND OBLIGATIONS IN CASE OF ACCESS BY PUBLIC
					AUTHORITIES
				</b>
			</P>
			<P>
				<b>
					<i> Clause 14</i>
				</b>
			</P>
			<P>
				<b>Local laws and practices affecting compliance with the Clauses</b>
			</P>
			<P>
				&nbsp;(a) The Parties warrant that they have no reason to believe that
				the laws and practices in the third country of destination applicable to
				the processing of the personal data by the data importer, including any
				requirements to disclose personal data or measures authorizing access by
				public authorities, prevent the data importer from fulfilling its
				obligations under these Clauses. This is based on the understanding that
				laws and practices that respect the essence of the fundamental rights
				and freedoms and do not exceed what is necessary and proportionate in a
				democratic society to safeguard one of the objectives listed in Article
				23(1) of Regulation (EU) 2016/679, are not in contradiction with these
				Clauses.
			</P>
			<P>
				(b) The Parties declare that in providing the warranty in paragraph (a),
				they have taken due account in particular of the following elements:
			</P>
			<P>
				(i) the specific circumstances of the transfer, including the length of
				the processing chain, the number of actors involved and the transmission
				channels used; intended onward transfers; the type of recipient; the
				purpose of processing; the categories and format of the transferred
				personal data; the economic sector in which the transfer occurs; the
				storage location of the data transferred;
			</P>
			<P>
				(ii) the laws and practices of the third country of destination–
				including those requiring the disclosure of data to public authorities
				or authorizing access by such authorities – relevant in light of the
				specific circumstances of the transfer, and the applicable limitations
				and safeguards;
			</P>
			<P>
				(iii) any relevant contractual, technical or organizational safeguards
				put in place to supplement the safeguards under these Clauses, including
				measures applied during transmission and to the processing of the
				personal data in the country of destination.
			</P>
			<P>
				(c) The data importer warrants that, in carrying out the assessment
				under paragraph (b), it has made its best efforts to provide the data
				exporter with relevant information and agrees that it will continue to
				cooperate with the data exporter in ensuring compliance with these
				Clauses.
			</P>
			<P>
				(d) The Parties agree to document the assessment under paragraph (b) and
				make it available to the competent supervisory authority on request.
			</P>
			<P>
				(e) The data importer agrees to notify the data exporter promptly if,
				after having agreed to these Clauses and for the duration of the
				contract, it has reason to believe that it is or has become subject to
				laws or practices not in line with the requirements under paragraph (a),
				including following a change in the laws of the third country or a
				measure (such as a disclosure request) indicating an application of such
				laws in practice that is not in line with the requirements in paragraph
				(a).{' '}
			</P>
			<P>
				(f) Following a notification pursuant to paragraph (e), or if the data
				exporter otherwise has reason to believe that the data importer can no
				longer fulfil its obligations under these Clauses, the data exporter
				shall promptly identify appropriate measures (e.g. technical or
				organizational measures to ensure security and confidentiality) to be
				adopted by the data exporter and/or data importer to address the
				situation. The data exporter shall suspend the data transfer if it
				considers that no appropriate safeguards for such transfer can be
				ensured, or if instructed by the competent supervisory authority to do
				so. In this case, the data exporter shall be entitled to terminate the
				contract, insofar as it concerns the processing of personal data under
				these Clauses. If the contract involves more than two Parties, the data
				exporter may exercise this right to termination only with respect to the
				relevant Party, unless the Parties have agreed otherwise. Where the
				contract is terminated pursuant to this Clause, Clause 16(d) and (e)
				shall apply.
			</P>
			<P>
				<b>
					<i> Clause 15</i>
				</b>
			</P>
			<P>
				<b>
					Obligations of the data importer in case of access by public
					authorities
				</b>
			</P>
			<P>
				<b>15.1 Notification</b>
			</P>
			<P>
				(a) The data importer agrees to notify the data exporter and, where
				possible, the data subject promptly (if necessary with the help of the
				data exporter) if it:
			</P>
			<P>
				(i) receives a legally binding request from a public authority,
				including judicial authorities, under the laws of the country of
				destination for the disclosure of personal data transferred pursuant to
				these Clauses; such notification shall include information about the
				personal data requested, the requesting authority, the legal basis for
				the request and the response provided; or
			</P>
			<P>
				(ii) becomes aware of any direct access by public authorities to
				personal data transferred pursuant to these Clauses in accordance with
				the laws of the country of destination; such notification shall include
				all information available to the importer.
			</P>
			<P>
				(b) If the data importer is prohibited from notifying the data exporter
				and/or the data subject under the laws of the country of destination,
				the data importer agrees to use its best efforts to obtain a waiver of
				the prohibition, with a view to communicating as much information as
				possible, as soon as possible. The data importer agrees to document its
				best efforts in order to be able to demonstrate them on request of the
				data exporter.
			</P>
			<P>
				(c) Where permissible under the laws of the country of destination, the
				data importer agrees to provide the data exporter, at regular intervals
				for the duration of the contract, with as much relevant information as
				possible on the requests received (in particular, number of requests,
				type of data requested, requesting authority/ies, whether requests have
				been challenged and the outcome of such challenges, etc.).{' '}
			</P>
			<P>
				(d) The data importer agrees to preserve the information pursuant to
				paragraphs (a) to (c) for the duration of the contract and make it
				available to the competent supervisory authority on request.
			</P>
			<P>
				(e) Paragraphs (a) to (c) are without prejudice to the obligation of the
				data importer pursuant to Clause 14(e) and Clause 16 to inform the data
				exporter promptly where it is unable to comply with these Clauses.
			</P>
			<P>
				<b>15.2 Review of legality and data minimisation</b>
			</P>
			<P>
				(a) The data importer agrees to review the legality of the request for
				disclosure, in particular whether it remains within the powers granted
				to the requesting public authority, and to challenge the request if,
				after careful assessment, it concludes that there are reasonable grounds
				to consider that the request is unlawful under the laws of the country
				of destination, applicable obligations under international law and
				principles of international comity. The data importer shall, under the
				same conditions, pursue possibilities of appeal. When challenging a
				request, the data importer shall seek interim measures with a view to
				suspending the effects of the request until the competent judicial
				authority has decided on its merits. It shall not disclose the personal
				data requested until required to do so under the applicable procedural
				rules. These requirements are without prejudice to the obligations of
				the data importer under Clause 14(e).
			</P>
			<P>
				(b) The data importer agrees to document its legal assessment and any
				challenge to the request for disclosure and, to the extent permissible
				under the laws of the country of destination, make the documentation
				available to the data exporter. It shall also make it available to the
				competent supervisory authority on request.{' '}
			</P>
			<P>
				(c) The data importer agrees to provide the minimum amount of
				information permissible when responding to a request for disclosure,
				based on a reasonable interpretation of the request.
			</P>
			<P>
				<b>SECTION IV – FINAL PROVISIONS</b>
			</P>
			<P>
				<b>
					<i> Clause 16</i>
				</b>
			</P>
			<P>
				<b>Non-compliance with the Clauses and termination</b>
			</P>
			<P>
				(a) The data importer shall promptly inform the data exporter if it is
				unable to comply with these Clauses, for whatever reason.
			</P>
			<P>
				(b) In the event that the data importer is in breach of these Clauses or
				unable to comply with these Clauses, the data exporter shall suspend the
				transfer of personal data to the data importer until compliance is again
				ensured or the contract is terminated. This is without prejudice to
				Clause 14(f).
			</P>
			<P>
				(c) The data exporter shall be entitled to terminate the contract,
				insofar as it concerns the processing of personal data under these
				Clauses, where:
			</P>
			<P>
				(i) the data exporter has suspended the transfer of personal data to the
				data importer pursuant to paragraph (b) and compliance with these
				Clauses is not restored within a reasonable time and in any event within
				one month of suspension;
			</P>
			<P>
				(ii) the data importer is in substantial or persistent breach of these
				Clauses; or
			</P>
			<P>
				(iii) the data importer fails to comply with a binding decision of a
				competent court or supervisory authority regarding its obligations under
				these Clauses.
			</P>
			<P>
				In these cases, it shall inform the competent supervisory authority of
				such non-compliance. Where the contract involves more than two Parties,
				the data exporter may exercise this right to termination only with
				respect to the relevant Party, unless the Parties have agreed otherwise.
			</P>
			<P>
				(d) Personal data that has been transferred prior to the termination of
				the contract pursuant to paragraph (c) shall at the choice of the data
				exporter immediately be returned to the data exporter or deleted in its
				entirety. The same shall apply to any copies of the data. The data
				importer shall certify the deletion of the data to the data exporter.
				Until the data is deleted or returned, the data importer shall continue
				to ensure compliance with these Clauses. In case of local laws
				applicable to the data importer that prohibit the return or deletion of
				the transferred personal data, the data importer warrants that it will
				continue to ensure compliance with these Clauses and will only process
				the data to the extent and for as long as required under that local law.
			</P>
			<P>
				(e) Either Party may revoke its agreement to be bound by these Clauses
				where (i) the European Commission adopts a decision pursuant to Article
				45(3) of Regulation (EU) 2016/679 that covers the transfer of personal
				data to which these Clauses apply; or (ii) Regulation (EU) 2016/679
				becomes part of the legal framework of the country to which the personal
				data is transferred. This is without prejudice to other obligations
				applying to the processing in question under Regulation (EU) 2016/679.
			</P>
			<P>
				<b>
					<i> Clause 17</i>
				</b>
			</P>
			<P>
				<b>Governing law</b>
			</P>
			<P>
				These Clauses shall be governed by the law of one of the EU Member
				States, provided such law allows for third-party beneficiary rights. The
				Parties agree that this shall be the law of Ireland.
			</P>
			<P>
				<b>
					<i> Clause 18</i>
				</b>
			</P>
			<P>
				<b>Choice of forum and jurisdiction</b>
			</P>
			<P>
				(a) Any dispute arising from these Clauses shall be resolved by the
				courts of an EU Member State.
			</P>
			<P>(b) The Parties agree that those shall be the courts of Ireland.</P>
			<P>
				(c) A data subject may also bring legal proceedings against the data
				exporter and/or data importer before the courts of the Member State in
				which he/she has his/her habitual residence.
			</P>
			<P>
				(d) The Parties agree to submit themselves to the jurisdiction of such
				courts.
			</P>
			<br />
			<P>
				<b>ANNEX I</b>
			</P>
			<P>
				<b>A. LIST OF PARTIES</b>
			</P>
			<P>
				<b>Data exporter(s):</b>
			</P>
			<P>
				Name: The data exporter is G7 Express’s customer who has entered into
				the G7 Express Terms of Use (each, a “Customer,” and such terms, the
				“Terms of Use”), solely to the extent such individual is a resident of
				the European Union.
			</P>
			<P>
				Address: As specified in Customer’s account, which is maintained by
				Customer on the G7 Express Service.
			</P>
			<P>
				Contact person’s name, position and contact details: Contact details for
				the data exporter are specified in Customer’s account, which is
				maintained by Customer on the G7 Express Service. Details about the data
				exporter’s data protection officer (or, where the data exporter has not
				appointed a data protection officer, another appropriate point of
				contact) are available to the data importer in the form of the data
				exporter’s notification e-mail address.{' '}
			</P>
			<P>
				Activities relevant to the data transferred under these Clauses: The
				data importer provides the Processor Services to the data exporter in
				accordance with the Terms of Use.
			</P>
			<P>
				Signature and date: The parties agree that acceptance of the Terms of
				Use by the data importer and the data exporter shall constitute
				execution of these Clauses by both parties as follows:
			</P>
			<P>
				(a) on 27 October 2021, where the effective date of the Terms of Use is
				before 27 September 2021; or
			</P>
			<P>(b) otherwise, on the effective date of the Terms of Use.</P>
			<P>Role (controller/processor): controller</P>
			<P>
				<b>Data importer(s):</b>{' '}
			</P>
			<P>Name: G7 Logistics Inc</P>
			<P>Address: California</P>
			<P>Contact person’s name, position and contact details: </P>
			<P>George Phocas, Chief Legal Officer, legal@g7express.com</P>
			<P>
				Activities relevant to the data transferred under these Clauses: The
				data importer provides the Processor Services to the data exporter in
				accordance with the Terms of Use.
			</P>
			<P>
				Signature and date: The parties agree that acceptance of the Terms of
				Use by the data importer and the data exporter shall constitute
				execution of these Clauses by both parties as follows:
			</P>
			<P>
				(a) on 27 October 2021, where the effective date of the Terms of Use is
				before 27 September 2021; or
			</P>
			<P>(b) otherwise, on the effective date of the Terms of Use.</P>
			<P>Role (controller/processor): processor</P>
			<P>
				<b>B. DESCRIPTION OF TRANSFER</b>
			</P>
			<P>
				<i>Categories of data subjects whose personal data is transferred</i>
			</P>
			<P>
				Controller’s contacts and other end users including Controller’s
				employees, contractors, collaborators, customers, prospects, suppliers
				and subcontractors. Data Subjects also include individuals attempting to
				communicate with or transfer Personal Data to the Controller’s end
				users.
			</P>
			<P>
				<i>Categories of personal data transferred</i>
			</P>
			<P>
				Contact Information, the extent of which is determined and controlled by
				the Customer in its sole discretion, and other Personal Data such as
				navigational data (including website usage information), email data,
				system usage data, application integration data, and other electronic
				data submitted, stored, sent, or received by end users via the
				subscription service.
			</P>
			<P>
				<i>
					Sensitive data transferred (if applicable) and applied restrictions or
					safeguards that fully take into consideration the nature of the data
					and the risks involved, such as for instance strict purpose
					limitation, access restrictions (including access only for staff
					having followed specialised training), keeping a record of access to
					the data, restrictions for onward transfers or additional security
					measures.
				</i>
			</P>
			<P>
				The parties do not anticipate the transfer of any special categories of
				data.
			</P>
			<P>
				<i>
					The frequency of the transfer (e.g. whether the data is transferred on
					a one-off or continuous basis).
				</i>
			</P>
			<P>Continuous</P>
			<P>
				<i>Nature of the processing</i>
			</P>
			<P>
				<i>
					The subject-matter of Processing of Personal Data by Processor is the
					provision of the services to the Controller that involves the
					Processing of Personal Data. Personal Data will be subject to those
					Processing activities as may be specified in the Terms of Use.
				</i>
			</P>
			<P>
				<i>Purpose(s) of the data transfer and further processing</i>
			</P>
			<P>
				Personal Data will be Processed for purposes of providing the services
				set out and otherwise agreed to in the Terms of Use.
			</P>
			<P>
				<i>
					The period for which the personal data will be retained, or, if that
					is not possible, the criteria used to determine that period
				</i>
			</P>
			<P>
				Personal Data will be Processed for the duration of the Terms of Use,
				subject to the terms of these Clauses.
			</P>
			<P>
				<i>
					For transfers to (sub-) processors, also specify subject matter,
					nature and duration of the processing
				</i>
			</P>
			<P>As above</P>
			<P>
				<b>C. COMPETENT SUPERVISORY AUTHORITY</b>
			</P>
			<P>
				<i>
					Identify the competent supervisory authority/ies in accordance with
					Clause 13
				</i>
			</P>
			<P>
				The Irish Supervisory Authority - The Data Protection Commission, unless
				the data exporter notifies the data importer of an alternative competent
				supervisory authority from time to time in accordance with these Clauses
				and the DPA.
			</P>
			<br />
			<P>
				<b>ANNEX II</b>
			</P>
			<P>
				<b>
					TECHNICAL AND ORGANIZATIONAL MEASURES INCLUDING TECHNICAL AND
					ORGANIZATIONAL MEASURES TO ENSURE THE SECURITY OF THE DATA
				</b>
			</P>
			<P>
				(Data importer (“G7 Express”) currently observes the security practices
				described in this Annex II. Notwithstanding any provision to the
				contrary otherwise agreed to by data exporter, G7 Express may modify or
				update these practices at its discretion provided that such modification
				and update does not result in a material degradation in the protection
				offered by these practices. All capitalized terms not otherwise defined
				herein will have the meanings as set forth in the Terms of Use.
			</P>
			<ol>
				<li>Access Control</li>
				<ol>
					<li>Preventing Unauthorized Product Access</li>
					<ul>
						<li>
							Outsourced processing: G7 Express hosts its Service with
							outsourced cloud infrastructure providers. Additionally, G7
							Express maintains contractual relationships with vendors in order
							to provide the Service in accordance with our Data Processing
							Addendum. G7 Express relies on contractual agreements, privacy
							policies, and vendor compliance programs in order to protect data
							processed or stored by these vendors.
						</li>
						<li>
							Physical and environmental security: G7 Express hosts its product
							infrastructure with multi-tenant, outsourced infrastructure
							providers
						</li>
						<li>
							Authentication: G7 Express implemented a uniform password policy
							for its customer products. Customers who interact with the
							products via the user interface must authenticate before accessing
							non-public customer data.
						</li>
					</ul>
					<li>
						Preventing Unauthorized Product Use: G7 Express implements industry
						standard access controls and detection capabilities for the internal
						networks that support its products. In addition, G7 Express conducts
						security reviews of code stored in G7 Express’s source code
						repositories and checks for coding best practices and identifiable
						software flaws.
					</li>
					<li>Limitations of Privilege &amp; Authorization Requirements</li>
					<ul>
						<li>
							Product access: A subset of G7 Express’s employees have access to
							the products and to customer data via controlled interfaces. The
							intent of providing access to a subset of employees is to provide
							effective customer support, to troubleshoot potential problems, to
							detect and respond to security incidents and implement data
							security.
						</li>
						<li>
							Employee Conduct Standards. All employees are required to conduct
							themselves in a manner consistent with company guidelines,
							non-disclosure requirements, and ethical standards.
						</li>
					</ul>
				</ol>
				<li>Transmission Control</li>
			</ol>
			<ol>
				<ol>
					<li>
						In-transit: G7 Express makes HTTPS encryption (also referred to as
						SSL or TLS) available on every one of its login interfaces and for
						free on every customer site hosted on the G7 Express products. G7
						Express’s HTTPS implementation uses industry standard algorithms and
						certificates.
					</li>
					<li>
						At-rest: G7 Express stores user passwords following policies that
						follow industry standard practices for security.
					</li>
				</ol>
				<li>Input Control</li>
				<ol>
					<li>
						Detection: G7 Express designed its infrastructure to log extensive
						information about the system behavior, traffic received, system
						authentication, and other application requests. Internal systems
						aggregated log data and alert appropriate employees of malicious,
						unintended, or anomalous activities. G7 Express personnel, including
						security, operations, and support personnel, are responsive to known
						incidents.
					</li>
					<li>
						Response and tracking: G7 Express maintains a record of known
						security incidents that includes description, dates and times of
						relevant activities, and incident disposition. Suspected and
						confirmed security incidents are investigated by security,
						operations, or support personnel; and appropriate resolution steps
						are identified and documented. For any confirmed incidents, G7
						Express will take appropriate steps to minimize product and Customer
						damage or unauthorized disclosure.
					</li>
					<li>
						Communication: If G7 Express becomes aware of unlawful access to
						Customer data stored within its products, G7 Express will: 1) notify
						the affected Customers of the incident; 2) provide a description of
						the steps G7 Express is taking to resolve the incident; and 3)
						provide status updates to the Customer contact, as G7 Express deems
						necessary. Notification(s) of incidents, if any, will be delivered
						to one or more of the Customer’s contacts in a form G7 Express
						selects, which may include via email or telephone.
					</li>
				</ol>
				<li>Availability Control</li>
				<ol>
					<li>
						Fault tolerance: Backup and replication strategies are designed to
						ensure redundancy and fail-over protections during a significant
						processing failure. Customer data is backed up to multiple durable
						data stores and replicated across multiple availability zones.
					</li>
					<li>
						Online replicas and backups: Where feasible, production databases
						are designed to replicate data between no less than 1 primary and 1
						secondary database. All databases are backed up and maintained using
						at least industry standard methods.
					</li>
				</ol>
			</ol>
			<br />
			<P>EXHIBIT 2</P>
			<P>List of Sub-Processors</P>
			<ul>
				<li>United States Postal Service</li>
				<li>UPS Market Driver, Inc.</li>
				<li>PSI Systems, Inc. dba Endicia</li>
				<li>Stamps.com Inc.</li>
				<li>Pitney Bowes Inc.</li>
				<li>Simpler Postage Inc.</li>
				<li>Google LLC</li>
				<li>Facebook, Inc.</li>
				<li>FullStory, Inc.</li>
				<li>Intercom, Inc.</li>
				<li>LinkedIn Corporation</li>
				<li>Microsoft Corporation</li>
				<li>Aircall.io, Inc.</li>
				<li>Spreedly, Inc.</li>
				<li>Stripe, Inc.</li>
				<li>PayPal Inc.</li>
				<li>Elavon, Inc.</li>
				<li>American Express Travel Related Services Company, Inc.</li>
				<li>New Relic, Inc.</li>
				<li>Wildbit, LLC</li>
				<li>Hotjar Limited</li>
				<li>Any G7 Express subsidiaries and affiliates</li>
			</ul>
		</main>
	);
}
