import React from 'react';

export default function Steps(props) {
	const { steps, current, onStepEdit } = props;

	return (
		<nav aria-label='Progress'>
			<ol className='space-y-4 md:flex md:space-y-0 md:space-x-8'>
				{steps.map((step, idx) => (
					<li key={step.name} className='md:flex-1'>
						{step.isCompleted ? (
							<a
								href={step.href}
								onClick={(e) => {
									e.preventDefault();
									onStepEdit(idx);
								}}
								className='group pl-4 py-2 flex flex-col border-l-4 border-yellow-500 hover:border-yellow-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4'
							>
								<span className='text-xs text-yellow-500 font-semibold tracking-wide uppercase group-hover:text-yellow-600'>
									{step.id}
								</span>
								<span className='text-sm font-medium'>{step.name}</span>
							</a>
						) : current === idx ? (
							<a
								href={step.href}
								onClick={(e) => {
									e.preventDefault();
								}}
								className='pl-4 py-2 flex flex-col border-l-4 border-yellow-500 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4'
								aria-current='step'
							>
								<span className='text-xs text-yellow-500 font-semibold tracking-wide uppercase'>
									{step.id}
								</span>
								<span className='text-sm font-medium'>{step.name}</span>
							</a>
						) : (
							<a
								href={step.href}
								onClick={(e) => {
									e.preventDefault();
								}}
								className='group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4'
							>
								<span className='text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700'>
									{step.id}
								</span>
								<span className='text-sm font-medium'>{step.name}</span>
							</a>
						)}
					</li>
				))}
			</ol>
		</nav>
	);
}
