import { TYPES } from '../actions/types';

const INITIAL_STATE = {
	serviceRates: null,
	shipper: {
		streetLine1: '',
		streetLine2: '',
		city: '',
		postalCode: '',
		stateOrProvinceCode: '',
		countryCode: '',
	},
	recipient: {
		streetLine1: '',
		streetLine2: '',
		city: '',
		postalCode: '',
		stateOrProvinceCode: '',
		countryCode: '',
	},
	formValues: {
		length: '',
		width: '',
		height: '',
		weight: '',
		packagingType: '',
		shipDate: '',
	},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TYPES.GET_QUOTES:
			return {
				...state,
				serviceRates: action.payload.service_rates,
				formValues: { ...state.formValues, ...action.payload.values },
			};
		case TYPES.SHIPPER:
			return {
				...state,
				shipper: { ...state.shipper, ...action.payload },
			};
		case TYPES.RECIPIENT:
			return {
				...state,
				recipient: { ...state.recipient, ...action.payload },
			};
		case TYPES.RESET_PRICE:
			return {
				...state,
				serviceRates: null,
				shipper: { shipperCountryCode: '', shipperPostalCode: '' },
				recipient: {
					recipientCountryCode: '',
					recipientCity: '',
					recipientPostalCode: '',
				},
			};
		case TYPES.CLEAN_UP_SERVICE_RATES:
			return {
				state: INITIAL_STATE,
			};
		case TYPES.UPDATE_SHIPPER:
			return {
				...state,
				shipper: {
					...state.shipper,
					[action.payload.name]: action.payload.value,
				},
			};
		case TYPES.UPDATE_RECIPIENT:
			return {
				...state,
				recipient: {
					...state.recipient,
					[action.payload.name]: action.payload.value,
				},
			};
		default:
			return state;
	}
};
