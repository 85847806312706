import React from 'react';
import moment from 'moment';
import { DownloadIcon } from '@heroicons/react/outline';

import { connect } from 'react-redux';
import { getTransactionByClientSecret } from '../../store/actions';

const products = [
	{
		id: 1,
		name: 'Nomad Tumbler',
		description:
			'This durable and portable insulated tumbler will keep your beverage at the perfect temperature during your next adventure.',
		href: '#',
		price: '35.00',
		status: 'Preparing to ship',
		step: 1,
		date: 'March 24, 2021',
		datetime: '2021-03-24',
		address: ['Floyd Miles', '7363 Cynthia Pass', 'Toronto, ON N3Y 4H8'],
		email: 'f•••@example.com',
		phone: '1•••••••••40',
		imageSrc:
			'https://tailwindui.com/img/ecommerce-images/confirmation-page-03-product-01.jpg',
		imageAlt: 'Insulated bottle with white base and black snap lid.',
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

class Confirmation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			path: '',
			errorMessage: '',
			isPending: false,
		};
	}

	getOrderDate = () => {
		const current = new Date();
		return moment(current).format('LL');
	};

	onSubmitCallback = (errorMessage) => {
		this.setState((prevState) => ({
			...prevState,
			isPending: false,
		}));

		if (errorMessage) {
			this.setState((prevState) => ({
				...prevState,
				errorMessage,
			}));
		}
	};

	componentDidMount() {
		this.setState((prevState) => ({
			...prevState,
			isPending: true,
		}));

		this.props.getTransactionByClientSecret(
			this.props.clientSecret,
			this.onSubmitCallback
		);
	}

	render() {
		const { transaction } = this.props.data;

		return (
			<main className='mt-8'>
				<div className='px-4 space-y-2 sm:px-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0'>
					<div className='flex sm:items-baseline sm:space-x-4'>
						<h1 className='text-lg font-medium tracking-tight text-gray-900 sm:text-lg'>
							Your payment has been confirmed:{' '}
						</h1>
						<a
							href='/'
							className='hidden text-sm font-medium text-yellow-500 hover:text-yellow-400 sm:block'
						>
							View invoice<span aria-hidden='true'> &rarr;</span>
						</a>
					</div>
					<p className='text-sm text-gray-600'>
						Order placed{' '}
						<span className='font-medium text-gray-900'>
							{this.getOrderDate()}
						</span>
					</p>
					<a
						href='/'
						className='text-sm font-medium text-yellow-500 hover:text-yellow-400 sm:hidden'
					>
						View invoice<span aria-hidden='true'> &rarr;</span>
					</a>
				</div>

				{transaction && (
					<>
						<section aria-labelledby='products-heading' className='mt-6'>
							<h2 id='products-heading' className='sr-only'>
								Products purchased
							</h2>

							<div className='space-y-8'>
								{products.map((product) => (
									<div
										key={product.id}
										className='bg-white border-t border-b border-gray-200 shadow-sm sm:border sm:rounded-lg'
									>
										<div className='py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8'>
											<div className='sm:flex lg:col-span-7'>
												<a href={'/'} className='flex'>
													<span className='mr-2 font-medium'>
														Download your label:
													</span>
													<DownloadIcon
														className={'w-6 h-6 text-yellow-500'}
														aria-hidden='true'
													/>
												</a>
											</div>
										</div>

										<div className='border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8'>
											<h4 className='sr-only'>Status</h4>
											<p className='text-sm font-medium text-gray-900'>
												{product.status} on <span>{this.getOrderDate()}</span>
											</p>
											<div className='mt-6' aria-hidden='true'>
												<div className='bg-gray-200 rounded-full overflow-hidden'>
													<div
														className='h-2 bg-yellow-500 rounded-full'
														style={{
															width: `calc((${product.step} * 2 + 1) / 8 * 100%)`,
														}}
													/>
												</div>
												<div className='hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6'>
													<div className='text-yellow-500'>Order placed</div>
													<div
														className={classNames(
															product.step > 0 ? 'text-yellow-500' : '',
															'text-center'
														)}
													>
														Labeling
													</div>
													<div
														className={classNames(
															product.step > 1 ? 'text-yellow-500' : '',
															'text-center'
														)}
													>
														Shipped
													</div>
													<div
														className={classNames(
															product.step > 2 ? 'text-yellow-500' : '',
															'text-right'
														)}
													>
														Delivered
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</section>

						<section aria-labelledby='summary-heading' className='mt-16'>
							<h2 id='summary-heading' className='sr-only'>
								Billing Summary
							</h2>

							<div className='bg-gray-100 py-6 px-4 sm:px-6 sm:rounded-lg lg:px-8 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8'>
								<dl className='grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7'>
									<div>
										<dt className='font-medium text-gray-900'>
											Billing address
										</dt>
										<dd className='mt-3 text-gray-500'>
											<span className='block'>
												{transaction.paymentIntent.metadata.shipperPersonName}
											</span>
											<span className='block'>
												{transaction.paymentIntent.metadata.shipperStreetLine1}
											</span>
											<span className='block'>
												{transaction.paymentIntent.metadata.shipperCity},{' '}
												{transaction.paymentIntent.metadata.shipperPostalCode}
											</span>
										</dd>
									</div>
									<div>
										<dt className='font-medium text-gray-900'>
											Payment information
										</dt>
										<div className='mt-3'>
											<dd className='-ml-4 -mt-4 flex flex-wrap'>
												<div className='ml-4 mt-4 flex-shrink-0'>
													<svg
														aria-hidden='true'
														width={36}
														height={24}
														viewBox='0 0 36 24'
														xmlns='http://www.w3.org/2000/svg'
														className='h-6 w-auto'
													>
														<rect
															width={36}
															height={24}
															rx={4}
															fill='#224DBA'
														/>
														<path
															d='M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z'
															fill='#fff'
														/>
													</svg>
													<p className='sr-only'>Visa</p>
												</div>
												<div className='ml-4 mt-4'>
													<p className='text-gray-900'>Ending with 4242</p>
													<p className='text-gray-600'>Expires 02 / 24</p>
												</div>
											</dd>
										</div>
									</div>
								</dl>

								<dl className='mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:col-span-5'>
									<div className='pb-4 flex items-center justify-between'>
										<dt className='text-gray-600'>Subtotal</dt>
										<dd className='font-medium text-gray-900'>
											{transaction.paymentIntent.metadata.price}
										</dd>
									</div>
									<div className='py-4 flex items-center justify-between'>
										<dt className='text-gray-600'>Surcharge</dt>
										<dd className='font-medium text-gray-900'>Included</dd>
									</div>
									<div className='pt-4 flex items-center justify-between'>
										<dt className='font-medium text-gray-900'>Order total</dt>
										<dd className='font-medium text-yellow-500'>
											{transaction.paymentIntent.metadata.price}
										</dd>
									</div>
								</dl>
							</div>
						</section>
					</>
				)}
			</main>
		);
	}
}

const mapStateToProps = (state) => ({
	data: state.transaction.data,
});

export default connect(mapStateToProps, { getTransactionByClientSecret })(
	Confirmation
);
