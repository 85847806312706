export const TYPES = {
	AUTH_USER: 'auth-user',
	GET_QUOTES: 'get-quotes',
	RESET_PRICE: 'reset-price',
	UPDATE_SHIPPER: 'update_shipper',
	UPDATE_RECIPIENT: 'update_recipient',
	CLEAN_UP_SERVICE_RATES: 'clean-up-service-rates',
	SHIPPER: 'shipper',
	RECIPIENT: 'recipient',
	INITIATE_SHIPMENT_DETAILS_VALUES: 'initiate-shipment-values',
	FEDEX_CREATE_SHIPMENT: 'fedex-create-shipment',
	USPS_CREATE_SHIPMENT: 'usps-create-shipment',
	GET_TRANSACTION_BY_CLIENT_SECRET: 'get-transaction-by-client-secret',
	GET_TRANSACTIONS_BY_UID: 'get-transactions-by-uid',
};
