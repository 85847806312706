import { auth } from '../../firebase/config';
import axios from 'axios';
import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	sendPasswordResetEmail,
	sendEmailVerification,
} from 'firebase/auth';
import { signOut as firebaseSignOut } from 'firebase/auth';

import { API_ENDPOINTS } from '../api';
import { TYPES } from './types';

export const signIn =
	({ email, password }, callback) =>
	async (dispatch) => {
		try {
			const userAuth = await signInWithEmailAndPassword(auth, email, password);
			let errorMessage = '';

			if (userAuth.user.emailVerified) {
				dispatch({ type: TYPES.AUTH_USER, payload: userAuth.user });
				callback(errorMessage);
			} else {
				errorMessage =
					'You have NOT verified your email yet. Please check your email for verification.';
				callback(errorMessage);
			}
		} catch (err) {
			callback(err.message);
		}
	};

export const signUp =
	({ displayName, email, password }, callback) =>
	async (dispatch) => {
		try {
			const res = await createUserWithEmailAndPassword(auth, email, password);

			if (!res) {
				throw new Error('could not complete signup');
			}

			await updateProfile(auth.currentUser, {
				displayName,
			});

			await sendEmailVerification(auth.currentUser, {
				url: `${window.location.href.split('/signup')[0]}/signin`,
			});

			const errorMessage = '';

			callback(errorMessage, auth.currentUser.email);
		} catch (err) {
			callback(err.message);
		}
	};

export const signOut = () => async (dispatch) => {
	try {
		await firebaseSignOut(auth);

		dispatch({ type: TYPES.AUTH_USER, payload: null });
	} catch (err) {
		console.log(err.message);
	}
};

export const resetPassword =
	({ email }, callback) =>
	async (dispatch) => {
		try {
			await sendPasswordResetEmail(auth, email);

			const errorMessage = '';
			callback(errorMessage, email);
		} catch (err) {
			callback(err.message);
		}
	};

export const getQuotes = (reqBody, callback, values) => (dispatch) => {
	auth.currentUser
		.getIdToken()
		.then((token) => {
			axios
				.create({ headers: { Authorization: `Bearer ${token}` } })
				.post(API_ENDPOINTS.wceGetQuote, reqBody)
				.then((res) => {
					console.log(res.data);
					dispatch({
						type: TYPES.GET_QUOTES,
						payload: { service_rates: res.data.service_rates, values: values },
					});
					callback();
				})
				.catch((err) => {
					callback(err.message);
				});
		})
		.catch((err) => console.log(err));
};

export const getShipper = (formData) => (dispatch) => {
	dispatch({ type: TYPES.SHIPPER, payload: formData });
};

export const getRecipient = (formData) => (dispatch) => {
	dispatch({ type: TYPES.RECIPIENT, payload: formData });
};

export const cleanUpServiceRates = () => (dispatch) => {
	dispatch({ type: TYPES.CLEAN_UP_SERVICE_RATES });
};

export const initiateShipmentDetailsValues =
	(formValues, service) => (dispatch) => {
		dispatch({
			type: TYPES.INITIATE_SHIPMENT_DETAILS_VALUES,
			payload: { formValues, service },
		});
	};

export const fedexCreateShipment =
	(reqBody, callback, values) => (dispatch) => {
		auth.currentUser
			.getIdToken()
			.then((token) => {
				axios
					.create({ headers: { Authorization: `Bearer ${token}` } })
					.post(API_ENDPOINTS.fedexCreateShipment, reqBody)
					.then((res) => {
						console.log(res.data);
						dispatch({
							type: TYPES.FEDEX_CREATE_SHIPMENT,
							payload: res.data.data,
						});
						callback();
					})
					.catch((err) => {
						callback(err.message);
					});
			})
			.catch((err) => console.log(err));
	};

export const uspsCreateShipment = (reqBody, callback, values) => (dispatch) => {
	auth.currentUser
		.getIdToken()
		.then((token) => {
			axios
				.create({ headers: { Authorization: `Bearer ${token}` } })
				.post(API_ENDPOINTS.uspsCreateShipment, reqBody)
				.then((res) => {
					console.log(res.data);
					dispatch({
						type: TYPES.USPS_CREATE_SHIPMENT,
						payload: res.data.data,
					});
					callback();
				})
				.catch((err) => {
					callback(err.message);
				});
		})
		.catch((err) => console.log(err));
};

export const getTransactionByClientSecret =
	(secret, callback) => (dispatch) => {
		auth.currentUser
			.getIdToken()
			.then((token) => {
				axios
					.create({ headers: { Authorization: `Bearer ${token}` } })
					.get(API_ENDPOINTS.getTransactionByClientSecret + secret)
					.then((res) => {
						dispatch({
							type: TYPES.GET_TRANSACTION_BY_CLIENT_SECRET,
							payload: res.data,
						});
						callback();
					})
					.catch((err) => {
						callback(err.message);
					});
			})
			.catch((err) => console.log(err));
	};

export const getTransactionsByUid = (reqBody, callback) => (dispatch) => {
	auth.currentUser
		.getIdToken()
		.then((token) => {
			axios
				.create({ headers: { Authorization: `Bearer ${token}` } })
				.get(API_ENDPOINTS.getTransactions, reqBody)
				.then((res) => {
					dispatch({
						type: TYPES.GET_TRANSACTIONS_BY_UID,
						payload: res.data,
					});
					callback();
				})
				.catch((err) => {
					callback(err.message);
				});
		})
		.catch((err) => console.log(err));
};
