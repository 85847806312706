import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Form as ReduxForm } from 'react-final-form';
import { connect } from 'react-redux';

import { Form, ButtonSet, Button, Divider } from '../components';
import SubmissionError from '../SubmissionError';

const FormSubmit = (props) => {
	const [state, setState] = useState({
		path: '',
		errorMessage: '',
		isPending: false,
	});

	const stripe = useStripe();
	const elements = useElements();

	const { component: Fields, buttonLabel } = props;

	const onSubmitCallback = (errorMessage, state) => {
		setState((prevState) => ({
			...prevState,
			isPending: false,
		}));

		if (errorMessage) {
			setState((prevState) => ({
				...prevState,
				errorMessage,
			}));
		} else {
			if (props.setRedirectPath) {
				props.setRedirectPath(props.redirectPath || state);
			}
		}
	};

	const closeSubmissionError = () => {
		setState((prevState) => ({
			...prevState,
			errorMessage: '',
		}));
	};

	const onSubmit = async (values) => {
		const reqBody = { ...props.getReqBody(values) };

		setState((prevState) => ({
			...prevState,
			isPending: true,
		}));

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: 'http://localhost:3000/create-shipment',
			},
			// redirect: 'if_required',
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error) {
			if (error.type === 'card_error' || error.type === 'validation_error') {
				setState((prevState) => ({
					...prevState,
					errorMessage: error.message,
				}));
			} else {
				setState((prevState) => ({
					...prevState,
					errorMessage: 'An unexpected error occured.',
				}));
			}
			setState((prevState) => ({
				...prevState,
				isPending: false,
			}));
		} else {
			// props.dispatch(props.submitAction(reqBody, onSubmitCallback, values));
			onSubmitCallback();
		}
	};

	return (
		<ReduxForm
			onSubmit={(values) => {
				onSubmit(values);
			}}
			initialValues={props.initialValues}
			render={({ handleSubmit, submitting, values }) => (
				<Form onSubmit={handleSubmit}>
					{state.errorMessage && (
						<SubmissionError
							close={closeSubmissionError}
							errorMessage={state.errorMessage}
						/>
					)}

					<Divider>{<Fields values={values} />}</Divider>

					<ButtonSet>
						{state.isPending ? (
							<button
								type='button'
								disabled
								className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400'
							>
								<svg
									className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
								>
									<circle
										className='opacity-25'
										cx='12'
										cy='12'
										r='10'
										stroke='currentColor'
										strokeWidth='4'
									></circle>
									<path
										className='opacity-75'
										fill='currentColor'
										d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
									></path>
								</svg>
								Processing...
							</button>
						) : (
							<Button type='submit' label={buttonLabel} disabled={submitting} />
						)}
					</ButtonSet>
				</Form>
			)}
		/>
	);
};

export default connect()(FormSubmit);
