export const Form = ({ children, ...props }) => {
	return (
		<form {...props} className='space-y-8 divide-y divide-gray-200'>
			{children}
		</form>
	);
};

export const Divider = ({ children }) => {
	return <div className='space-y-8 divide-y divide-gray-200'>{children}</div>;
};

export const ButtonSet = ({ children }) => {
	return (
		<div className='mt-5 pt-5 border-t border-gray-200'>
			<div className='flex justify-end'>{children}</div>
		</div>
	);
};

export const Button = ({ label, ...props }) => {
	return (
		<button
			{...props}
			className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400'
		>
			{label}
		</button>
	);
};

export const Checkbox = ({
	input,
	meta,
	size,
	required,
	disabled,
	children,
}) => {
	const renderError = ({ error, touched }) => {
		if (touched && error) {
			return <div className='text-sm text-red-700'>{error}</div>;
		}
	};

	return (
		<div className={size}>
			<div className='relative flex items-start'>
				<div className='flex items-center h-5'>
					<input
						{...input}
						required={required}
						disabled={disabled}
						type='checkbox'
						className='focus:ring-yellow-400 h-4 w-4 text-yellow-500 border-gray-300 rounded'
					/>
				</div>
				<div className='ml-3 text-sm'>{children}</div>
			</div>
			{renderError(meta)}
		</div>
	);
};

export const Select = ({ input, meta, label, size, required, children }) => {
	const renderError = ({ error, touched }) => {
		if (touched && error) {
			return <div className='text-sm text-red-700 mt-1'>{error}</div>;
		}
	};

	return (
		<div className={size}>
			<label
				htmlFor={input.name}
				className='block text-sm font-medium text-gray-700'
			>
				{label}
			</label>
			<select
				{...input}
				required={required}
				className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-400 focus:border-yellow-400 sm:text-sm'
			>
				{children}
			</select>
			{renderError(meta)}
		</div>
	);
};

export const Input = ({
	input,
	meta,
	label,
	type,
	size,
	placeholder,
	autoComplete,
	required,
	disabled,
}) => {
	const renderError = ({ error, touched }) => {
		if (touched && error) {
			return <div className='text-sm text-red-700 mt-1'>{error}</div>;
		}
	};

	return (
		<div className={size}>
			<label
				htmlFor={input.name}
				className='block text-sm font-medium text-gray-700'
			>
				{label ? label : <span>&nbsp;</span>}
			</label>
			<input
				{...input}
				type={type}
				autoComplete={autoComplete}
				required={required}
				disabled={disabled}
				placeholder={placeholder}
				className='mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
			/>
			{renderError(meta)}
		</div>
	);
};

export const TitleSet = ({ children }) => {
	return (
		<div className='md:col-span-1'>
			<div className='px-0'>{children}</div>
		</div>
	);
};

export const FieldSet = ({ children }) => {
	return (
		<div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
			<div className='md:grid md:grid-cols-3 md:gap-6'>{children}</div>
		</div>
	);
};

export const Title = ({ children }) => {
	return (
		<h3 className='text-lg font-medium leading-6 text-gray-900'>{children}</h3>
	);
};

export const Subtitle = ({ children }) => {
	return <p className='mt-1 text-sm text-gray-600'>{children}</p>;
};

export const Card = ({ children }) => {
	return (
		<div className='mt-5 md:mt-0 md:col-span-2'>
			<div className='border border-gray-200 overflow-hidden sm:rounded-md'>
				<div className='px-4 py-5 bg-white sm:p-6'>
					<div className='grid grid-cols-6 gap-6'>{children}</div>
				</div>
			</div>
		</div>
	);
};

export const FORM_GRID = [
	'col-span-6 lg:col-span-6',
	'col-span-6 lg:col-span-1',
	'col-span-6 lg:col-span-2',
	'col-span-6 lg:col-span-3',
	'col-span-6 lg:col-span-4',
	'col-span-6 lg:col-span-5',
];
