import React, { useState } from 'react';
import PageTitle from '../components/PageTitle';
import General from '../components/your-account/General';
import OrderHistory from '../components/your-account/OrderHistory';

const tabs = [
	{ name: 'General', href: '#', current: true },
	{ name: 'Order History', href: '#', current: false },
	{ name: 'Shipment Addresses', href: '#', current: false },
];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const YourAccount = () => {
	const [state, setState] = useState({
		tabs: [
			{ name: 'General' },
			{ name: 'Order History' },
			{ name: 'Shipment Addresses' },
		],
		current: 0,
	});

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			<PageTitle title='Your Account' />
			<div className='pt-6 sm:pt-0'>
				{/* Tabs */}
				<div className='lg:hidden'>
					<label htmlFor='selected-tab' className='sr-only'>
						Select a tab
					</label>
					<select
						id='selected-tab'
						name='selected-tab'
						className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md'
						defaultValue={tabs[state.current].name}
					>
						{tabs.map((tab) => (
							<option key={tab.name}>{tab.name}</option>
						))}
					</select>
				</div>
				<div className='hidden lg:block'>
					<div className='border-b border-gray-200'>
						<nav className='-mb-px flex space-x-8'>
							{tabs.map((tab, index) => (
								<a
									key={tab.name}
									href={tab.href}
									onClick={(e) => {
										e.preventDefault();
										setState((prevState) => ({
											...prevState,
											current: index,
										}));
									}}
									className={classNames(
										index === state.current
											? 'border-yellow-500 text-yellow-600'
											: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
										'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
									)}
								>
									{tab.name}
								</a>
							))}
						</nav>
					</div>
				</div>
			</div>

			{state.current === 0 && <General />}
			{state.current === 1 && <OrderHistory />}
		</main>
	);
};

export default YourAccount;
