import { TYPES } from '../actions/types';

const INITIAL_STATE = {
	initialValues: null,
	labelInfo: null,
	selectedService: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TYPES.INITIATE_SHIPMENT_DETAILS_VALUES:
			return {
				...state,
				initialValues: action.payload.formValues,
				selectedService: action.payload.service,
			};
		case TYPES.FEDEX_CREATE_SHIPMENT:
			return {
				...state,
				labelInfo: action.payload,
			};
		case TYPES.USPS_CREATE_SHIPMENT:
			return {
				...state,
				labelInfo: action.payload,
			};
		default:
			return state;
	}
};
