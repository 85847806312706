import React, { useState } from 'react';
import PageTitle from '../components/PageTitle';
import SignUpForm from '../lib/form/sign-up-form';
import RouterLink from '../components/RouterLink';

const initialState = '';

export const AccountCreated = ({ email }) => {
	return (
		<div>
			<h3 className='text-lg leading-6 font-medium text-gray-900'>
				Your new account has been created successfully
			</h3>
			<p className='my-5 text-sm'>
				You should receive your email confirmation soon at{' '}
				<span className='font-medium'>{email}</span>. Please verify the email to
				activate your account.
			</p>
			<h4 className='text-sm leading-6 font-medium text-gray-900'>
				Can't find the email?
			</h4>
			<p className='my-5 text-sm'>
				Check that the email address above is correct. You might also check your
				spam folder.
			</p>
			<h4 className='text-sm leading-6 font-medium text-gray-900'>
				Already verified your email?
			</h4>
			<p className='my-5 text-sm'>
				Please <RouterLink path={'/signin'}>sign in</RouterLink>.
			</p>
		</div>
	);
};

export default function SignUp() {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			{redirectPath ? (
				<AccountCreated email={redirectPath} />
			) : (
				<>
					<PageTitle title='Create a Free Account' />
					<SignUpForm setRedirectPath={setRedirectPath} />
				</>
			)}
		</main>
	);
}
