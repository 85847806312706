import React, { useState } from 'react';
import { connect } from 'react-redux';
import { usePlacesWidget } from 'react-google-autocomplete';
import { getShipper } from '../../../store/actions';
import { getAddressObj } from './getAddressObj';

const ShipperAutoComplete = (props) => {
	const [formattedAddress, setFormattedAddress] = useState('');
	const { getShipper } = props;

	const { ref } = usePlacesWidget({
		apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
		onPlaceSelected: (place) => {
			console.log(place);
			if (place.formatted_address) {
				setFormattedAddress(place.formatted_address);
			}
			getShipper(getAddressObj(place));
		},
		options: {
			types: ['geocode'],
			fields: ['ALL'],
			componentRestrictions: { country: ['us', 'ca', 'mx'] },
		},
	});

	return (
		<div className='col-span-6'>
			<label
				htmlFor='shipperAddress'
				className='block text-sm font-medium text-gray-700'
			>
				Search for shipper's address
			</label>
			<input
				ref={ref}
				name='shipperAddress'
				type='text'
				className='mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
			/>
			{formattedAddress && (
				<div className='mt-5 text-sm font-medium text-gray-600'>
					Formatted address: {formattedAddress}
				</div>
			)}
		</div>
	);
};

export default connect(null, { getShipper })(ShipperAutoComplete);
