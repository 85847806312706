import { Field } from 'react-final-form';
import {
	FieldSet,
	Subtitle,
	TitleSet,
	Card,
	Input,
	FORM_GRID,
} from '../components';

export default function Fields() {
	return (
		<FieldSet>
			<TitleSet>
				<Subtitle>
					Enter your email, and we'll email to you for setting new password.
				</Subtitle>
			</TitleSet>
			<Card>
				<Field name='email'>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Email'
							type='email'
							size={FORM_GRID[4]}
							placeholder=''
							autoComplete='off'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
			</Card>
		</FieldSet>
	);
}
