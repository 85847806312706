import React from 'react';
import { auth } from './firebase/config';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { connect } from 'react-redux';

import Router from './router';
import { TYPES } from './store/actions/types';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = { authChecked: false };
	}

	componentDidMount() {
		onAuthStateChanged(auth, async (_user) => {
			console.log('User state changed. Current user is: ', _user);
			if (_user && _user.emailVerified) {
				this.props.dispatch({ type: TYPES.AUTH_USER, payload: _user });
			}
			this.setState({ authChecked: true });
		});
	}

	render() {
		return this.state.authChecked && <Router />;
	}
}

export default connect()(App);
