import React, { useState } from 'react';
import PageTitle from '../components/PageTitle';
import ResetPasswordForm from '../lib/form/reset-password-form';
import RouterLink from '../components/RouterLink';

const initialState = '';

const ResetPassword = () => {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			{redirectPath ? (
				<div>
					<h3 className='text-lg leading-6 font-medium text-gray-900'>
						Password reset request successful
					</h3>
					<p className='my-5 text-sm'>
						You should receive your reset link soon at{' '}
						<span className='font-medium'>{redirectPath}</span> or by text if
						you opted in for text notifications.
					</p>
					<h4 className='text-sm leading-6 font-medium text-gray-900'>
						Can't find the email?
					</h4>
					<p className='my-5 text-sm'>
						If the email address you entered does not match an existing G7
						Express's account, you will not receive an email. Check that the
						email address above is correct.
						<br />
						You should also check your spam folder.
					</p>
					<h4 className='text-sm leading-6 font-medium text-gray-900'>
						Already changed your password?
					</h4>
					<p className='my-5 text-sm'>
						Please{' '}
						<RouterLink
							path={'/signin'}
							className='font-medium text-indigo-600 hover:text-indigo-500'
						>
							sign in
						</RouterLink>
						.
					</p>
				</div>
			) : (
				<>
					<PageTitle title='Reset Password' />
					<ResetPasswordForm setRedirectPath={setRedirectPath} />
				</>
			)}
		</main>
	);
};

export default ResetPassword;
