import React from 'react';
import { Field } from 'react-final-form';
import {
	FieldSet,
	Subtitle,
	TitleSet,
	Title,
	Card,
	Input,
	Select,
	Checkbox,
	FORM_GRID,
} from '../components';
import {
	composeValidators,
	mustBeNumber,
	greaterThan,
	maxValue,
	minValue,
} from '../validators';
import { parseFigures } from '../formats';
import { UsStateList } from '../../../data';

const Fields = ({ values }) => {
	return (
		<>
			<FieldSet>
				<TitleSet>
					<Title>Shipper's address</Title>
					<Subtitle>
						Enter the home, office, or warehouse address where you're shipping
						from
					</Subtitle>
				</TitleSet>
				<Card>
					<Field name='shipperCountryCode'>
						{({ input, meta }) => (
							<Select
								input={input}
								meta={meta}
								label='Country *'
								size={FORM_GRID[4]}
							>
								<option value=''>Select a country</option>
								<option value='US'>United States</option>
								<option value='CA'>Canada</option>
								<option value='MX'>Mexico</option>
							</Select>
						)}
					</Field>
					<Field name='shipperPersonName'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Contact name *'
								type='text'
								size={FORM_GRID[3]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='shipperCompanyName'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Company (optional)'
								type='text'
								size={FORM_GRID[3]}
								placeholder=''
								autoComplete='on'
								required={false}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='shipperStreetLine1'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Address *'
								type='text'
								size={FORM_GRID[4]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='shipperStreetLine2'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='App/Unit/Suite (optional)'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={false}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='shipperCity'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='City/District *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					{values.shipperCountryCode === 'US' ? (
						<Field name='shipperStateOrProvinceCode'>
							{({ input, meta }) => (
								<Select
									input={input}
									meta={meta}
									label='State/Province *'
									size={FORM_GRID[2]}
								>
									<option value={''}>Select a state</option>
									<UsStateList />
								</Select>
							)}
						</Field>
					) : (
						<Field name='shipperStateOrProvinceCode'>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='State/Province *'
									type='text'
									size={FORM_GRID[2]}
									placeholder=''
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
					)}
					<Field name='shipperPostalCode'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Zip/Postal Code *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='shipperPhoneNumber'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Phone no. *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='shipperPhoneExtension'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Ext'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={false}
								disabled={false}
							></Input>
						)}
					</Field>
				</Card>
			</FieldSet>
			<FieldSet>
				<TitleSet>
					<Title>Recipient's address</Title>
					<Subtitle>
						Enter the home, office, or warehouse address where you're shipping
						to
					</Subtitle>
				</TitleSet>
				<Card>
					<Field name='recipientCountryCode'>
						{({ input, meta }) => (
							<Select
								input={input}
								meta={meta}
								label='Country *'
								size={FORM_GRID[4]}
							>
								<option value=''>Select a country</option>
								<option value='US'>United States</option>
								<option value='CA'>Canada</option>
								<option value='MX'>Mexico</option>
								<option value='VN'>Vietnam</option>
							</Select>
						)}
					</Field>
					<Field name='recipientPersonName'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Contact name *'
								type='text'
								size={FORM_GRID[3]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='recipientCompanyName'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Company (optional)'
								type='text'
								size={FORM_GRID[3]}
								placeholder=''
								autoComplete='on'
								required={false}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='recipientStreetLine1'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Address *'
								type='text'
								size={FORM_GRID[4]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='recipientStreetLine2'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='App/Unit/Suite (optional)'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={false}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='recipientCity'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='City/District *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					{values.recipientCountryCode === 'US' ? (
						<Field name='recipientStateOrProvinceCode'>
							{({ input, meta }) => (
								<Select
									input={input}
									meta={meta}
									label='State/Province *'
									size={FORM_GRID[2]}
								>
									<option value={''}>Select a state</option>
									<UsStateList />
								</Select>
							)}
						</Field>
					) : (
						<Field name='recipientStateOrProvinceCode'>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='State/Province *'
									type='text'
									size={FORM_GRID[2]}
									placeholder=''
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
					)}
					<Field name='recipientPostalCode'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Zip/Postal Code *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='recipientPhoneNumber'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Phone no. *'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='recipientPhoneExtension'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Ext'
								type='text'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={false}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field name='residential' type='checkbox'>
						{({ input, meta }) => (
							<Checkbox
								input={input}
								meta={meta}
								size={FORM_GRID[3]}
								required={false}
								disabled={false}
							>
								I'm shipping to a resident
							</Checkbox>
						)}
					</Field>
				</Card>
			</FieldSet>
			<FieldSet>
				<TitleSet>
					<Title>Packaging Types & Services</Title>
					<Subtitle>
						Fedex Envelope dimensions: 9-1/2" x 12-1/2" or 9-1/2" x 15-1/2"
						(reusable). Maximum volume 250 cubic inches
					</Subtitle>
				</TitleSet>
				<Card>
					<Field name='packagingType'>
						{({ input, meta }) => (
							<Select
								input={input}
								meta={meta}
								label='Packaging type *'
								size={FORM_GRID[3]}
								required={true}
							>
								<option value=''>Select a type</option>
								<option value='YOUR_PACKAGING'>Your Packaging</option>
								<option value='FEDEX_ENVELOPE'>Courier's Envelope</option>
							</Select>
						)}
					</Field>
					<Field name='serviceType' defaultValue={''}>
						{({ input, meta }) => (
							<Select
								input={input}
								meta={meta}
								label='Service type *'
								size={FORM_GRID[3]}
							>
								<option value=''>All Services</option>
								<option value='STANDARD_OVERNIGHT'>
									FedEx Standard Overnight®
								</option>
								<option value='PRIORITY_OVERNIGHT'>
									FedEx Priority Overnight®
								</option>
								<option value='FEDEX_GROUND'>FedEx Ground®</option>
								<option value='GROUND_HOME_DELIVERY'>
									FedEx Home Delivery®
								</option>
								<option value='INTERNATIONAL_ECONOMY'>
									FedEx International Economy®
								</option>
								<option value='FEDEX_INTERNATIONAL_PRIORITY'>
									FedEx International Priority®
								</option>
								<option value='683'>USPS First Class Mail</option>
								<option value='684'>USPS Priority Mail</option>
							</Select>
						)}
					</Field>
				</Card>
			</FieldSet>
			{values.packagingType !== 'FEDEX_ENVELOPE' &&
				values.packagingType !== '' && (
					<FieldSet>
						<TitleSet>
							<Title>Package Dimensions & Weight</Title>
							<Subtitle>Maximum weight is 150 lbs</Subtitle>
						</TitleSet>
						<Card>
							<Field
								name='length'
								validate={composeValidators(mustBeNumber, minValue(1))}
								parse={parseFigures}
							>
								{({ input, meta }) => (
									<Input
										input={input}
										meta={meta}
										label='Length *'
										type='text'
										size={FORM_GRID[2]}
										placeholder='in'
										autoComplete='on'
										required={true}
										disabled={false}
									></Input>
								)}
							</Field>
							<Field
								name='width'
								validate={composeValidators(mustBeNumber, minValue(1))}
								parse={parseFigures}
							>
								{({ input, meta }) => (
									<Input
										input={input}
										meta={meta}
										label='Width *'
										type='text'
										size={FORM_GRID[2]}
										placeholder='in'
										autoComplete='on'
										required={true}
										disabled={false}
									></Input>
								)}
							</Field>
							<Field
								name='height'
								validate={composeValidators(mustBeNumber, minValue(1))}
								parse={parseFigures}
							>
								{({ input, meta }) => (
									<Input
										input={input}
										meta={meta}
										label='Height *'
										type='text'
										size={FORM_GRID[2]}
										placeholder='in'
										autoComplete='on'
										required={true}
										disabled={false}
									></Input>
								)}
							</Field>
						</Card>
					</FieldSet>
				)}
			<FieldSet>
				<TitleSet>
					<Title>{'Package weight'}</Title>
					<Subtitle>Maximum weight is 150 lbs</Subtitle>
				</TitleSet>
				<Card>
					<Field
						name='weight'
						validate={composeValidators(mustBeNumber, maxValue(150))}
						parse={parseFigures}
					>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Lb(s) *'
								type='text'
								size={FORM_GRID[1]}
								placeholder='lb'
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
					<Field
						name='ozUnit'
						validate={composeValidators(mustBeNumber, maxValue(15))}
						parse={parseFigures}
					>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Oz(s) *'
								type='text'
								size={FORM_GRID[1]}
								placeholder='oz'
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
				</Card>
			</FieldSet>
			{values.packagingType !== 'FEDEX_ENVELOPE' && (
				<FieldSet>
					<TitleSet>
						<Title>Shipment Declaration</Title>
						<Subtitle>Shipment value and description are required</Subtitle>
					</TitleSet>
					<Card>
						<Field
							name='declaredValue'
							validate={composeValidators(mustBeNumber, greaterThan(0))}
							parse={parseFigures}
						>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='Declared value *'
									type='text'
									size={FORM_GRID[2]}
									placeholder='U.S. Dollars'
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
						<Field name='itemDescription'>
							{({ input, meta }) => (
								<Input
									input={input}
									meta={meta}
									label='Item Description *'
									type='text'
									size={FORM_GRID[0]}
									placeholder=''
									autoComplete='on'
									required={true}
									disabled={false}
								></Input>
							)}
						</Field>
					</Card>
				</FieldSet>
			)}

			<FieldSet>
				<TitleSet>
					<Title>Ship date</Title>
					<Subtitle>
						The day that shipment's label is scanned and shipped
					</Subtitle>
				</TitleSet>
				<Card>
					{' '}
					<Field name='shipDate'>
						{({ input, meta }) => (
							<Input
								input={input}
								meta={meta}
								label='Ship date *'
								type='date'
								size={FORM_GRID[2]}
								placeholder=''
								autoComplete='on'
								required={true}
								disabled={false}
							></Input>
						)}
					</Field>
				</Card>
			</FieldSet>
		</>
	);
};

export default Fields;
