import React from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import RouterLink from '../components/RouterLink';

const carriers = {
	Fedex: 'fa-brands fa-fedex',
	usps: 'fa-brands fa-usps',
};

const ServiceRates = (props) => {
	const { linkName, onServiceSelect, serviceRates } = props;

	const formatSaveNumber = (price, rate, surcharge) => {
		let number = (1 - price / (rate + surcharge)) * 100;
		return number.toFixed(2);
	};

	const formatRateNumber = (rate, surcharge) => {
		let number = rate + surcharge;
		return number.toFixed(2);
	};

	return (
		<div className='flex flex-col mt-10'>
			<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
				<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
					<div className='overflow-hidden border-b border-gray-200'>
						<table className='min-w-full divide-y divide-gray-200'>
							<thead className='bg-gray-50'>
								<tr>
									<th
										scope='col'
										className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Service
									</th>
									<th
										scope='col'
										className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Price
									</th>
									<th scope='col' className='relative px-6 py-3'>
										<span className='sr-only'>Ship now</span>
									</th>
								</tr>
							</thead>
							<tbody className='bg-white divide-y divide-gray-200'>
								{serviceRates.map((service) => (
									<tr key={uuidv4()}>
										<td className='px-6 py-4 whitespace-nowrap'>
											<div className='flex items-center'>
												<div className='flex-shrink-0 h-10 w-10'>
													<p className='text-3xl'>
														<i className={carriers[service.carrier_code]}></i>
													</p>
												</div>
												<div className='ml-4'>
													<div className='text-sm font-medium text-gray-900'>
														{service.service}
													</div>
													<div className='text-sm text-gray-500'>
														Delivery by:{' '}
														{moment(service.commit_date).format(
															'ddd, MMM Do YYYY, h:mm:ss a'
														)}
													</div>
												</div>
											</div>
										</td>
										<td className='px-6 py-4 whitespace-nowrap'>
											<div className='text-sm font-medium text-gray-900'>
												{service.price.toFixed(2)}
											</div>
											<div className='text-sm text-gray-500'>
												<span className='line-through'>
													{formatRateNumber(service.rate, service.surcharge)}
												</span>{' '}
												save{' '}
												<span className='font-medium'>
													{formatSaveNumber(
														service.price,
														service.rate,
														service.surcharge
													)}
													%
												</span>
											</div>
										</td>

										<td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
											<RouterLink
												path='/create-shipment'
												onClick={(e) => {
													e.preventDefault();
													onServiceSelect(service);
												}}
											>
												{linkName}
											</RouterLink>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceRates;
