export const getAddressObj = (place) => {
	const address = {
		streetLine1: '',
		streetLine2: '',
		city: '',
		postalCode: '',
		stateOrProvinceCode: '',
		countryCode: '',
	};

	const streetLine1 = ['', ''];

	const addressComponents = [...place.address_components];
	for (let i = 0; i < addressComponents.length; i++) {
		if (addressComponents[i].types[0] === 'postal_code') {
			address.postalCode = addressComponents[i].long_name;
		}
		if (addressComponents[i].types[0] === 'locality') {
			address.city = addressComponents[i].long_name;
		}
		if (addressComponents[i].types[0] === 'country') {
			address.countryCode = addressComponents[i].short_name;
		}
		if (addressComponents[i].types[0] === 'administrative_area_level_1') {
			address.stateOrProvinceCode = addressComponents[i].short_name;
		}
		if (addressComponents[i].types[0] === 'street_number') {
			streetLine1[0] = addressComponents[i].long_name;
		}
		if (addressComponents[i].types[0] === 'route') {
			streetLine1[1] = addressComponents[i].long_name;
		}
	}

	if (streetLine1[0] && streetLine1[1]) {
		address.streetLine1 = streetLine1.join(' ');
	}

	console.log(address);

	return address;
};
