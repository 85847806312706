import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './Footer';

class Layout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<div className='bg-white'>
				<Header />

				<Outlet />

				<Footer />
			</div>
		);
	}
}

export default Layout;
