import { store } from '../../../store';

const reqBodyFedex = (formValues, service) => {
	const reqBody = {
		shipDatestamp: formValues.shipDate,
		totalDeclaredValue: {
			amount: formValues.declaredValue || 10,
			currency: 'USD',
		},
		shipper: {
			address: {
				streetLines: [
					formValues.shipperStreetLine1,
					formValues.shipperStreetLine2,
				],
				city: formValues.shipperCity,
				stateOrProvinceCode: formValues.shipperStateOrProvinceCode,
				postalCode: formValues.shipperPostalCode,
				countryCode: formValues.shipperCountryCode,
				residential: false,
			},
			contact: {
				personName: formValues.shipperPersonName,
				phoneNumber: formValues.shipperPhoneNumber,
				emailAddress: '',
			},
		},
		recipients: [
			{
				address: {
					streetLines: [
						formValues.recipientStreetLine1,
						formValues.recipientStreetLine2,
					],
					city: formValues.recipientCity,
					stateOrProvinceCode: formValues.recipientStateOrProvinceCode,
					postalCode: formValues.recipientPostalCode,
					countryCode: formValues.recipientCountryCode,
					residential: false,
				},
				contact: {
					personName: formValues.recipientPersonName,
					phoneNumber: formValues.recipientPhoneNumber,
				},
			},
		],
		pickupType: 'DROPOFF_AT_FEDEX_LOCATION',
		serviceType: service.service_code,
		packagingType: formValues.packagingType,
		requestedPackageLineItems: [
			{
				declaredValue: {
					amount: formValues.declaredValue || '10',
					currency: 'USD',
				},
				weight: {
					units: 'LB',
					value: formValues.weight || 1,
				},
				dimensions: {
					length:
						formValues.packagingType === 'FEDEX_ENVELOPE'
							? 15
							: formValues.length,
					width:
						formValues.packagingType === 'FEDEX_ENVELOPE'
							? 10
							: formValues.width,
					height:
						formValues.packagingType === 'FEDEX_ENVELOPE'
							? 1
							: formValues.height,
					units: 'IN',
				},
				itemDescriptionForClearance: formValues.itemDescription,
				itemDescription: formValues.itemDescription,
			},
		],
	};

	return reqBody;
};

const reqBodyEssential = (formValues, service) => {
	console.log(formValues.packagingType);
	// const reqBody = {
	// 	shipment: {
	// 		parcels: [
	// 			{
	// 				length: formValues.length,
	// 				width: formValues.width,
	// 				height: formValues.height,
	// 				weight: formValues.weight,
	// 				package_type: 'parcel',
	// 				parcel_items: [
	// 					{
	// 						name: 'mask',
	// 						weight: formValues.weight,
	// 						length: formValues.length,
	// 						width: formValues.width,
	// 						height: formValues.height,
	// 						quantity: 1,
	// 						price: 10,
	// 						cost: 10,
	// 						customs_data: {
	// 							content_type: 'OTHER',
	// 							hs_tariff_code: '123233',
	// 							value: '10.00',
	// 						},
	// 					},
	// 				],
	// 				service_options: {
	// 					delivery_confirmation: 'NO_SIGNATURE_REQUIRED',
	// 					saturday_delivery: true,
	// 					saturday_pickup: true,
	// 					hold_for_pickup: true,
	// 					onerate: false,
	// 				},
	// 			},
	// 		],
	// 		service_id: service.service_code,
	// 		ship_date: formValues.shipDate,
	// 		label_format: 'pdf',
	// 		label_size: '4x6',
	// 		inline_image: false,
	// 		label_scale: 0,
	// 		order_id: '53747225',
	// 		to_location: {
	// 			company: formValues.recipientCompanyName,
	// 			first_name: formValues.recipientPersonName,
	// 			last_name: '',
	// 			nick_name: '',
	// 			address1: formValues.recipientStreetLine1,
	// 			address2: formValues.recipientStreetLine2,
	// 			address3: '',
	// 			city: formValues.recipientCity,
	// 			state: formValues.recipientStateOrProvinceCode,
	// 			country: formValues.recipientCountryCode,
	// 			postal_code: formValues.recipientPostalCode,
	// 			phone: formValues.recipientPhoneNumber,
	// 			email: '',
	// 		},
	// 		from_location: {
	// 			company: formValues.shipperCompanyName,
	// 			first_name: formValues.shipperPersonName,
	// 			last_name: '',
	// 			nick_name: '',
	// 			address1: formValues.shipperStreetLine1,
	// 			address2: formValues.shipperStreetLine2,
	// 			address3: '',
	// 			city: formValues.shipperCity,
	// 			state: formValues.shipperStateOrProvinceCode,
	// 			country: formValues.shipperCountryCode,
	// 			postal_code: formValues.shipperPostalCode,
	// 			phone: formValues.shipperPhoneNumber,
	// 			email: '',
	// 		},
	// 	},
	// };

	const reqBody = {
		shipment: {
			from_location: {
				company: formValues.shipperCompanyName,
				first_name: formValues.shipperPersonName,
				last_name: '',
				nick_name: '',
				address1: formValues.shipperStreetLine1,
				address2: formValues.shipperStreetLine2,
				address3: '',
				city: formValues.shipperCity,
				state: formValues.shipperStateOrProvinceCode,
				country: formValues.shipperCountryCode,
				postal_code: formValues.shipperPostalCode,
				phone: formValues.shipperPhoneNumber,
				email: '',
			},
			to_location: {
				company: formValues.recipientCompanyName,
				first_name: formValues.recipientPersonName,
				last_name: '',
				nick_name: '',
				address1: formValues.recipientStreetLine1,
				address2: formValues.recipientStreetLine2,
				address3: '',
				city: formValues.recipientCity,
				state: formValues.recipientStateOrProvinceCode,
				country: formValues.recipientCountryCode,
				postal_code: formValues.recipientPostalCode,
				phone: formValues.recipientPhoneNumber,
				email: '',
			},
			order_id: '123433',
			parcels: [
				{
					length:
						formValues.packagingType === 'FEDEX_ENVELOPE'
							? 15
							: formValues.length,
					width:
						formValues.packagingType === 'FEDEX_ENVELOPE'
							? 10
							: formValues.width,
					height:
						formValues.packagingType === 'FEDEX_ENVELOPE'
							? 1
							: formValues.height,
					weight: formValues.weight,
					package_type: 'parcel',
					description: 'mask',
					parcel_items: [
						{
							name: 'mask',
							weight: formValues.weight,
							length:
								formValues.packagingType === 'FEDEX_ENVELOPE'
									? 15
									: formValues.length,
							width:
								formValues.packagingType === 'FEDEX_ENVELOPE'
									? 10
									: formValues.width,
							height:
								formValues.packagingType === 'FEDEX_ENVELOPE'
									? 1
									: formValues.height,
							quantity: 1,
							price: 1,
							customs_data: {
								content_type: 'OTHER',
								hs_tariff_code: '123456',
								value: '1',
							},
						},
					],
				},
			],
			service_id: '684',
		},
	};

	return reqBody;
};

export const getReqBody = (values) => {
	let reqBody = null;

	const service = { ...store.getState().shipment.selectedService };
	const formValues = { ...store.getState().shipment.initialValues };

	console.log(formValues);

	let finalWeight = 0;
	finalWeight = parseInt(formValues.weight) + parseInt(formValues.ozUnit) / 16;

	formValues.weight = finalWeight || 0.21875;

	if (service.carrier_code === 'Fedex')
		reqBody = reqBodyFedex(formValues, service);
	if (service.carrier_code === 'usps')
		reqBody = reqBodyEssential(formValues, service);

	console.log(reqBody);

	return reqBody;
};
