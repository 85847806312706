import React from 'react';

import { connect } from 'react-redux';
import { getQuotes } from '../../../store/actions';

import FormSubmit from '../FormSubmit';
import Fields from './Fields';
import { getReqBody } from './getReqBody';
import Checkout from './Checkout';

const buttonLabel = 'Continue';
const redirectPath = '';

const ShipmentDetailsForm = ({
	setRedirectPath,
	serviceRates,
	initialValues,
	onStepChange,
}) => {
	return (
		<>
			<FormSubmit
				component={Fields}
				submitAction={getQuotes}
				getReqBody={getReqBody}
				buttonLabel={buttonLabel}
				redirectPath={redirectPath}
				setRedirectPath={setRedirectPath}
				initialValues={initialValues}
			/>
			{serviceRates && <Checkout onStepChange={onStepChange} />}
		</>
	);
};

const mapStateToProps = (state) => ({
	initialValues: state.shipment.initialValues,
	serviceRates: state.price.serviceRates,
});

export default connect(mapStateToProps)(ShipmentDetailsForm);
