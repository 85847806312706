import { combineReducers } from 'redux';
import authReducer from './authReducer';
import priceReducer from './priceReducer';
import shipmentReducer from './shipmentReducer';
import transactionReducer from './transactionReducer';

export default combineReducers({
	auth: authReducer,
	price: priceReducer,
	shipment: shipmentReducer,
	transaction: transactionReducer,
});
