export const P = ({ children }) => {
	return <p className='mb-5'>{children}</p>;
};

export const H2 = ({ children }) => {
	return (
		<h2 className='text-xl font-semibold pt-5 lg:pt-3 pb-2 sm:pb-3 lg:pb-4 leading-4'>
			{children}
		</h2>
	);
};

export const Strong = ({ children }) => {
	return <strong className='font-semibold'>{children}</strong>;
};

export const A = ({ children, ...props }) => {
	return (
		<a {...props} className='font-medium text-yellow-500 hover:text-yellow-400'>
			{children}
		</a>
	);
};
