import { TYPES } from '../actions/types';

const INITIAL_STATE = {
	currentUser: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TYPES.AUTH_USER:
			return {
				...state,
				currentUser: action.payload,
			};
		default:
			return state;
	}
};
