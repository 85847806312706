import React from 'react';
import { P, A } from '../components/common';
import PageTitle from '../components/PageTitle';

export default function TermsAndConditions() {
	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			<PageTitle title='Terms and Conditions' />
			<br />
			<P>Last Modified: April 30th, 2022</P>
			<P>
				This is our agreement. When you create a G7 Express' account, we form a
				contract. G7 Express will be bound by the terms outlined below, and so
				will you.
			</P>
			<P>
				Our Privacy Policy, available at{' '}
				<A href='/privacy-policy'>https://g7express.com/privacy-policy</A>, is a
				part of this contract and separately explains our obligations to protect
				your data and personal information. It also covers what choices you can
				make about getting or deleting the personal information we keep as part
				of our service. So, please read that carefully, and if you have any
				concerns, follow the links and instructions it provides.
			</P>
			<P>
				If you are located in the European Union, our Data Processing Addendum,
				available at{' '}
				<A href='https://g7express.com/dpa'>https://g7express.com/dpa</A>, is
				part of this contract. The Data Processing Addendum defines our specific
				legal obligations under the data protection laws of the European Union
				and your agreement with respect to the terms governing the processing of
				your personal data.
			</P>
			<P>
				<strong>
					We also ask that you read these Terms of Use carefully, because they
					contain important limitations on your legal rights, like mandatory
					arbitration and certain limits on our legal liabilities.{' '}
				</strong>
			</P>
			<P>Now for the details...</P>
			<P>
				<strong>AGREEMENT BETWEEN CUSTOMER AND G7 LOGISTICS INC</strong>
			</P>
			<P>
				You, our customer, are really important to us. So, we have to make sure
				we are legally able to help you.
			</P>
			<P>
				We can provide our services only if you are over sixteen (16) years old
				and agree to use our shipping, labeling and other services for
				legitimate purposes (for instance, you are not shipping anything
				illegal).
			</P>
			<P>This means that by using our services you agree that you:</P>
			<ul>
				<li>
					Are sixteen (16) years of age or older and are legally able and
					competent to enter into these Terms of Use (we’ll call them the
					"Terms"),
				</li>
				<li>
					Will only use the G7 Express' website to make legitimate purchases of
					courier services for legal shipping of products and documents on your
					own behalf or for another person for whom you are legally authorized
					to act (a "Third Party"); and
				</li>
				<li>
					Will be bound by these Terms, which we may update and amend from time
					to time.
				</li>
			</ul>
			<P>
				If you do not agree to the Terms, you must not use or purchase any
				third-party courier services through the G7 Express' website.
			</P>
			<P>
				If you have any concerns after you set up your account, you can contact
				our customer support to cancel your account at any time.{' '}
			</P>
			<P>
				<strong>What We Do; Our Services</strong>
			</P>
			<P>
				We provide the G7 Express website ("Website") to assist you, our
				customers, in comparing, selecting, and purchasing third party courier
				services and postage, compiling and reviewing your shipping history, and
				resolving any questions via our customer support services. We will call
				these our "Services". We provide the Services for the legal shipping of
				products and documents nationally and internationally or otherwise
				transacting business with courier services, and for no other purposes.{' '}
			</P>
			<P>
				When we refer to "we," "us," "our" and "G7 Express," we refer to G7
				Logistics Inc, a California entity, and its subsidiaries and corporate
				affiliates. The term "you" refers to the customer visiting the Website
				and/or purchasing third-party courier services through us on this
				Website, or through our partners and customer service agents.
			</P>
			<P>
				<strong>G7 Express is an Intermediary</strong>
			</P>
			<P>
				G7 Express serves only as an intermediary between you and couriers like
				Federal Express (FedEx), the United States Postal Service ("USPS") and
				United Parcel Service ("UPS"). We only provide you with a platform to
				order their postage, shipping products and services. We do not provide
				the courier services ourselves. We do not transport, ship, deliver, or
				otherwise provide any service beyond simply reselling postage, labels
				and the Services we list on the Website.{' '}
			</P>
			<P>
				When we display service options on our Website offered by couriers like
				USPS and UPS, this does not mean that we recommend, approve or sponsor
				that courier or that courier’s service options. We also cannot guarantee
				the quality of those services. Your interaction with any courier
				accessed through the Website is at your own risk.{' '}
			</P>
			<P>
				You agree that G7 Express has no control over the couriers listed on the
				Website, and is not responsible for the accuracy, timeliness, or
				thoroughness of information on the Website related in any way to
				couriers or their Services. You acknowledge that G7 Express will have no
				liability with respect to the acts, omissions, errors, representations,
				warranties, breaches, or negligence of any such courier or for any
				personal injuries, death, property damage, or other damages or expenses
				resulting from your interactions with the courier.
			</P>
			<P>
				It is your responsibility to find, open, read, understand, and accept
				the terms, conditions, or rules of purchase of any courier you select
				for shipping. You are responsible for ensuring that your purchases abide
				by the terms and conditions of purchase imposed by any courier you
				select to perform shipping services, including terms and conditions of
				purchase set forth in a courier's terms, conditions, and rules. For
				instance, if a courier doesn’t permit the shipment of hazardous liquids,
				it is your responsibility to comply with those rules. For example, the
				first time you purchase UPS shipping services through the Website, in
				order to complete the purchase you will be required to accept certain
				UPS terms and conditions.
			</P>
			<P>
				<strong>Access from Third Party Sites</strong>
			</P>
			<P>
				G7 Express may integrate with third party ecommerce software to allow
				you to import order data to more easily create shipping labels and to
				relay the order status back to your ecommerce software. G7 Express has
				no control over third party software we may integrate with, or over the
				information you have entered into or have stored on that software. G7
				Express also does not have control over the application programming
				interface (API) for third party software or the currency of any links or
				data-sharing protocols. Because we do not have control over that
				software, we cannot be and are not responsible for any lost data, errors
				in data or in data transmission, downtime, unavailability, or any other
				loss or damage that might occur from using that software or the links on
				or connections to that software. We recommend you review the privacy
				policy governing any third party software before using them.
			</P>
			<P>
				<strong>Availability of Service</strong>
			</P>
			<P>
				We will try to keep the G7 Express site available at all times. However,
				there may be times when we have to maintain, update or improve the
				Website or temporarily suspend the availability of the Services for
				other reasons. We do not guarantee the availability of the Website or
				Services at any time, and will not be liable for any suspension or
				discontinuance of the Services.{' '}
			</P>
			<P>
				<strong>Changes to our Terms and Privacy Policy</strong>
			</P>
			<P>
				As our service offerings change, as laws change, as the world changes,
				G7 Express may, from time to time, amend or modify these Terms and/or
				our Privacy Policy at{' '}
				<A href='/privacy-policy'>https://g7express.com/privacy-policy</A>. We
				will post the amended or modified Terms and/or Privacy Policy on the
				Website, which will automatically be effective five (5) business days
				after being posted. If at any time you do not agree to be bound by and
				comply with these Terms, as amended or modified from time to time, you
				agree that your sole remedy is to cease using the Website.{' '}
			</P>
			<P>
				<strong>Personal Conduct &amp; Customer Support</strong>
			</P>
			<P>
				Our customer support team is available to help you via the chat on our
				Website or email as listed on{' '}
				<A href='https://g7express.com/contact'>
					https://g7express.com/contact
				</A>{' '}
				with all your shipping questions and to make our Services as easy to use
				as possible. But we can only do our job well if everyone behaves. If you
				are abusive, mean or nasty to our customer support personnel, we reserve
				the right to stop serving you until you can be nice, or to suspend or
				terminate your account with G7 Express. Please note we will record or
				keep written records of our customer support interactions. All retention
				of such recordings or other records will be subject to our Privacy
				Policy, available at{' '}
				<A href='/privacy-policy'>https://g7express.com/privacy-policy</A>.{' '}
			</P>
			<P>
				<strong>We May Contact You</strong>
			</P>
			<P>
				You agree that we may communicate with you by email, text messaging,
				phone, mail, or otherwise; usually our communication will relate to
				Website and Service updates, promotions, administrative, security and
				other issues relating to G7 Express's Services, including invitations to
				participate in promotional activities, newsletters and other
				communications. Receiving these communications is a condition of your
				use of G7 Express, though we may offer you the opportunity to opt out of
				some of them. You may also block all communication from us by contacting
				our customer support team and asking to cancel your G7 Express' account.{' '}
			</P>
			<P>
				<strong>Legal Use of Website</strong>
			</P>
			<P>
				By accessing or using this Website or the Services, you agree that you
				will comply with all applicable laws and regulations, including U.S.
				import, export and re-export control laws and regulations, controlled
				substance laws and hazardous materials laws, regulations and private
				courier rules. G7 Express reserves the right at all times to disclose
				any information as necessary to satisfy any applicable law, regulation,
				legal process or governmental request.{' '}
			</P>
			<P>
				You also acknowledge that G7 Express is required to retain certain
				information with regard to shipments due to contractual obligations with
				couriers like USPS and UPS, combatting fraud and protecting your
				information, and performing our legal obligations under applicable law.
				You can read more information about that in our Privacy Policy at{' '}
				<A href='/privacy-policy'>https://g7express.com/privacy-policy</A>.{' '}
			</P>
			<P>
				<strong>Payments and Additional Charges</strong>
			</P>
			<P>
				You agree to pay G7 Express all charges applicable for the Services at
				the time such Services are ordered by you on the Website. You are
				responsible for ensuring that G7 Express has up-to-date information
				(including, among other things, valid credit card numbers and contact
				information) and you are responsible for entry of information regarding
				the Services you order. Should any amounts due for Services remain
				unpaid, or in the event that any check, ACH payment, credit card charge
				or online payment service is denied, returned, or refused, you agree to
				pay, in addition to the principal amount and any applicable interest
				thereon, all of the bank and payment provider charges assessed against
				you and G7 Express and G7 Express's reasonable costs of collecting or
				attempting to collect, including reasonable attorney fees and expenses.
				G7 Express reserves the right to verify your credit before you purchase
				Services at any time. All transactions originating from your account are
				your responsibility. In addition, if any check, ACH payment, credit card
				charge or online payment service is denied, returned, or refused, G7
				Express may charge the underlying payment and any incidental charges (as
				described above) against any other form of payment you have ever added
				to your G7 Express account.
			</P>
			<P>
				You are responsible for any additional charges due to (a) misstatements
				regarding weight, size, classification, number, or manner of Service,
				(b) deliveries on weekends or holidays, (c) local customs charges, (d)
				import taxes or duties, or (e) any similar charge(s) incurred. If any
				such charges become due as a result of Services performed by a carrier
				on your behalf and are charged to G7 Express by a carrier, you agree
				that G7 Express may charge any additional amounts due on the credit card
				or bank account used when such Services were purchased or, in the event
				that you paid for the Services in another manner, you agree to reimburse
				G7 Express fully for such charges within seven (7) days of our demand.
				G7 Express is not required to automatically provide any refunds or
				reduction of charges for any misstatement by you of weight, size, number
				or manner of Service.
			</P>
			<P>
				Any claim for unused or misprinted postage or fee refund must be
				submitted directly to G7 Express on the Website within twenty (20) days
				of date of purchase. Postage handled and/or returned to sender will not
				be considered valid unused and/or misprinted postage. If determined
				valid, at G7 Express’s sole discretion, G7 Express will credit an active
				customer's account an amount equal to what was paid for the Services. In
				addition, you agree to assign to G7 Express any and all rights to file
				any such claims with or against any carrier with respect to Services
				ordered or paid for on the Website.
			</P>
			<P>
				<strong>Account Balances</strong>
			</P>
			<P>
				You may maintain a pre-funded account balance with G7 Express and view
				that balance, your transaction, shipping label and postage activity,
				refunds, adjustments and other transactions within your account, online
				at any time. G7 Express does not share that account activity with the
				couriers or third parties, though the couriers may keep track of the
				shipping information that you provide for postage/courier labels.
			</P>
			<P>
				If you fund and maintain a positive balance in your account with G7
				Express, G7 Express will keep track of that balance but will not
				segregate those funds into separate bank accounts and will not pay
				interest on those accounts. If you request a refund of any positive
				account balances, G7 Express will work to promptly and expeditiously
				honor such refund requests, subject to the payment/refund procedures of
				your bank/payment provider.
			</P>
			<P>
				If we don’t hear from you for a while and we cannot get in touch with
				you, we may consider your account abandoned. We will wait as long as we
				can to maintain your account and reach out to you at the contact
				information you’ve provided. But G7 Express (and its partners or
				successors in interest) may refer abandoned account balances to the
				Wyoming State Treasurer in accordance with the Wyoming Uniform Unclaimed
				Property Act if unclaimed after five (5) years and the identity, status,
				or present location of the account holder cannot be determined. If G7
				Express (or its successors) is no longer headquartered in Wyoming at
				that time, then the account balance may be referred to the State
				Treasurer or applicable office in whichever state or jurisdiction we are
				in at that time.
			</P>
			<P>
				<strong>Change in Billing, Prices, Promotions and Policies</strong>
			</P>
			<P>
				G7 Express may change billing procedures, prices, promotions and/or
				policies at any time without advance notice. All such changes will be
				posted on the Website. Continued use of the Website and/or Services
				constitutes your affirmation that you have read and understood such
				notices and agree to the terms contained therein.
			</P>
			<P>
				<strong>Collection; Use of Your Data</strong>
			</P>
			<P>
				G7 Express may automatically collect some information about your
				computer when you visit this website. For example, we may collect your
				IP address, Web browser software (such as Chrome, Firefox, Safari, or
				Internet Explorer), and referring website. We also may collect
				information about your online activity, such as services and couriers
				viewed and services booked. Our goals in collecting this automatic
				information include helping customize your user experience and
				inhibiting fraud. G7 Express may use sensitive billing information (such
				as cardholder name, credit card number, and expiration date) for the
				purpose of completing the purchase of courier services you request on
				our Website. We use other information about you for the following
				general purposes: to provide you with the products and services you
				request; to provide you with courier confirmation and updates; to manage
				your account, including processing bills and providing courier
				information; to communicate with you in general; to respond to your
				questions and comments; to measure interest in and improve our products,
				Services, Website, and related apps; to notify you about special offers
				and products or services that may be of interest to you; to otherwise
				customize your experience with this Website and related apps; to reward
				you as part of any reward and recognition program you choose to join; to
				solicit information from you, including through surveys; to resolve
				disputes, collect fees, or troubleshoot problems; to prevent potentially
				prohibited or illegal activities; to enforce these Terms; and as
				otherwise described to you at the point of collection.
			</P>
			<P>
				You are solely responsible for the accuracy, quality, integrity,
				legality, reliability, appropriateness and copyright of all data. G7
				Express may collect, use, and distribute statistical information related
				to the usage of the Website, Services, or traffic patterns only in
				aggregate and de-identified form ("Aggregated Data"). Except as
				otherwise stated in our Privacy Policy, Aggregated Data does not contain
				any personal information about you, and G7 Express will not sell
				personal information about you to third parties. Aggregated Data may be
				shared with third-parties, but is stripped of all personal information
				and contains information like: the busiest shipping date of the year is
				December 18th and the top shipping destination in the U.S. is the metro
				NYC area. G7 Express will own all Aggregated Data.
			</P>
			<P>
				G7 Express does not knowingly collect or solicit any information from
				anyone under the age of sixteen (16) or knowingly allow such persons to
				register accounts for G7 Express Services. The Services are not meant
				for children/minors under the age of sixteen (16). In the event that we
				learn that we have collected personal information from anyone under the
				age of sixteen (16) without parental consent, we will delete that
				information and terminate the applicable account. If you believe that we
				have collected any information from anyone under the age of sixteen
				(16), please contact us via the chat on our website or email as listed
				on{' '}
				<A href='https://g7express.com/contact'>
					https://g7express.com/contact
				</A>
				.
			</P>
			<P>
				<strong>Termination</strong>
			</P>
			<P>
				You may terminate your account with G7 Express at any time by contacting
				our customer support.
			</P>
			<P>
				G7 Express may terminate your access to and use of the Website for any
				or no reason at any time. In addition, G7 Express may suspend or
				terminate your account for misconduct such as abusive behavior toward
				our customer support personnel or using the Services for illegal
				purposes. Upon such termination, you agree to immediately pay G7 Express
				any unpaid amounts related to Services ordered. Any accrued rights,
				remedies, obligations or liabilities will not be affected including, but
				not limited to, G7 Express's right to claim damages in respect of any
				breach of these Terms or G7 Express's right to indemnification by you
				under these Terms. In addition, any provisions of these Terms that
				expressly, or by implication, have effect after termination will
				continue in full force and effect.{' '}
			</P>
			<P>
				When your account is terminated, we may retain your information as
				required by applicable regulations and G7 Express's records and
				information management policies to comply with our legal and reporting
				obligations, resolve disputes, enforce our agreements, complete any
				outstanding transactions, and for the detection and prevention of fraud.
			</P>
			<P>
				<strong>Our Proprietary Rights</strong>
			</P>
			<P>
				Our Website and the software used in connection with the G7 Express
				Services contain proprietary and confidential information that is
				protected by applicable intellectual property and other laws. You may
				not copy or create any derivative work from any text or images on our
				Website. You may not copy, modify, create a derivative work of, reverse
				engineer, reverse assemble or otherwise attempt to discover any source
				code, rent, sell, assign, sublicense, or otherwise transfer or attempt
				to transfer any rights in the Website or software. You may not modify
				the Website or software in any manner or form or use modified versions
				of the Website or software. You may not access the G7 Express Website or
				any of our data by any means other than through interfaces we provide.
			</P>
			<P>
				You also acknowledge that G7 Express, the G7 Express logos, and the look
				and feel of our Website, and the order and choices in the Website menus
				are all the property of G7 Express and its affiliates and subject to
				trademark and copyright protection. You agree that you will not use or
				copy any of our or our partners' trademarks, trade names, logos or the
				style, look and feel of our Website or software without our written
				permission.{' '}
			</P>
			<P>
				You agree that the Website, in whole or in part, will not be reproduced,
				duplicated, copied, sold, resold, or otherwise exploited for any purpose
				not expressly permitted by G7 Express. Any use of Website data,
				materials, content, or information; any derivative use of this Website
				or its contents; and any use of data mining, robots, or similar data
				gathering and extraction tools are strictly prohibited.
			</P>
			<P>
				<strong>Disclaimer of Warranties</strong>
			</P>
			<P>
				G7 Express offers the Website and Services on an "as is" basis. That
				means we make no promises and no representations or warranties of any
				kind to you. This includes, but is not limited to, the availability or
				operation of the Website, or the content, data, information, or Services
				on this Website. There are no implied warranties of merchantability,
				title, non-infringement, fitness for a particular purpose, security and
				accuracy, or any other warranties that might arise from the course of
				dealing, course of performance, or usage of trade. We make no promise,
				representation or warranty to you about any of that. No advice or
				information provided by G7 Express will create a warranty. You assume
				total responsibility.{' '}
			</P>
			<P>
				The information published on the Website and the Services may include
				inaccuracies or errors, including pricing errors. In particular, G7
				Express does not guarantee the accuracy of, and disclaims all liability
				for any errors or other inaccuracies relating to the information and
				description of the courier services displayed on this Website
				(including, without limitation, the pricing, general product
				descriptions, etc.). In addition, G7 Express expressly reserves the
				right to correct any pricing errors on our Website.
			</P>
			<P>
				<strong>Limitation of G7 Express's Liability</strong>
			</P>
			<P>
				You assume all risk of loss when using the Services. G7 Express will not
				be liable for any risk of any kind arising from your use of, or
				inability to use, the Website, the Services, or any other content, data,
				materials, or information of G7 Express.
			</P>
			<P>IN PARTICULAR, G7 Express WILL NOT BE LIABLE FOR: </P>
			<ul>
				<li>
					ANY DAMAGES TO OR VIRUSES, WORMS, TROJAN HORSES OR OTHER DISABLING OR
					HARMFUL COMPONENTS THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR OTHER
					PROPERTY AS THE RESULT OF YOUR ACCESS TO THE WEBSITE OR YOUR
					DOWNLOADING OF ANY CONTENT FROM THE WEBSITE OR ANY THIRD PARTY SITES;{' '}
				</li>
				<li>
					LOST OR PROSPECTIVE PROFITS OR REVENUE, LOSS OF GOODWILL, LOSS OF
					CUSTOMERS, DATA LOSS, WORK STOPPAGE, OR COMPUTER FAILURE, DELAY OR
					MALFUNCTION ANY INJURY, DEATH, LOSS, CLAIM, ACT OF GOD, ACCIDENT,
					DELAY, OR{' '}
				</li>
				<li>
					ANY DIRECT, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR
					CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING WITHOUT LIMITATION LOST
					PROFITS OR LOST SAVINGS),{' '}
				</li>
			</ul>
			<P>
				whether any of those claims are based in contract, tort, strict
				liability or otherwise, that arise out of or are in any way related to:
				(i) any use of the Website or the data, materials, information or
				content thereof; (ii) any failure or delay (including, without
				limitation, the use of or inability to use any component of this
				Website) in performance of Services; or (iii) the performance or
				non-performance by G7 Express, a courier, or any other provider, whether
				or not G7 Express knew or should have known of the possibility that such
				damages to such parties or any other party might be incurred.{' '}
			</P>
			<P>
				In any event, G7 Express will not be liable for any damages in excess of
				the purchase price paid for the individual Service at issue, whether or
				not G7 Express had knowledge that such damages might be incurred.
			</P>
			<P>
				<strong>Indemnity</strong>
			</P>
			<P>
				You will defend, indemnify and hold G7 Express, its officers, directors,
				employees, and agents, harmless from and against any claim, cause of
				action, liability, expense, loss or demand, including without limitation
				reasonable legal and accounting fees, arising out of, or in any way
				connected with your breach of these Terms or your use of, or access to,
				the Website or Services.
			</P>
			<P>
				<strong>Class Action Waiver</strong>
			</P>
			<P>
				In exchange for G7 Express’s provision of the Services and your payment
				for those Services, you hereby waive the right to bring or participate
				in any class action lawsuit against G7 Express and its affiliates (which
				will include any party controlling, controlled by or in common control
				with G7 Express, or any of G7 Express’s employees, directors or
				officers).{' '}
			</P>
			<P>
				SPECIFICALLY YOU AGREE NOT TO (1) SEEK TO PROCEED ON ANY CLAIM IN
				ARBITRATION OR COURT AS A CLASS CLAIM OR CLASS ACTION OR OTHER
				COMPARABLE REPRESENTATIVE PROCEEDING; (2) SEEK TO CONSOLIDATE IN
				ARBITRATION OR COURT ANY CLAIMS INVOLVING SEPARATE CLAIMANTS; (3) BE
				PART OF, OR BE REPRESENTED IN, ANY CLASS ACTION OR OTHER REPRESENTATIVE
				ACTION BROUGHT BY ANYONE ELSE AGAINST G7 Express OR ITS AFFILIATES; NOR
				(4) SEEK ANY AWARD OR REMEDY IN ARBITRATION OR COURT AGAINST OR ON
				BEHALF OF ANYONE WHO IS NOT A NAMED PARTY TO THE ARBITRATION.
			</P>
			<P>
				<strong>Choice of Law; Wyoming Jurisdiction </strong>
			</P>
			<P>
				The Services performed by G7 Express LLC are offered from, and performed
				in the State of Wyoming. These Terms are governed and interpreted in
				their entirety pursuant to the laws of the State of Wyoming, United
				States of America or U.S. federal laws and regulations, as applicable,
				notwithstanding any principles of conflicts of law.{' '}
			</P>
			<P>
				You agree that any action related to these Terms, the Website or the
				Services and not subject to the mandatory arbitration clause described
				below may only be brought before the state and federal courts located in
				Wyoming.
			</P>
			<P>
				<strong>Arbitration and Dispute Resolution</strong>
			</P>
			<P>
				By this Agreement, both you and we are waiving certain rights to
				litigate disputes in court. You and we both agree that any arbitration
				will be conducted on an individual basis and not on a consolidated,
				class wide or representative basis.{' '}
			</P>
			<P>
				All disputes arising out of or relating to these Terms and Conditions
				will be finally resolved by arbitration conducted in the English
				language in Laramie County, Wyoming under the commercial arbitration
				rules of the American Arbitration Association or JAMS. You agree that
				this will be the sole and exclusive jurisdiction for the adjudication of
				any disputes arising in connection with these Terms and Conditions, your
				use of the Services, and your relationship with G7 Express LLC and its
				affiliates, members, directors, officers, employees and agents. The
				arbitration will be conducted by a single arbitrator mutually selected
				by the parties. If the parties cannot agree on an arbitrator, then they
				may each appoint their own arbitrator, which two arbitrators will in
				turn, select the single arbitrator for adjudication of the dispute. he
				parties will bear equally the cost of the arbitration (except that the
				prevailing party will be entitled to an award of reasonable attorneys’
				fees incurred in connection with the arbitration in such an amount as
				may be determined by the arbitrator). Under no circumstances will the
				arbitrator be authorized to award punitive damages, including but not
				limited to federal or state statutes permitting multiple or punitive
				damage awards. Any purported award of punitive or multiple damages will
				be beyond the arbitrator’s authority, void, and unenforceable.
			</P>
			<P>
				All decisions of the arbitrator will be final and binding on both
				parties and enforceable in any court of competent jurisdiction.{' '}
			</P>
			<P>
				Rules and forms for filing an arbitration claim can be found at{' '}
				<A href='http://www.jamsadr.com/' target='_blank'>
					www.jamsadr.com
				</A>{' '}
				or{' '}
				<A href='http://www.adr.org/' target='_blank'>
					www.adr.org
				</A>
				.{' '}
			</P>
			<P>
				This arbitration agreement is entered into pursuant to the Federal
				Arbitration Act, 9 U.S.C. §§1-16 (FAA).
			</P>
			<P>
				Notwithstanding anything to the contrary, we will be entitled to seek
				injunctive relief, security, or other equitable remedies from federal
				and state courts located in the State of Wyoming or any other court of
				competent jurisdiction.{' '}
			</P>
			<P>
				<strong>Permitted Time For Filing A Claim or Lawsuit</strong>
			</P>
			<P>
				Regardless of any statute or law to the contrary, any claim or cause of
				action arising out of or related to use of the Services or registration
				on our Website must be filed within one (1) year after such claim or
				cause of action arose or will be forever barred.
			</P>
			<P>
				<strong>Miscellaneous</strong>
			</P>
			<P>
				You agree that nothing in these Terms or in your use of or access to the
				Website or Services will make you an employee, agent, or legal
				representative of G7 Express. You may not assign or delegate the
				performance of part or all of your obligations under these Terms,
				without the prior written approval of G7 Express.
			</P>
			<P>
				All terms used in one number or gender will be construed to include any
				other number or gender as the context may require. If any provision of
				these Terms is held to be invalid or unenforceable, such provision will
				be deemed to be restated to reflect as nearly as possible the original
				intention in accordance with applicable law, and the remainder of the
				Terms will remain in full force and effect. These Terms constitute the
				entire agreement between the parties with respect to the subject matter
				hereof and supersede and replace all prior or contemporaneous
				understandings or agreements, written or oral, regarding such subject
				matter. Any waiver of any provision of the Terms will be effective only
				if in writing and signed by G7 Express. The failure to enforce any right
				under these Terms will not be a waiver of the provision or the right to
				enforce it at a later time.
			</P>
			<P>
				<strong>
					By accepting these Terms, you are also accepting the{' '}
					<A href='https://g7express.com/privacy-policy'>Privacy Policy</A> and{' '}
					<A href='/dpa'>Data Processing Addendum</A>, which is included by
					reference in these Terms of Use. The Data Processing Addendum defines
					our specific legal obligations under the data protection laws of the
					European Union and your agreement with respect to the terms governing
					the processing of your personal data.
				</strong>
			</P>
			<P>
				<strong>
					For customers located in the European Union, your acceptance of the
					Terms of Use, Privacy Policy and the Data Processing Addendum will be
					by separate acknowledgement.
				</strong>
			</P>
			<P>
				<strong>
					If you are located in the European Union and do not see a request for
					an acknowledgement of the Terms of Use, Privacy Policy and Data
					Processing Addendum (which may be due to your temporary location or
					use of the site outside the EU or the through your use of an onion
					browser), you may request an acknowledgement or separate PDF copy of
					the Terms of Use, Privacy Policy and the Data Processing Agreement by
					contacting our{' '}
					<A href='https://g7express.com/contact'>customer support</A> via chat,
					email, or phone, as available.
				</strong>
			</P>
			<P>
				UPS, the UPS brandmark, UPS Ready®, and the color brown are trademarks
				of United Parcel Service of America, Inc. All Rights Reserved.
			</P>
			<P>
				Discounts off UPS daily rates. Rates are limited to shipping from the
				U.S. only. Rates and any applicable discounts are subject to change at
				any time without notice.
			</P>
			<P>
				NOTICE: The UPS package tracking systems accessed via this service (the
				“Tracking Systems”) and tracking information obtained through this
				service (the “Information”) is the private property of UPS. UPS
				authorizes you to use the Tracking Systems solely to track shipments
				tendered by or for you to UPS for delivery and for no other purpose.
				Without limitation, you are not authorized to make the Information
				available on any web site or otherwise reproduce, distribute, copy,
				store, use or sell the Information for commercial gain without the
				express written consent of UPS. This is a personal service, thus your
				right to use the Tracking Systems or Information is non-assignable. Any
				access or use that is inconsistent with these terms is unauthorized and
				strictly prohibited.
			</P>
		</main>
	);
}
