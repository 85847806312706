import React, { useState } from 'react';
import Steps from '../components/Steps';
import PageTitle from '../components/PageTitle';
import ShipmentDetailsForm from '../lib/form/shipment-details-form';
import PaymentForm from '../lib/form/payment-form';
// import Payment from '../components/create-shipment/Payment';
import Confirmation from '../components/create-shipment/Confirmation';

import { useSearchParams } from 'react-router-dom';

const CreateShipment = () => {
	const [searchParams] = useSearchParams();
	const clientSecret = searchParams.get('payment_intent_client_secret');

	let current = 0;
	if (clientSecret) {
		current = 2;
	}

	const [state, setState] = useState({
		steps: [
			{
				id: 'Step 1',
				name: 'Shipment details',
				href: '#',
				isCompleted: false,
			},
			{
				id: 'Step 2',
				name: 'Payment',
				href: '#',
				isCompleted: false,
			},
			{
				id: 'Step 3',
				name: 'Confirmation and labels',
				href: '#',
				isCompleted: false,
			},
		],
		current,
	});

	const onStepChange = () => {
		setState((prevState) => {
			let newSteps = [...prevState.steps];

			newSteps[prevState.current].isCompleted = true;

			return {
				...prevState,
				current: state.current + 1,
				steps: newSteps,
			};
		});
	};

	const onStepEdit = (idx) => {
		setState((prevState) => {
			return {
				...prevState,
				current: idx,
			};
		});
	};

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			<PageTitle title='Create a Shipment' />

			<div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
				<Steps
					steps={state.steps}
					current={state.current}
					onStepEdit={onStepEdit}
				/>
			</div>

			{state.current === 0 && (
				<ShipmentDetailsForm onStepChange={onStepChange} />
			)}
			{state.current === 1 && <PaymentForm onStepChange={onStepChange} />}
			{state.current === 2 && <Confirmation clientSecret={clientSecret} />}
		</main>
	);
};

// class CreateShipment extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			steps: [
// 				{
// 					id: 'Step 1',
// 					name: 'Shipment details',
// 					href: '#',
// 					isCompleted: false,
// 				},
// 				{
// 					id: 'Step 2',
// 					name: 'Payment',
// 					href: '#',
// 					isCompleted: false,
// 				},
// 				{
// 					id: 'Step 3',
// 					name: 'Confirmation and labels',
// 					href: '#',
// 					isCompleted: false,
// 				},
// 			],
// 			current: 0,
// 		};
// 	}

// 	onStepChange = () => {
// 		this.setState((prevState) => {
// 			let newSteps = [...prevState.steps];

// 			newSteps[prevState.current].isCompleted = true;

// 			return {
// 				...prevState,
// 				current: this.state.current + 1,
// 				steps: newSteps,
// 			};
// 		});
// 	};

// 	onStepEdit = (idx) => {
// 		this.setState((prevState) => {
// 			return {
// 				...prevState,
// 				current: idx,
// 			};
// 		});
// 	};

// 	render() {
// 		return (
// 			<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
// 				<PageTitle title='Create a Shipment' />

// 				<div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
// 					<Steps
// 						steps={this.state.steps}
// 						current={this.state.current}
// 						onStepEdit={this.onStepEdit}
// 					/>
// 				</div>

// 				{this.state.current === 0 && (
// 					<ShipmentDetailsForm onStepChange={this.onStepChange} />
// 				)}
// 				{this.state.current === 1 && (
// 					<PaymentForm onStepChange={this.onStepChange} />
// 				)}
// 				{this.state.current === 2 && <Confirmation />}
// 			</main>
// 		);
// 	}
// }

export default CreateShipment;
