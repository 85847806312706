import ReactDOM from 'react-dom';
import './index.css';
import Root from './store';

import App from './App';

ReactDOM.render(
	<Root>
		<App />
	</Root>,
	document.querySelector('#root')
);
