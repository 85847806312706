import React from 'react';

import { connect } from 'react-redux';
import { initiateShipmentDetailsValues } from '../../../store/actions';

import ServiceRates from '../../../components/ServiceRates';

const Checkout = ({ price, onStepChange, initiateShipmentDetailsValues }) => {
	const onServiceSelect = (service) => {
		initiateShipmentDetailsValues(price.formValues, service);
		onStepChange();
	};

	return (
		<ServiceRates
			linkName={'Checkout'}
			onServiceSelect={onServiceSelect}
			serviceRates={price.serviceRates}
		/>
	);
};

const mapStateToProps = (state) => ({
	price: state.price,
});

export default connect(mapStateToProps, { initiateShipmentDetailsValues })(
	Checkout
);
