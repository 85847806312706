import React from 'react';
import { Link } from 'react-router-dom';

const RouterLink = ({ path, children, ...props }) => {
	return (
		<Link
			{...props}
			to={path}
			className='font-medium text-yellow-500 hover:text-yellow-400'
		>
			{children}
		</Link>
	);
};

export default RouterLink;
