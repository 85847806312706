import React from 'react';

import { connect } from 'react-redux';
import { getQuotes } from '../../../store/actions';

import FormSubmit from '../FormSubmit';
import Fields from './Fields';
import { getReqBody } from './getReqBody';
import ShipNow from './ShipNow';

const buttonLabel = 'Get quotes';

const GetQuotesForm = ({ redirectPath, setRedirectPath, serviceRates }) => {
	return (
		<>
			<FormSubmit
				component={Fields}
				submitAction={getQuotes}
				getReqBody={getReqBody}
				buttonLabel={buttonLabel}
				redirectPath={redirectPath}
				setRedirectPath={setRedirectPath}
			/>
			{serviceRates && <ShipNow />}
		</>
	);
};

const mapStateToProps = (state) => ({
	serviceRates: state.price.serviceRates,
});

export default connect(mapStateToProps)(GetQuotesForm);
