import React from 'react';
import { resetPassword } from '../../../store/actions';
import FormSubmit from '../FormSubmit';
import Fields from './Fields';

const getReqBody = (values) => {
	return {
		email: values.email,
	};
};
const buttonLabel = 'Continue';
const redirectPath = '';

const ResetPasswordForm = ({ setRedirectPath }) => {
	return (
		<FormSubmit
			component={Fields}
			getReqBody={getReqBody}
			submitAction={resetPassword}
			buttonLabel={buttonLabel}
			redirectPath={redirectPath}
			setRedirectPath={setRedirectPath}
		/>
	);
};

export default ResetPasswordForm;
