import React from 'react';

const PageTitle = ({ title }) => {
	return (
		<h1 className='text-3xl font-extrabold pt-10 sm:pt-12 lg:pt-4 pb-2 sm:pb-3 lg:pb-4 leading-6'>
			{title}
		</h1>
	);
};

export default PageTitle;
