import { Link } from 'react-router-dom';

const features = [
	{
		name: 'fa-brands fa-fedex',
		description: 'Overnight Express discount up to 40%',
	},
	{
		name: 'fa-brands fa-usps',
		description: 'Special Rates for First Class & Piority Mail',
	},
	{
		name: 'fa-brands fa-ups',
		description: 'UPS 3 Day Select®, UPS Ground',
	},
];
const collections = [
	{
		name: 'FedEx International First®, FedEx International Priority®',
		description: 'Fedex Services',
		imageSrc:
			'https://firebasestorage.googleapis.com/v0/b/g7express-fe-c8591.appspot.com/o/fedex-home.jpg?alt=media&token=e007b94a-8123-442b-85cf-f88f9885012b',
		imageAlt:
			'Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.',
		href: '/create-shipment',
	},
	{
		name: 'USPS Priority Mail®, USPS First-Class Mail®',
		description: 'USPS Services',
		imageSrc:
			'https://firebasestorage.googleapis.com/v0/b/g7express-fe-c8591.appspot.com/o/usps-home.jpg?alt=media&token=7c2b8ec2-65e7-41d4-b2ec-7f10555cc6dd',
		imageAlt:
			'Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.',
		href: '/create-shipment',
	},
	{
		name: 'UPS 3 Day Select®, UPS Ground',
		description: 'UPS Services',
		imageSrc:
			'https://firebasestorage.googleapis.com/v0/b/g7express-fe-c8591.appspot.com/o/ups-home-03.jpg?alt=media&token=66fa6d9c-9a47-41e9-96cd-1f2040911026',
		imageAlt: 'Collection of four insulated travel bottles on wooden shelf.',
		href: '/create-shipment',
	},
];
const testimonials = [
	{
		id: 1,
		quote:
			'I have found their rates are very competitive, but it is their convenience online service adds the extra value for me.',
		attribution: 'Henry Nguyen, Southern California',
	},
	{
		id: 2,
		quote:
			'I had to return a purchase that didn’t fit. The whole process was so simple that I ended up ordering two new items!',
		attribution: 'Kelly McPherson, Chicago',
	},
	{
		id: 3,
		quote:
			'I used to pay list prices with no discount, but not anymore. Now I have up to 40% off and I can event can print out labels myself.',
		attribution: 'Xavier Gonzalez, Phoenix',
	},
];

export default function Home() {
	return (
		<main>
			{/* Hero */}
			<div className='flex flex-col border-b border-gray-200 lg:border-0'>
				<nav aria-label='Offers' className='order-last lg:order-first'>
					<div className='max-w-7xl mx-auto lg:px-8'>
						<ul className='grid grid-cols-1 divide-y divide-gray-200 lg:grid-cols-3 lg:divide-y-0 lg:divide-x'>
							{features.map((feature) => (
								<li key={feature.name} className='flex flex-col'>
									<a
										href={'/create-shipment'}
										className='relative flex-1 flex flex-col justify-center bg-white py-6 px-4 text-center focus:z-10'
									>
										<p className='text-7xl text-gray-500'>
											<i className={feature.name}></i>
										</p>
										<p className='font-semibold text-gray-900'>
											{feature.description}
										</p>
									</a>
								</li>
							))}
						</ul>
					</div>
				</nav>

				<div className='relative'>
					<div
						aria-hidden='true'
						className='hidden absolute w-1/2 h-full bg-gray-100 lg:block'
					/>
					<div className='relative bg-gray-100 lg:bg-transparent'>
						<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2'>
							<div className='max-w-2xl mx-auto py-24 lg:py-64 lg:max-w-none'>
								<div className='lg:pr-16'>
									<h1 className='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl'>
										Create your shipments & save up to{' '}
										<span className='text-yellow-500'>40%</span> shipping rate
									</h1>
									<p className='mt-4 text-xl text-gray-600'>
										We guaranteed our services.
									</p>
									<div className='mt-6'>
										<Link
											to='/signup'
											className='inline-block bg-yellow-500 border border-transparent py-3 px-8 rounded-md font-medium text-white hover:bg-yellow-600'
										>
											Click Here & Ship Now
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='w-full h-64 sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:w-1/2 lg:h-full'>
						<img
							src='https://firebasestorage.googleapis.com/v0/b/g7express-fe-c8591.appspot.com/o/hero-home.jpg?alt=media&token=adab3fae-db29-47ea-bda6-c9370bfeea7f'
							alt=''
							className='w-full h-full object-center object-cover'
						/>
					</div>
				</div>
			</div>

			{/* Collections */}
			<section aria-labelledby='collections-heading' className='bg-white'>
				<div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
					<div className='max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none'>
						<h2
							id='collections-heading'
							className='text-2xl font-extrabold text-gray-900'
						>
							Shipping Services
						</h2>

						<div className='mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6'>
							{collections.map((collection) => (
								<div key={collection.name} className='group relative'>
									<div className='relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1'>
										<img
											src={collection.imageSrc}
											alt={collection.imageAlt}
											className='w-full h-full object-center object-cover'
										/>
									</div>
									<h3 className='mt-6 text-sm text-gray-500'>
										<a href={collection.href}>
											<span className='absolute inset-0' />
											{collection.name}
										</a>
									</h3>
									<p className='text-base font-semibold text-gray-900'>
										{collection.description}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>

			{/* Trending products */}
			{/* <section aria-labelledby='trending-heading' className='bg-gray-100'>
				<div className='py-16 sm:py-24 lg:max-w-7xl lg:mx-auto lg:py-32 lg:px-8'>
					<div className='px-4 flex items-center justify-between sm:px-6 lg:px-0'>
						<h2
							id='trending-heading'
							className='text-2xl font-extrabold tracking-tight text-gray-900'
						>
							Shipping services
						</h2>
					</div>
					<div>
						<dl className='mt-12 space-y-10 sm:space-y-0 px-4 sm:px-6 lg:px-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8'>
							{features.map((feature) => {
								return (
									<div key={feature.name} className='relative'>
										<dt>
											<CheckIcon
												className='absolute top-6 h-6 w-6 text-yellow-500'
												aria-hidden='true'
											/>
											<p className='ml-9 text-7xl leading-6 font-medium text-gray-900'>
												<i className={feature.name}></i>
											</p>
										</dt>
										<dd className='mt-2 ml-9 text-base text-gray-500'>
											{feature.description}
										</dd>
									</div>
								);
							})}
						</dl>
					</div>
				</div>
			</section> */}

			{/* Sale and testimonials */}
			<div className='relative overflow-hidden'>
				{/* Decorative background image and gradient */}
				<div aria-hidden='true' className='absolute inset-0'>
					<div className='absolute inset-0 max-w-7xl mx-auto overflow-hidden xl:px-8'>
						<img
							src='https://firebasestorage.googleapis.com/v0/b/g7express-fe-c8591.appspot.com/o/support-home.jpg?alt=media&token=e4ab1933-c3e5-459a-8e8b-41bc543798e8'
							alt=''
							className='w-full h-full object-center object-cover'
						/>
					</div>
					<div className='absolute inset-0 bg-white bg-opacity-75' />
					<div className='absolute inset-0 bg-gradient-to-t from-white via-white' />
				</div>

				{/* Sale */}
				<section
					aria-labelledby='sale-heading'
					className='relative max-w-7xl mx-auto pt-32 px-4 flex flex-col items-center text-center sm:px-6 lg:px-8'
				>
					<div className='max-w-2xl mx-auto lg:max-w-none'>
						<h2
							id='sale-heading'
							className='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl'
						>
							Support when you need it
						</h2>
						<p className='mt-4 max-w-xl mx-auto text-xl text-gray-600'>
							We are here to help you with your questions regarding of our
							products and services
						</p>
						<a
							href='mailto:info@g7express.com'
							className='mt-6 inline-block w-full bg-gray-900 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-gray-800 sm:w-auto'
						>
							Click Here to Email Us
						</a>
					</div>
				</section>

				{/* Testimonials */}
				<section
					aria-labelledby='testimonial-heading'
					className='relative py-24 max-w-7xl mx-auto px-4 sm:px-6 lg:py-32 lg:px-8'
				>
					<div className='max-w-2xl mx-auto lg:max-w-none'>
						<h2
							id='testimonial-heading'
							className='text-2xl font-extrabold tracking-tight text-gray-900'
						>
							What are people saying?
						</h2>

						<div className='mt-16 space-y-16 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8'>
							{testimonials.map((testimonial) => (
								<blockquote key={testimonial.id} className='sm:flex lg:block'>
									<svg
										width={24}
										height={18}
										viewBox='0 0 24 18'
										xmlns='http://www.w3.org/2000/svg'
										aria-hidden='true'
										className='flex-shrink-0 text-gray-300'
									>
										<path
											d='M0 18h8.7v-5.555c-.024-3.906 1.113-6.841 2.892-9.68L6.452 0C3.188 2.644-.026 7.86 0 12.469V18zm12.408 0h8.7v-5.555C21.083 8.539 22.22 5.604 24 2.765L18.859 0c-3.263 2.644-6.476 7.86-6.451 12.469V18z'
											fill='currentColor'
										/>
									</svg>
									<div className='mt-8 sm:mt-0 sm:ml-6 lg:mt-10 lg:ml-0'>
										<p className='text-lg text-gray-600'>{testimonial.quote}</p>
										<cite className='mt-4 block font-semibold not-italic text-gray-900'>
											{testimonial.attribution}
										</cite>
									</div>
								</blockquote>
							))}
						</div>
					</div>
				</section>
			</div>
		</main>
	);
}
