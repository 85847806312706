import { store } from '../../../store';

export const getReqBody = (values) => {
	const {
		length,
		height,
		width,
		weight,
		ozUnit,
		packagingType,
		shipDate,
		residential,
	} = values;
	const shipper = { ...store.getState().price.shipper };
	const recipient = { ...store.getState().price.recipient };

	const from_location = {
		state: shipper.stateOrProvinceCode,
		country: shipper.countryCode,
		postal_code: shipper.postalCode,
		city: shipper.city,
		residential: true,
	};

	const to_location = {
		state: '',
		country: recipient.countryCode,
		postal_code: recipient.postalCode,
		city: recipient.city || recipient.stateOrProvinceCode,
		residential: residential || false,
	};

	let packages = [];
	let finalWeight = 0;

	finalWeight = parseInt(weight) + parseInt(ozUnit) / 16;

	packages.push({
		length: packagingType === 'FEDEX_ENVELOPE' ? 15 : length,
		width: packagingType === 'FEDEX_ENVELOPE' ? 10 : width,
		height: packagingType === 'FEDEX_ENVELOPE' ? 1 : height,
		weight: finalWeight || 0.21875,
		groupPackageCount: 1,
	});

	let commodities = [];
	commodities.push({
		description: 'documentation',
		weight: {
			units: 'LB',
			value: finalWeight,
		},
		quantity: 1,
		customsValue: {
			amount: '10',
			currency: 'USD',
		},
	});

	let carrier = 0;

	const reqBody = {
		carrier: carrier,
		shipDate: shipDate,
		from_location,
		to_location,
		packages,
		documentShipment: true,
		pickupType: 'DROPOFF_AT_FEDEX_LOCATION',
		packagingType,
		commodities,
		service_Type: '',
	};

	console.log(reqBody);

	return reqBody;
};
